import React, { useEffect, useState } from 'react'
import appSubject from '../../../../subjects/AppSubject'
import CardMainControls from './CardMainControls'
import CreateCardControls from './CreateCardControls'

const CardControls = () => {
  const [page, setPage] = useState(appSubject.selectedPage)

  const onPageUpdate = () => {
    setPage(appSubject.selectedPage)
  }

  useEffect(() => {
    appSubject.addObserver(onPageUpdate)
    return () => {
      appSubject.removeObserver(onPageUpdate);
    }
  }, [])

  const renderControls = () => {
    switch (page) {
      case 'cardList': {
        return <CardMainControls />
      }
      case 'createCard': {
        return <CreateCardControls />
      }
      default:
        break
    }
  }

  return <>{renderControls()}</>
}

export default CardControls
