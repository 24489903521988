import styled from '@emotion/styled'
import { MenuItem, Select } from '@mui/material'
import React from 'react'

const PillSelect = ({ defaultValue, options, onOptionSelected }) => {
  const ThemedSelect = styled(Select)(() => ({
    width: '100%',
    backgroundColor: 'white',
    borderColor: 'white',
    borderRadius: '15px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      paddingRight: '0px',
    },
    '& .MuiSelect-outlined ': {
      padding: '0px',
      paddingLeft: '10%',
    },
  }))

  const handleSelected = (e) => {
    onOptionSelected(e.target.value)
  }

  return (
    <ThemedSelect
      onChange={handleSelected}
      defaultValue={options && defaultValue}
      inputProps={{ sx: { textAlign: 'center', width: '100%', paddingRight: '0px' } }}>
      {options ? (
        options.map((element, index) => {
          return (
            <MenuItem {...(element.props && { ...element.props })} value={element.value} key={index}>
              {element.label}
            </MenuItem>
          )
        })
      ) : (
        <MenuItem disabled>Sin opciones disponibles</MenuItem>
      )}
    </ThemedSelect>
  )
}

export default PillSelect
