import { Grid, MenuItem, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import theme from '../../../../css/Theme'
import appSubject from '../../../../subjects/AppSubject'
import CustomSelect from '../../../../shared/Components/CustomSelect/CustomSelect'
import styled from '@emotion/styled'

const ThemedSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    marginLeft: '35%',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#ff3b4e',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#ff3b4e',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: 'black',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
)

const CreateControls = () => {
  const [selectedType, setSelectedType] = useState(appSubject.selectedSectionType)

  const onTypeChange = () => {
    setSelectedType(appSubject.selectedSectionType)
  }

  useEffect(() => {
    appSubject.addObserver(onTypeChange)
    return () => appSubject.removeObserver(onTypeChange)
  })

  const onSaveNewSection = () => {
    appSubject.saveNewSection()
  }

  const types = ['BANNER', 'SMALL', 'MEDIUM']

  return (
    <Grid container item xs={12} direction={'column'} flexWrap={'nowrap'} justifyContent={'space-between'}>
      <Grid
        container
        item
        xs={6}
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{ height: '100%' }}
        spacing={3}>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <ThemedButton background="transparent" border="1px solid red" color={'red'}>
            {appSubject.newSection.id ? 'Modificar Sección' : 'Nueva sección'}
          </ThemedButton>
        </Grid>
        <Grid container item xs={1} justifyContent={'center'}>
          <KeyboardArrowDownIcon sx={{ fontSize: '40px', color: 'white' }} />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={appSubject.newSection && appSubject.newSection.name}
            onChange={(e) => (appSubject.newSection.name = e.target.value)}
            placeholder={'Nombre'}
          />
        </Grid>
        <Grid container item xs={2}>
          <CustomSelect onChange={(e) => appSubject.onChangeSelectedType(e.target.value)} defaultValue={selectedType}>
            {types.map((element, index) => {
              return (
                <MenuItem key={index} value={element}>
                  {element}
                </MenuItem>
              )
            })}
          </CustomSelect>
        </Grid>
        <Grid container item xs={2} direction="row" alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={3}>
            <Typography align="center">Activo</Typography>
          </Grid>
          <Grid item xs={3}>
            <ThemedSwitch
              disabled={appSubject.newSection.id === 1 ? true : false}
              sx={{ m: 1 }}
              defaultChecked={appSubject.newSection && appSubject.newSection.enabled}
              onChange={(e) => {
                appSubject.newSection.enabled = e.target.checked
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={3} direction="column" justifyContent={'flex-end'} sx={{ height: '100%' }}>
        <Grid item xs={3}>
          <ThemedButton handler={onSaveNewSection}>GUARDAR</ThemedButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateControls
