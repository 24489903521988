import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from "@emotion/styled";

const ThemedBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const Spinner = () => {
  return (
    <ThemedBackdrop open={true}>
      <CircularProgress color="primary" />
    </ThemedBackdrop>
  );
};

export default Spinner;