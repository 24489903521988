import salonService from "../http/services/SalonService";
import { SalonTemplate } from "./models/SalonTemplate";

class SalonSubject {
  names = [];
  selectedPage = "viewer";
  selectedType = "BATCH";
  categories = [];
  observers = [];
  selectedName = "";
  newSalon = new SalonTemplate();
  filteredSalons = [...this.names];

  /**
   * Gets all the notifications and categories available in the DB. Then, updates the local variables
   */
  async updateSalons() {
    this.names = await salonService.getSalons();
    this.filteredSalons = [...this.names];
    this.filterActiveRooms();
    this.notify();
  }

  filterActiveRooms() {
    this.filteredSalons = this.filteredSalons.filter((elem) => elem.active);
  }
  /**
   * Updates the local type variable.
   * @param {String} type type of notification: could be batch or custom
   */
  setSelectedType(type) {
    this.selectedType = type;
    this.notify();
  }
  /**
   * Updates the local page variable
   * @param {String} page that is currently rendered: colud be viewer or create
   */
  setSelectedPage(page) {
    this.selectedPage = page;
    this.notify();
  }
  /**
   * Updates the local name variable
   * @param {String} page that is currently rendered: colud be viewer or create
   */
  setSelectedName(room) {
    this.newSalon = room;
    this.notify();
  }
  /**
   *  Adds an observer function to the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  addObservers(o) {
    this.observers.push(o);
  }
  /**
   * Removes an observer function from the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  removeObservers(o) {
    let index = this.observers.indexOf(o);
    this.observers.splice(index, 1);
  }
  /**
   *  Runs all the observer functions in the array of observers.
   */
  notify() {
    this.observers.forEach((element) => element());
  }
  /**
   * Creates a new instance on the NotificationPushTemplate and setal all the local variables to their default value.
   */
  async cleanSelectedItem() {
    this.newSalon = new SalonTemplate();
    this.setSelectedType(null);
    this.setSelectedPage("viewer");
  }

  /**
   * Filters the array of notificationsPush by name. If the input value is empty it sets the filterd array to equal the notificationsPush array
   * @param {Input event} e
   */
  searchHandler(e) {
    let arr = [...this.names];
    let inputText = e.target.value.toUpperCase();

    let filtered = arr.filter((elem) => {
      let name = elem.name.toUpperCase();
      return name.includes(inputText);
    });

    this.filteredSalons = [...filtered];

    if (e.target.value === "") {
      this.filteredSalons = [...this.bookings];
    }

    this.notify();
  }

  /**
   * Sorts the array of rooms, in alphabetical order by name
   */
  sortRoomsByName() {
    let arr = [...this.names];

    arr.sort(function (a, b) {
      let first = a.name.toLowerCase();
      let second = b.name.toLowerCase();
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });
    this.filteredSalons = [...arr];
    this.filterActiveRooms();
    this.notify();
  }

  /**
   * Checks if all the required inputs of the create notification form are filled.
   * Adds the new notification instance to the array of notifications push, and to the array of filtered notifications push.
   */
  async saveSalon() {
    if (this.newSalon.name === "") {
      alert("El nombre no puede ser vacío");
    } else {
      let arr = [...this.names];
      const requestBody = new SalonTemplate(this.newSalon);
      let jsonResponse;
      try {
        jsonResponse = await salonService.post(requestBody);
        jsonResponse && arr.push(jsonResponse.data[0]);
      } catch (error) {
        console.log(error);
      }
      this.names = [...arr];
      this.filteredSalons = [...this.names];
      this.filterActiveRooms();
      this.cleanSelectedItem();
      this.selectedPage = "viewer";
      this.notify();
    }
  }

  async putSalon(room) {
    if (room === "") {
      alert("El nombre no puede ser vacío");
    } else {
      let arr = [...this.names];
      const requestBody = new SalonTemplate(room);
      let find = arr.find((element) => element.id === requestBody.id);
      let index = arr.indexOf(find);
      let jsonResponse;
      try {
        jsonResponse = await salonService.put(requestBody);
      } catch (error) {
        console.log(error);
      }
      jsonResponse && (arr[index] = new SalonTemplate(jsonResponse.data[0]));
      this.names = [...arr];
      this.filteredSalons = [...this.names];
      this.filterActiveRooms();
      this.cleanSelectedItem();
      this.selectedPage = "viewer";
      this.notify();
    }
  }

  async deleteRoom(room) {
    let arr = [...this.names];
    let id = room.id;
    let find = arr.find((element) => element.id === id);
    let index = arr.indexOf(find);
    let jsonResponse;
    try {
      jsonResponse = await salonService.delete(id);
    } catch (error) {
      console.log(error);
    }
    jsonResponse && arr.splice(index, 1);
    this.names = [...arr];
    this.filteredSalons = [...this.names];
    this.filterActiveRooms();
    this.cleanSelectedItem();
    this.notify();
  }
}
const salonSubject = new SalonSubject();
export default salonSubject;
