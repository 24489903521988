import coreAxiosClient from '../axios/CoreAxiosClient'

class ClassService {
  getClubs(){
    return coreAxiosClient.get(`/clubs/list`)
  }

  getActivities(id){
    return coreAxiosClient.get(`/disciplines/categories/club/` + id)
  }

  getClasses(idClub, idActivity){
    return coreAxiosClient.get(`/clases/list?clubId=${idClub}&disciplineId=${idActivity}`)
  }

  getCoaches(){
    return coreAxiosClient.get(`/ls/coach/list`)
  }

  getBooking(idClass){
    return coreAxiosClient.get(`/bookings/class/${idClass}`)
  }

  putBooking(idBook){
    return coreAxiosClient.put(`bookings/${idBook}/redeem`)
  }
}

const classService = new ClassService()
export default classService