import { Grid } from '@mui/material'
import PushNotificationControls from '../../components/SalonsComponent/salonControls'
import PushSalonViewer from '../../components/SalonsComponent/pushSalonViewer'
import salonSubject from '../../subjects/SalonSubject'
import { useEffect } from 'react'

const SalonsPage = () => {

  useEffect(() => {
    salonSubject.updateSalons()
  }, [])

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90%' }}>
      <Grid
        container
        item
        wrap="nowrap"
        xs={7.5}
        direction={'column'}
        justifyContent={'flex-start'}
        sx={{
          border: '1px solid #cccccc',
          borderRadius: '15px',
          padding: '10px',
          paddingBottom: 0,
          ml: '40px',
          mt: '20px',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <PushSalonViewer />
      </Grid>
      <Grid
        container
        item
        xs={3.5}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        sx={{ backgroundColor: '#cccccc', mt: '20px', height: '100%', padding: '20px' }}>
        <PushNotificationControls />
      </Grid>
    </Grid>
  )
}
export default SalonsPage