import { SedesList } from './SedesViewer/sedesList'
import { Divider, Grid, Typography } from '@mui/material'
import CustomTextField from '../../shared/Components/CustomTextField/CustomTextField'
import theme from '../../css/Theme'
import sedeSubject from '../../subjects/SedeSubject'

const SedesViewer = () => {

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>Configuración</Typography>
          <Typography variant="h4">Sedes</Typography>
        </Grid>
        <Grid container item xs={4} direction="column">
          {(
            <Grid item xs={6}>
              <CustomTextField searchbutton={1} onChange={(e) => sedeSubject.searchHandler(e)} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={0.25}>
        <Divider orientation="horizontal" color={theme.palette.primary.main} />
      </Grid>
      <SedesList />
    </>
  )
}
export default SedesViewer