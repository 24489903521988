import { Divider, Grid, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Theme from '../../../../css/Theme'
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CardFinder from './CardFinder'
import appSubject from '../../../../subjects/AppSubject'
import DragAndDropList from '../../../../shared/Components/DragAndDrop/DragAndDropList'
import dragAndDropSubject from '../../../../shared/Components/DragAndDrop/DragAndDropSubject'
import UploadImg from '../../../../shared/Components/UploadImgComponent/UploadImg'
import PillSelect from '../../../../shared/Components/PillSelect/PillSelect'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

/**
 * Local textareaAutosize customization
 */
const ThemedTextareaAutosize = styled(TextareaAutosize)(() => ({
  width: '100%',
  borderRadius: '5px',
  border: '2px solid white',
  background: 'transparent',
  '&::placeholder': {
    padding: '2%',
    textOverflow: 'ellipsis !important',
    color: 'white !important',
    fontFamily: Theme.typography.fontFamily,
    fontSize: 'medium',
  },
}))

/**
 * Render two components, one shared by all the creating/modifing pages and another acording to the selected page.
 * @param {String} page Selected page identifier.
 * @returns two components, one shared by all the creating/modifing page options and another acording to the selected page.
 */
export default function CreateNew() {
  const [addedCards, setAddedCards] = useState(appSubject.addedCardsToSection)
  const [selectedType, setSelectedType] = useState(appSubject.selectedSectionType)
  const [selectedAction, setSelectedAction] = useState(appSubject.selectedSectionAction)
  const [selectedSectionActionConfig, setSelectedActionConfig] = useState(appSubject.selectedSectionActionConfig)

  const navigateOnViewer = () => {
    appSubject.updateSelectedPage('homeSections', true)
  }

  const onTypeChange = () => {
    setSelectedType(appSubject.selectedSectionType)
  }

  const onActionChange = () => {
    setSelectedAction(appSubject.selectedSectionAction)
  }

  const onActionConfigChange = () => {
    setSelectedActionConfig(appSubject.selectedSectionActionConfig)
  }

  const onAddedCardsUpdate = () => {
    setAddedCards(appSubject.addedCardsToSection)
  }

  // const showScheduledButton = addedCards.some(card => new Date(card.activeUntil) > new Date());
  

  useEffect(() => {
    appSubject.addObserver(onActionConfigChange)
    appSubject.addObserver(onActionChange)
    appSubject.addObserver(onTypeChange)
    appSubject.addObserver(onAddedCardsUpdate)
    return () => {
      appSubject.removeObserver(onActionConfigChange)
      appSubject.removeObserver(onActionChange)
      appSubject.removeObserver(onTypeChange)
      appSubject.removeObserver(onAddedCardsUpdate)
    }
  }, [])

  const handleAddCards = (element) => {
    appSubject.handleAddCardsToSection(element)
    dragAndDropSubject.addItemsToList(element)
    navigateOnControls('createSection', false)    
  }

  const navigateOnControls = (pageName, cleanSelectedItem) => {
    appSubject.updateSelectedPage(pageName, cleanSelectedItem)
  }


  const handleRemoveCards = (element) => {
    appSubject.handleRemoveCardsToSection(element)
    dragAndDropSubject.removeItemFromList(element)
    navigateOnControls('createSection', false)    
  }

  const handleEditCard = (element) => {
    console.log("HANDLE EDIT CARD");
    console.log(element);
    appSubject.updateSelectedPage('scheduleCard', false, element)
    dragAndDropSubject.addItemsToList(element)
  }

  const toURL = (imageFile) => {
    if (typeof imageFile !== 'string') {
      return URL.createObjectURL(imageFile)
    } else return imageFile
  }

  const actions = [
    { value: 'link', label: 'Link', props: { disabled: true } },
    { value: 'page', label: 'Pantalla de Contenido' },
  ]

  console.log("ADDEDAD CARDS");
  console.log(addedCards);

  const renderActions = () => {
    switch (selectedAction) {
      case 'urlLink':
      case 'link': {
        return (
          <Grid container item direction="row" justifyContent="flex-start" sx={{ my: 2 }}>
            <Grid container item xs={2}></Grid>
            <Grid
              container
              item
              xs={7}
              direction="row"
              justifyContent="center"
              alignContent="flex-start"
              sx={{ outline: '2px solid white', borderRadius: '15px', height: '150px' }}>
              <Grid container item direction="row" justifyContent="flex-start" sx={{ height: 'fit-content', mt: 2 }}>
                <Grid container item xs={5}>
                  <Typography fontWeight={900} align="center" sx={{ pl: 2.5, width: '100%' }}>
                    Acción:
                  </Typography>
                </Grid>
                <Grid container item xs={5.5} justifyContent="center">
                  <PillSelect
                    defaultValue={selectedAction}
                    options={actions}
                    onOptionSelected={(e) => appSubject.onSectionActionChange(e)}></PillSelect>
                </Grid>
              </Grid>
              <Grid container item xs={10} direction="row" justifyContent="center" sx={{ mt: 3 }}>
                <CustomTextField
                  background={'#cccccc'}
                  backgroundcolor="white"
                  defaultValue={selectedSectionActionConfig.content}
                  onChange={(e) => (selectedSectionActionConfig.content = e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      }
      case 'page': {
        return (
          <Grid container item direction="row" justifyContent="flex-start" sx={{ my: 2, pr: 1 }}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="flex-end"
              alignContent="flex-start"
              sx={{ outline: '2px solid white', borderRadius: '15px' }}>
              <Grid container item direction="row" justifyContent="center" sx={{ height: 'fit-content', mt: 2 }}>
                <Grid container item xs={3}>
                  <Typography fontWeight={900} align="center" sx={{ pl: 2.5, width: '100%' }}>
                    Acción:
                  </Typography>
                </Grid>
                <Grid container item xs={3.25} justifyContent="center">
                  <PillSelect
                    defaultValue={selectedAction}
                    options={actions}
                    onOptionSelected={(e) => appSubject.onSectionActionChange(e)}
                  />
                </Grid>
                <Grid container item xs={1.75}></Grid>
              </Grid>
              <Grid container item xs={8} direction="column" rowSpacing={2} sx={{ my: 2 }}>
                <Grid container item direction="row"></Grid>
                <Grid container item direction="row">
                  <Grid container item xs={3} style={{ justifyContent: "end" }}>
                    <Typography>Imagen</Typography>
                  </Grid>
                  <Grid container item xs={8.5} justifyContent="center">
                    <UploadImg
                      onChange={(file) => (appSubject.selectedImageForSectionContent = file)}
                      value={toURL(selectedSectionActionConfig.image)}
                      infoImg={{ format: 'JPG/PNG', resolution: '428 X 642' }}
                    />
                  </Grid>
                </Grid>
                <Grid container item direction="row" style={{ display:"flex", justifyContent:"center", alignContent:"center" }}>
                  <Grid container item xs={9}>
                     <Typography style={{ textAlign: "center", display: "block", margin: "0 auto", marginTop: "30px", marginBottom: "30px", width: "100%" }}>Content (HTML)</Typography>
                    <div style={{ width: "700px", marginLeft:"-130px" }}>
                      <CKEditor
                        editor={Editor}
                        style={{ width: "500px", display: "block", margin: "0 auto" }}
                        data={selectedSectionActionConfig.content ? selectedSectionActionConfig.content : ''}
                        onChange={(event, editor) => {
                          selectedSectionActionConfig.content = editor.getData();
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={2}></Grid>
            </Grid>
          </Grid>
        )
      }
      default:
        break
    }
  }

  return (
    <>
      <Grid container wrap="nowrap" item sx={{ m: '5px 0' }} direction="row" justifyContent="flex-start">
        <Grid container item xs={1}>
          <ArrowForwardIosIcon
            onClick={navigateOnViewer}
            sx={{
              fontSize: 'x-large',
              transform: 'rotate(180deg)',
              color: Theme.palette.darkerGray.main,
              cursor: 'pointer',
            }}
          />
        </Grid>
        <Grid container item xs={4}>
          <ThemedTextareaAutosize
            minRows={5}
            maxRows={5}
            sx={{ resize: 'none' }}
            defaultValue={appSubject.newSection.description}
            onChange={(e) => (appSubject.newSection.description = e.target.value)}
            placeholder="Descripcion larga (200 caracteres max)"></ThemedTextareaAutosize>
        </Grid>
        {selectedType === 'BANNER' && (
          <Grid container item xs={6} alignItems="center" direction="column">
            <UploadImg
              infoImg={{ format: 'JPG/PNG', resolution: '428 X 250' }}
              value={appSubject.selectedBannerForSection}
              onChange={(file) => {
                appSubject.selectedBannerForSection = file
              }}
            />
            <Typography>Banner</Typography>
          </Grid>
        )}
      </Grid>
      <Divider
        sx={{
          width: '90%',
          height: '1px',
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0),rgb(255, 255, 255), rgba(0, 0, 0, 0))',
          margin: '20px',
        }}
        variant="middle"
      />
      {selectedType !== 'BANNER' ? (
        <>
          <Grid container item xs={1}>
            <Typography
              sx={{
                color: Theme.palette.darkerGray.main,
                fontSize: '18px',
                fontWeight: 'bold',
                textAlign: 'start',
                margin: '2%',
              }}>
              Administrar CARDS
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={9}
            direction="row"
            wrap="nowrap"
            sx={{ height: '100%', width: '100%' }}
            justifyContent="space-around">
            <Grid
              item
              xs={4}
              container
              direction={'column'}
              sx={{
                backgroundColor: 'white',
                width: '100%',
                overflow: 'auto',
                alignItems: 'center',
              }}>
              <DragAndDropList
                defaultList={addedCards}
                showDeleteButton
                deleteButtonHandler={(i) => {
                  handleRemoveCards(addedCards[i])
                }}
                onChange={(orderList) => {
                  appSubject.saveOrderSection(orderList)
                }}
                renderOption={(addedCards) => addedCards.id + '. ' + addedCards.title}
                optionValue={(addedCards, newOrder) => {
                  addedCards.weight = newOrder
                }}
                showEditButton
                editButtonHandler={(i) => {
                  handleEditCard(addedCards[i])
                }}
              />
            </Grid>
            <CardFinder handleCards={handleAddCards} />
          </Grid>
        </>
      ) : (
        <>{renderActions()}</>
      )}
    </>
  )
}
