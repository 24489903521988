import { Grid } from '@mui/material'
import pushNotificationSubject from '../../../subjects/PushNotificationsSubject'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page) => {
  pushNotificationSubject.setSelectedPage(page)
}

/**
 * Renders buttons that sort notifications and that navigate to "Create categories". Also renders filters for the array of notifications.
 */
const ViewerControls = () => {
  return (
    <Grid container item xs={6} direction="column" justifyContent={'flex-start'} gap={3}>
      <Grid item xs={2}>
        <ThemedButton handler={() => navigateOnControls('create')}>Crear Notificación</ThemedButton>
      </Grid>
      {/* <Grid item xs={2}>
        <CustomSelect
          lable="Todas las notificaciones"
          sx={{ background: 'white' }}
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          renderValue={(selected) => {
            if (selected) {
              return selected
            } else {
              return <>Todas las notificaciones</>
            }
          }}>
          <MenuItem value={'Todas las notificaciones'} sx={{ color: theme.palette.darkerGray.main }}>
            Todas las notificaciones
          </MenuItem>
          <MenuItem value={'opciones'} sx={{ color: theme.palette.darkerGray.main }}>
            Opciones
          </MenuItem>
        </CustomSelect>
      </Grid>
      <Grid item xs={2}>
        <CustomSelect
          lable="Más filtros"
          value={typeVideo}
          onChange={(e) => setTypeVideo(e.target.value)}
          renderValue={(selected) => {
            if (selected) {
              return selected
            } else {
              return <>Más filtros</>
            }
          }}>
          <MenuItem value={'Más filtros'} sx={{ color: theme.palette.darkerGray.main }}>
            Más filtros
          </MenuItem>
          <MenuItem value={'opciones'} sx={{ color: theme.palette.darkerGray.main }}>
            Opciones
          </MenuItem>
        </CustomSelect>
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={() => {
            pushNotificationSubject.sortNotificationsByName()
          }}
          sx={{
            background: 'transparent',
            borderTop: '1px solid white',
            borderBottom: '1px solid white',
            borderRadius: '5px',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography sx={{ color: theme.palette.darkGray.main, textTransform: 'lowercase' }}>
            {pushNotificationSubject.notificationPush.length} Notificaciones
          </Typography>
          <Typography sx={{ color: theme.palette.darkerGray.main, fontWeight: 'bold' }}>
            A-Z <ArrowDownwardIcon sx={{ fontSize: 'small' }} />
          </Typography>
        </Button>
      </Grid> */}
    </Grid>
  )
}
export default ViewerControls
