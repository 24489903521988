export class NotificationPushTemplate {
  name = ''
  status = ''
  category = 1
  type = 'BATCH'
  description = ''
  sendingDate = new Date()

  constructor(jsonResponse) {
    if (jsonResponse) {
      this.name = jsonResponse.name
      this.status = jsonResponse.status
      this.category = jsonResponse.category
      this.type = jsonResponse.type
      this.description = jsonResponse.description
      this.sendingDate = new Date(jsonResponse.sendingDate)
    }
  }
}
