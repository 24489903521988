import React, { useEffect, useState } from 'react'
import { Grid, Switch, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import styled from '@emotion/styled'
import theme from '../../../../css/Theme'
import appSubject from '../../../../subjects/AppSubject'

const ThemedSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    marginLeft: '35%',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#ff3b4e',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#ff3b4e',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: 'black',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
)

const CreateCardControls = () => {
  const [selectedCard, setSelectedCard] = useState(appSubject.newCard)

  const onCardChange = () => {
    setSelectedCard(appSubject.newCard)
  }

  useEffect(() => {
    appSubject.addObserver(onCardChange)
    return () => appSubject.removeObserver(onCardChange)
  }, [])

  return (
    <Grid container item direction="column" justifyContent="space-between" wrap="nowrap" sx={{ height: '100%' }}>
      <Grid
        container
        item
        xs={5}
        rowSpacing={1}
        direction="column"
        wrap="nowrap"
        justifyContent={'flex-start'}
        alignItems={'center'}>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <ThemedButton background="transparent" border="1px solid red" color={'red'}>
            Crear Card
          </ThemedButton>
        </Grid>
        <Grid container item xs={1} justifyContent={'center'}>
          <KeyboardArrowDownIcon sx={{ color: 'white', height: '100%', fontSize: '40px' }} />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            defaultValue={selectedCard.title ? selectedCard.title : ''}
            onChange={(e) => (selectedCard.title = e.target.value)}
            placeholder={'Título'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
          />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            defaultValue={selectedCard.subtitle ? selectedCard.subtitle : ''}
            onChange={(e) => (selectedCard.subtitle = e.target.value)}
            placeholder={'Subtítulo'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
          />
        </Grid>
        <Grid container item xs={2} direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={3}>
            <Typography align="center">Activo</Typography>
          </Grid>
          <Grid item xs={3}>
            <ThemedSwitch
              sx={{ m: 1 }}
              defaultChecked={selectedCard.enabled}
              onChange={(e) => {
                selectedCard.enabled = e.target.checked
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <ThemedButton
          handler={() => {
            console.log("LINEA 126")
            appSubject.saveCard(selectedCard.id)
          }}>
          GUARDAR
        </ThemedButton>
      </Grid>
    </Grid>
  )
}

export default CreateCardControls
