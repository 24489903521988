import { Button, Grid, Typography } from '@mui/material'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import { useEffect, useState } from 'react'
import theme from '../../../css/Theme'

/**
 * Renders buttons that sort programs and that navigate to "Create programs". Also renders filters for rendered accordions of programs.
 * @returns a component.
 */
const ProgramControls = () => {
  const [totalPrograms, setTotalPrograms] = useState(onDemandSubject.totalElementsProgram)

  /**
   * Executes the function on onDemandSubject that updates the selected page.
   * @param {String} pageName
   * @param {Boolean} cleanSelectedItem if true, it creates a new instance of program template.
   */
  const navigateOnControls = (pageName, cleanSelectedItem) => {
    onDemandSubject.updateSelectedPage(pageName, cleanSelectedItem)
  }

  const onTotalProgramsUpdate = () => {
    setTotalPrograms(onDemandSubject.totalElementsProgram)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onTotalProgramsUpdate)
    return () => onDemandSubject.removeObserver(onTotalProgramsUpdate)
  }, [])

  return (
    <Grid container item xs={5} direction="column" justifyContent={'flex-start'} gap={'10px'}>
      <Grid item xs={2}>
        <ThemedButton handler={() => navigateOnControls('createProgram', true)}>Crear Programa</ThemedButton>
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={() => {
            onDemandSubject.sortByProgram()
          }}
          sx={{
            background: 'transparent',
            border: '1px solid white',
            borderRadius: '10px',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography sx={{ color: theme.palette.darkGray.main, textTransform: 'lowercase' }}>
            {totalPrograms} programas
          </Typography>
          <Typography sx={{ color: theme.palette.darkerGray.main, fontWeight: 'bold' }}>
            A-Z <ArrowDownwardIcon sx={{ fontSize: 'small' }} />
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}
export default ProgramControls
