export class SaveSectionRequestModel {
  name
  content
  view_types
  config

  constructor(newSection) {
    this.name = newSection.name
    this.content = newSection.contents.map((element, index) => {
      return {
        content_id: element.id,
        content_type: element.contentType,
        weight: index + 1,
      }
    })

    this.view_types = newSection.viewTypes
    this.config = {
      view_type: newSection.config.viewType,
      is_published: newSection.config.published,
      weight: !newSection.config.published ? null : newSection.config.weight,
    }
  }
}
