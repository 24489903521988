import { Grid } from '@mui/material'
import { useEffect } from 'react'
import OnDemandControls from '../../components/OnDemand/OnDemandControls'
import OnDemandPageViewer from '../../components/OnDemand/OnDemandPageViewer'
import onDemandSubject from '../../subjects/OnDemandSubject'

const OnDemandPage = () => {
  useEffect(() => {
    onDemandSubject.getFormData()
  }, [])

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90%' }}>
      <Grid
        container
        item
        wrap="nowrap"
        xs={7.5}
        direction={'column'}
        justifyContent={'flex-start'}
        sx={{
          border: '1px solid #cccccc',
          borderRadius: '15px',
          padding: '10px',
          paddingBottom: 0,
          ml: '40px',
          mt: '20px',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <OnDemandPageViewer />
      </Grid>
      <Grid
        container
        item
        xs={3.5}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        sx={{ backgroundColor: '#cccccc', mt: '20px', height: '100%', padding: '20px' }}>
        <OnDemandControls />
      </Grid>
    </Grid>
  )
}
export default OnDemandPage
