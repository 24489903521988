import { Divider, Grid, MenuItem, Select } from '@mui/material'
import { useState } from 'react'
import Theme from '../../../css/Theme'
import ContentList from '../../../shared/Components/ContentList/ContentList'
import CustomTextField from '../../../shared/Components/CustomTextField/CustomTextField'
import onDemandSubject from '../../../subjects/OnDemandSubject'

const Search = () => {
  const [selectedFilter, setSelectedFilter] = useState('Videos')

  /**
   * Executes the function on onDemandSubject that adds a video to the array of section.
   * @param {Object} element a video from the filteredVideos array
   */
  const handleAddVideos = (element) => {
    onDemandSubject.handleAddContentToSection(element)
  }

  return (
    <>
      <Grid
        container
        item
        direction="column"
        alignItems={'center'}
        xs={6.5}
        sx={{ backgroundColor: '#666666', borderRadius: '15px', height: '400px' }}>
        <Grid container item xs={2} direction="column" sx={{ padding: '10px' }}>
          <Grid item container xs={2} direction="row" justifyContent={'space-around'} marginTop="10px">
            <Grid item xs={5} height="40px">
              <CustomTextField
                autoFocus
                onChange={(e) => onDemandSubject.searchHandler(e, 'createSection-' + selectedFilter)}
                searchbutton={1}
                background={'#666666'}
              />
            </Grid>
            <Grid item xs={5} height="40px">
              <Select
                lable="Videos"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                sx={{
                  width: '100%',
                  height: '100%',
                  border: '2px solid white',
                  borderRadius: '10px',
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                renderValue={(selected) => {
                  if (selected) {
                    return selected
                  } else {
                    return <>Videos</>
                  }
                }}>
                <MenuItem value={'Videos'} sx={{ color: Theme.palette.darkerGray.main }}>
                  Videos
                </MenuItem>
                <MenuItem value={'Programas'} sx={{ color: Theme.palette.darkerGray.main }}>
                  Programas
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          sx={{
            backgroundColor: 'white',
            width: '90%',
          }}
          variant="middle"
        />
        <Grid
          container
          item
          marginTop="15px"
          xs={9}
          direction="row"
          justifyContent={'flex-start'}
          alignContent={'flex-start'}
          sx={{ height: '100%', width: '100%', overflowY: 'auto' }}>
          <ContentList handleAddContent={handleAddVideos} type="section" selectedFilter={selectedFilter} />
        </Grid>
      </Grid>
    </>
  )
}
export default Search
