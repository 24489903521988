import { Divider, FormControlLabel, FormGroup, Grid, MenuItem, Switch, Typography } from '@mui/material'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import CustomSelect from '../../../shared/Components/CustomSelect/CustomSelect'
import CustomTextField from '../../../shared/Components/CustomTextField/CustomTextField'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useEffect, useState } from 'react'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import theme from '../../../css/Theme'

/**
 * Renders a form to create and save a new program.
 * @returns a component.
 */
const CreateProgramControls = () => {
  const [seasons, setSeasons] = useState(onDemandSubject.newProgram.seasons)

  /**
   * Updates the local variable seasons to the value of the intance of program (newProgram) from onDemandSubject.
   */
  const onSeasonsUpdate = () => {
    setSeasons(onDemandSubject.newProgram.seasons)
  }
  /**
   * Executes the saveProgram function in onDemandSubject.
   */
  const onSaveNewItem = () => {
    onDemandSubject.saveProgram()
  }

  const updateAccessLevel = (e) => {
    if (e.target.checked === true) {
      onDemandSubject.newProgram.accessLevels = ['ALL']
    } else {
      onDemandSubject.newProgram.accessLevels = ['MEMBERSHIP']
    }
  }

  useEffect(() => {
    onDemandSubject.addObserver(onSeasonsUpdate)
    return () => {
      onDemandSubject.removeObserver(onSeasonsUpdate)
    }
  }, [])

  return (
    <>
      <Grid
        container
        item
        xs={2}
        direction="column"
        justifyContent={'space-around'}
        alignItems={'center'}
        sx={{ height: '100%' }}>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <ThemedButton background="transparent" border="1px solid red" color={'red'}>
            {onDemandSubject.newProgram.id ? 'Modificar Programa' : 'Nuevo Programa'}
          </ThemedButton>
        </Grid>
        <Grid container item xs={2} justifyContent={'center'}>
          <KeyboardArrowDownIcon sx={{ fontSize: '40px', color: 'white' }} />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newProgram && onDemandSubject.newProgram.name}
            onChange={(e) => (onDemandSubject.newProgram.name = e.target.value)}
            placeholder={'Nombre'}
          />
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <Divider variant="middle" sx={{ backgroundColor: 'white', width: '90%' }} />
      </Grid>
      <Grid container item xs={3.5} direction="column" justifyContent={'space-around'} sx={{ height: '100% ' }}>
        <Grid item xs={1}>
          <Typography align="center" sx={{ fontWeight: 'bold' }}>
            CONFIGURACIÓN
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            onChange={(e) => {
              onDemandSubject.newProgram.severity = e.target.value
            }}
            defaultValue={
              onDemandSubject.newProgram.severity ? onDemandSubject.newProgram.severity : 'Dificultad/Nivel'
            }>
            <MenuItem value="Dificultad/Nivel" disabled>
              Dificultad/Nivel
            </MenuItem>
            {onDemandSubject.severityList.map((elem, index) => {
              return (
                <MenuItem key={index} value={elem}>
                  {elem}
                </MenuItem>
              )
            })}
          </CustomSelect>
        </Grid>
        <Grid item container justifyContent={'center'} xs={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color={'warning'}
                  onChange={(e) => updateAccessLevel(e)}
                  defaultChecked={onDemandSubject.newProgram && onDemandSubject.newProgram.accessLevels.includes('ALL')}
                />
              }
              label="¿Es Gratis?"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <Divider variant="middle" sx={{ backgroundColor: 'white', width: '90%' }} />
      </Grid>
      <Grid container item direction="column" xs={2} sx={{ height: '100% ' }}>
        <Grid item xs={3}>
          <Typography align="center" sx={{ fontWeight: 'bold' }}>
            TEMPORADAS
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          direction="row"
          justifyContent={'flex-start'}
          sx={{ overflowX: 'auto', overflowY: 'auto', width: '90%' }}>
          {seasons &&
            seasons.map((element, index) => {
              return (
                <Grid item sx={{ m: '5px', height: '30px' }} key={index}>
                  <ThemedButton handler={() => onDemandSubject.setSelectedSeason(element.number)}>
                    {element.number}
                  </ThemedButton>
                </Grid>
              )
            })}
          <Grid item sx={{ m: '5px', height: '30px' }} justifyContent={'center'}>
            {/* <AddCircleOutlineIcon
              onClick={() => onDemandSubject.createNewSeason()}
              sx={{ height: '100%', width: '100%', color: theme.palette.darkGray.main }}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={3} direction="column" justifyContent={'flex-end'} sx={{ height: '100%' }}>
        <Grid item xs={4}>
          <ThemedButton handler={onSaveNewItem}>GUARDAR</ThemedButton>
        </Grid>
      </Grid>
    </>
  )
}
export default CreateProgramControls
