import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.module.css'
import App from './App'
import { AppContextProvider } from './store/app-context'
import Theme from './css/Theme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import React from 'react'
import '@fontsource/montserrat'

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
)
