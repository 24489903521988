const severities = {
  Principiante: 'BEGINNER',
  Intermedio: 'MEDIUM',
  Difícil: 'ADVANCED',
  'Todos los niveles': 'ALL',
}

export const translateSeverities = (severity) => {
  return severities[severity]
}
