import { Button } from '@mui/material'
import Theme from '../../../css/Theme'

export const ThemedButton = (props) => {
  const obj = {
    background: props.background || Theme.palette.gradient.main,
    color: props.color || Theme.palette.primary.main,
    width: props.width || '100%',
    height: props.height || '100%',
    textTransform: props.textTransform || 'none',
    fontSize: props.fontSize || '16px',
    fontWeight: props.fontWeight || 'lighter',
    border: props.border,
    borderRadius: props.borderRadius || '10px',
    '&:hover': { backgroundColor: props.background || Theme.palette.darkerGray.main },
    boxShadow: props.boxShadow || 'none',
    margin: props.margin || '0px',
    // disabled: props.disabled || false,
  }
  return (
    <>
      <Button sx={obj} onClick={props.handler} disabled={props.disabled || false}>
        {props.children}
      </Button>
    </>
  )
}
