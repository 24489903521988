import { Grid } from '@mui/material'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import { useEffect, useState } from 'react'
import Search from './Search'
import DragAndDropList from '../../../shared/Components/DragAndDrop/DragAndDropList'

/**
 * The form to create a new section.
 * @returns a component.
 */
const Section = () => {
  const [addedVideos, setAddedVideos] = useState(onDemandSubject.addedContentToSection)

  /**
   * Updates the local variable addedVideos to the value of addedContentToSection from onDemandSubject
   */
  const onAddedVideosUpdate = () => {
    setAddedVideos(onDemandSubject.addedContentToSection)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onAddedVideosUpdate)
    return () => onDemandSubject.removeObserver(onAddedVideosUpdate)
  }, [])

  /**
   * Executes the function on onDemandSubject that removes a video from the array of section.
   * @param {Object} element video
   */
  const handleRemoveVideos = (element) => {
    onDemandSubject.handleRemoveContentToSection(element)
  }

  return (
    <Grid
      container
      justifyContent="space-evenly"
      direction="row"
      flexWrap="nowrap"
      sx={{ height: '100%', width: '100%' }}>
      <Grid container item direction={'column'} xs={4} sx={{ height: '400px', backgroundColor: 'white' }}>
        <DragAndDropList
          defaultList={addedVideos}
          onChange={(newOrderList) => {
            onDemandSubject.addedContentToSection = newOrderList
          }}
          renderOption={(option) => option.id + '. ' + option.name}
          optionValue={(option, newOrder) => {
            option.weight = newOrder
          }}
          showDeleteButton
          deleteButtonHandler={(i) => handleRemoveVideos(addedVideos[i])}
        />
      </Grid>
      <Search />
    </Grid>
  )
}
export default Section
