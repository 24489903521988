import appSubject from "../../../../subjects/AppSubject";
import { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Paper,
  Typography,
  FormControlLabel,
} from "@mui/material";
import classes from "../../../Calendar/CalendarComponent/CalendarComponent.module.css";
import styled from "@emotion/styled";
import CustomTimePicker from "../../../../shared/Components/CustomTimePicker/CustomTimePicker";
import { ThemedCheckBox } from "../../../../shared/Components/CustomCheckbox/CustomCheckbox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { es } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DesktopDatePicker } from "@mui/lab";
import { ThemedButton } from "../../../../shared/Components/CustomButton/CustomButton";


const ThemedDatePaper = styled(Paper)(() => ({
  background: "none",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  height: "2vh",
  boxShadow: "none",
  padding: "0%",
  paddingBottom: "10%",
}));

const CustomLableInput = ({ children, time, value, lable, onChange }) => {
  const handleTimeChange = (newTime) => {
    if (onChange) {
      onChange(newTime);
    }
    if(time==="start"){
      appSubject.saveTime(time, newTime);
    } else {
      appSubject.testTime(time, newTime)
    }
    
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography className={classes.Lables}>{lable}</Typography>
      </Grid>
      <Grid item container xs={4.25}>
        {value && (
          <FormControl sx={{ width: "100%" }}>
            <CustomTimePicker value={value[time]} handler={handleTimeChange} />
          </FormControl>
        )}
        {children}
      </Grid>
    </>
  );
};

const ScheduleSectionControls = () => {
  const [activeSince, setActiveSince] = useState(appSubject.dateSelected);
  const [activeUntil, setActiveUntil] = useState(appSubject.dateSelected);
  const [timePicked, setTimePicked] = useState(appSubject.selectedTime || new Date());
  const [ , setTimePicked2] = useState(appSubject.dateSelected || new Date());
  const [finishDate, setFinishDate] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [hasScheduledTime, setHasScheduledTime] = useState(formatearFecha(appSubject.newCard.activeUntil) || null)
  const [hasStartTime, setHasStartTime] = useState(formatearFecha(appSubject.newCard.activeSince) || null)
  const [title, setTitle] = useState(appSubject.newCard.title);
  const [subtitle, setSubtitle] = useState(appSubject.newCard.subtitle);
  const [renderKey, setRenderKey] = useState(0);
  const [ , setDefaultHour] = useState(null);

  useEffect(() => {
    setActiveSince(new Date())
    setActiveUntil(new Date())
    if (appSubject.selectedTime) {
      const nextHour = new Date();
      nextHour.setHours(nextHour.getHours() + 1); 
      const defaultTime = {
        start: nextHour
      }
      setDefaultHour(defaultTime); 
    }
  }, []);


  const combineDates = (date, timePicked, origin) => {
    let nuevaFecha = new Date(date);
    if(origin === "start"){
      nuevaFecha.setHours(timePicked.start.getHours());
      nuevaFecha.setMinutes(timePicked.start.getMinutes());
    } else{
      nuevaFecha.setHours(timePicked.getHours());
      nuevaFecha.setMinutes(timePicked.getMinutes());
    }
    return nuevaFecha;
  }

  const handleSetActiveUntil = () => {
      let start = combineDates(activeSince, timePicked, "start");
      let end = combineDates(activeUntil, appSubject.endDate.end, "end");

      if((start > new Date() && end > new Date()) && end > start){
        appSubject.saveCardScheduled(appSubject.newCard.id, activeSince, timePicked, activeUntil, end, appSubject.newCard.action);
        const newScheduledTime = formatearFecha(timePicked.start);
        setHasScheduledTime(newScheduledTime);
        setRenderKey(prevKey => prevKey + 1);
      } else {
        if(end < start){
          alert("La fecha de inicio es posterior a la fecha de fin")
        } else if(end.getTime() === start.getTime()){
          alert("La fecha de inicio y de fecha de fin son iguales")
        }else{
          alert("La fecha de inicio es anterior a la actual");
        } 
      }
  };

  const resetState = useCallback(() => {
    setTitle(appSubject.newCard.title);
    setSubtitle(appSubject.newCard.subtitle);
    setHasScheduledTime(formatearFecha(appSubject.newCard.activeUntil))
    setHasStartTime(formatearFecha(appSubject.newCard.activeSince))
  }, []);

  useEffect(() => {
    // console.log(hasScheduledTime);
  }, [hasScheduledTime]);

  function formatearFecha(fechaString) {

    if (!fechaString) {
      return null;
    }

    const meses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
  
    const fecha = new Date(fechaString);
    
    const dia = fecha.getDate();
    const mes = meses[fecha.getMonth()];
    const año = fecha.getFullYear();
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();
  
    const fechaFormateada = `${dia} de ${mes} de ${año} a las ${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
  
    return fechaFormateada;
  }

  useEffect(() => {
    appSubject.addObserver(resetState);
    return () => {
      appSubject.removeObserver(resetState);
    };
  }, [resetState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTimePicked(currentTime => appSubject.selectedTime || currentTime);
  });

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="space-between"
      wrap="nowrap"
      sx={{ height: "100%" }}
    >
      <Grid
        container
        item
        xs={10}
        rowSpacing={2}
        direction="column"
        wrap="nowrap"
        justifyContent={"flex-start"}
        key={renderKey}
      >
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomLableInput lable={`Título: ${title}`} />
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomLableInput lable={`Subtitulo: ${subtitle}`} />
        </Grid>
        {hasScheduledTime && (
          <><Grid item xs={1} sx={{ width: "100%" }}>
            <CustomLableInput lable={`Activo desde: ${hasStartTime}`} />
          </Grid><Grid item xs={1} sx={{ width: "100%" }}>
              <CustomLableInput lable={`Activo hasta: ${hasScheduledTime}`} />
            </Grid></>
        )}
        
      </Grid>

      <Grid item margin={"10px"}>
        <FormControlLabel
          control={
            <ThemedCheckBox checked={!!isScheduled}>
              {isScheduled ? (
                <FiberManualRecordIcon
                  sx={{ fontSize: "x-small" }}
                  className={classes.DotIcon}
                />
              ) : null}
            </ThemedCheckBox>
          }
          onClick={() => {
            if(!isScheduled){
              setIsScheduled(true)
            }else{
              setIsScheduled(false)
            }
            setIsScheduled(!isScheduled);
          }}
          label={"Programar"}
          className={classes.Checkbox}
        />
      </Grid>

      {isScheduled && (
        <>
        <Grid
          item
          container
          direction="row"
          justifyContent={"space-evenly"}
          alignItems="center"
          className={classes.CustomTextField}
          xs={4}
          sx={{ height: "100%" }}
        >
          <CustomLableInput lable={"Activo desde"}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={es}
              utils={DateFnsUtils}
            >
              <ThemedDatePaper>
                <DesktopDatePicker
                  size="small"
                  disablePast={true}
                  minDate={new Date()}
                  inputFormat="dd/MM/yyyy"
                  value={activeSince}
                  fullWidth={true}
                  onChange={(e) => {
                    // setTimePicked(e);
                    setActiveSince(e);
                    // setActiveUntil(prevActiveUntil => {
                    //   if (e > prevActiveUntil) {
                    //     return e;
                    //   } else {
                    //     return prevActiveUntil;
                    //   }
                    // });
                    setActiveUntil(e)
                    console.log("entra acaaa")
                    console.log(activeUntil)
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ fontSize: "8px" }} />
                  )} />
              </ThemedDatePaper>
            </LocalizationProvider>
          </CustomLableInput>
          <CustomLableInput
            value={appSubject.selectedTime}
            onChange={(newTime) => {
              setTimePicked(newTime);
            }}
            time={"start"}
            lable={"Hora"} />
        </Grid>
        <Grid item margin={"10px"}>
            <FormControlLabel
              control={<ThemedCheckBox checked={!!finishDate}>
                {finishDate ? (
                  <FiberManualRecordIcon
                    sx={{ fontSize: "x-small" }}
                    className={classes.DotIcon} />
                ) : null}
              </ThemedCheckBox>}
              onClick={() => {
                if (!finishDate) {
                  setFinishDate(true);
                } else {
                  setFinishDate(false);
                }
                setFinishDate(!finishDate);
              } }
              label={"Activar fecha fin"}
              className={classes.Checkbox} />
        </Grid>
        </>
      )}

    {finishDate && (
        <Grid
          item
          container
          direction="row"
          justifyContent={"space-evenly"}
          alignItems="center"
          className={classes.CustomTextField}
          xs={4}
          sx={{ height: "100%" }}
        >
          <CustomLableInput lable={"Activo hasta"}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={es}
              utils={DateFnsUtils}
            >
              <ThemedDatePaper>
                <DesktopDatePicker
                  size="small"
                  disablePast={true}
                  minDate={new Date()}
                  inputFormat="dd/MM/yyyy"
                  value={activeUntil}
                  fullWidth={true}
                  onChange={(e) => {
                    setTimePicked2(e);
                    setActiveUntil(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ fontSize: "8px" }}
                    />
                  )}
                />
              </ThemedDatePaper>
            </LocalizationProvider>
          </CustomLableInput>
          <CustomLableInput
            value={appSubject.endDate}
            onChange={(newTime) => {
              setTimePicked2(newTime);
            }}
            time={"end"}
            lable={"Hora"} />
        </Grid>
      )}

      <Grid item xs={1}>
        <ThemedButton
          handler={() => handleSetActiveUntil()}
          disabled={!finishDate}
        >
          PROGRAMAR
        </ThemedButton>
      </Grid>
    </Grid>
  );
};

export default ScheduleSectionControls;