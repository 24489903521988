import React from "react"
import classes from "./LoginPage.module.css"
import AuthForm from "../../components/AuthForm/AuthForm";


const LoginPage = () => {
  return (
    <div className={classes.backgroundDiv}>
      <div className={classes.borderContainer}>
        <AuthForm/>
      </div>
    </div>
  );
};

export  default  LoginPage