import genericAxiosClient from '../axios/GenericAxiosClient'

class ClubService {
  baseUrl = '/admin/club'

  put(salon) {
    return genericAxiosClient.put(this.baseUrl+"/salons", salon)
  }

  delete(id) {
    return genericAxiosClient.delete(`${this.baseUrl}/${id}`)
  }
}

const clubService = new ClubService()
export default clubService
