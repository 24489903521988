import { Divider } from '@mui/material'

const CustomDivider = () => {
  return (
    <Divider
      sx={{
        width: '90%',
        height: '1px',
        backgroundImage: 'linear-gradient(to right, rgb(210, 210, 210),rgb(255, 255, 255), rgb(210, 210, 210))',
        margin: '20px',
        '&:before': {},
      }}
      variant="middle"
    />
  )
}
export default CustomDivider
