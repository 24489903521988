import salonSubject from '../../subjects/SalonSubject'
import { SalonViewer } from './PushViewer/SalonViewer'
import { Divider, Grid, Typography } from '@mui/material'
import CustomTextField from '../../shared/Components/CustomTextField/CustomTextField'
import theme from '../../css/Theme'

const PushSalonViewer = () => {

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>Configuración</Typography>
          <Typography variant="h4">Salones</Typography>
        </Grid>
        <Grid container item xs={4} direction="column">
          {(
            <Grid item xs={6}>
              <CustomTextField searchbutton={1} onChange={(e) => salonSubject.searchHandler(e)} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={0.25}>
        <Divider orientation="horizontal" color={theme.palette.primary.main} />
      </Grid>
      <SalonViewer />
    </>
  )
}
export default PushSalonViewer