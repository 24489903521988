import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Grid, TextareaAutosize, Typography } from '@mui/material'
import theme from '../../../css/Theme'
import pushNotificationSubject from '../../../subjects/PushNotificationsSubject'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CreateBatchViewer from './CreateBatchViewer'
import CustomDivider from '../../../shared/Components/CustomDivider/CustomDivider'

/**
 * Local textareaAutosize customization
 */
const ThemedTextArea = styled(TextareaAutosize)(() => ({
  width: '90%',
  backgroundColor: 'transparent',
  border: '1px solid white',
  borderRadius: '4px',
  padding: '10px',
  '&::placeholder': {
    padding: '2%',
    textOverflow: 'ellipsis !important',
    color: 'white !important',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'medium',
  },
}))

/**
 * Renders a part of the form to create a new notification.
 * @returns
 */
const PushCreateViewer = () => {
  const [selectedType] = useState('BATCH')

  return (
    <Grid
      direction={'column'}
      wrap="nowrap"
      container
      rowSpacing={1}
      sx={{
        backgroundColor: theme.palette.lightGray.main,
        padding: '10px',
        borderRadius: '15px',
        margin: '10px 0',
        overflow: 'auto',
        height: '100%',
      }}>
      <Grid item>
        <ArrowForwardIosIcon
          onClick={() => {
            pushNotificationSubject.cleanSelectedItem()
            pushNotificationSubject.setSelectedPage('viewer')
          }}
          sx={{
            fontSize: 'x-large',
            transform: 'rotate(180deg)',
            color: theme.palette.darkerGray.main,
            cursor: 'pointer',
          }}
        />
      </Grid>
      <Grid direction="row" justifyContent={'center'} gap={4} container item>
        <Grid item xs={4}>
          <Typography align="right" color={theme.palette.darkerGray.main} fontWeight={800}>
            Descripción
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ThemedTextArea
            placeholder="Descripción (250 caracteres máx)"
            minRows={5}
            maxRows={5}
            defaultValue={
              pushNotificationSubject.newNotificationPush.description
            }
            onChange={(e) => (pushNotificationSubject.newNotificationPush.description = e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <CustomDivider />
      </Grid>
      {selectedType === 'BATCH' && <CreateBatchViewer />}
      {/* {selectedType === 'CUSTOM' && <CreateCustomViewer />} */}
    </Grid>
  )
}
export default PushCreateViewer
