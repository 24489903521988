import { Divider, Grid, Typography } from '@mui/material'
import ContentList from '../../../../shared/Components/ContentList/ContentList'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import appSubject from '../../../../subjects/AppSubject'
import { Link } from 'react-router-dom'

const CardFinder = ({ handleCards }) => {
  return (
    <Grid
      container
      item
      wrap="nowrap"
      direction="column"
      xs={7}
      sx={{ backgroundColor: '#666666', borderRadius: '15px', height: '100%' }}>
      <Grid container item xs={2} direction="row" padding={'10px'}>
        <Grid container item xs={5} direction={'column'} padding={'10px'} justifyContent="center">
          <Grid item xs={5}>
            <Typography fontSize={'15px'}>
              {appSubject.cards.filter((elem) => elem.enabled).length} Cards Activas
            </Typography>
            <Grid item xs={5}>
              <Link to="/app/cards" style={{ textDecoration: 'none' }}>
                <ThemedButton
                  height="25px"
                  size="small"
                  background="#ffa33e"
                  fontWeight="bold"
                  width="130px"
                  textTransform="uppercase"
                  borderRadius="5px"
                  fontSize="12px">
                  Nueva card
                </ThemedButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={7}
          direction={'column'}
          justifyContent={'center'}
          alignContent="flex-end"
          spacing={1}
          paddingX="30px">
          <Grid item container xs={5} direction="column" width={'90%'}>
            <Grid item>
              <CustomTextField
                autoFocus
                searchbutton={1}
                background={'#666666'}
                onChange={(e) => {
                  appSubject.searchCardsforSections(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          width: '90%',
          height: '1px',
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0),rgb(255, 255, 255), rgba(0, 0, 0, 0))',
          marginY: '10px',
        }}
      />
      <Grid
        container
        item
        xs={8}
        direction="row"
        justifyContent={'flex-start'}
        alignContent={'flex-start'}
        sx={{ height: '100%', width: '100%', overflowY: 'auto' }}>
        <ContentList type="homeSections" handleAddContent={handleCards} />
      </Grid>
    </Grid>
  )
}

export default CardFinder
