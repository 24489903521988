import { useEffect, useState } from 'react'
import CustomAccordion from '../../../shared/Components/CustomAccordion/CustomAccordion'
import classSubject from '../../../subjects/ClassSubject'
import {Grid, Typography } from '@mui/material'
import theme from '../../../css/Theme'
import TaskAlt from '@mui/icons-material/TaskAlt'

export const ClassReservationGrid = () => {
    const [filteredBookings, setBookings] = useState(classSubject.filteredBookings)
    const [expanded, setExpanded] = useState(false)

    const onBookingsUpdate = () => {
        setBookings(classSubject.filteredBookings)
    }

    useEffect(() => {
        classSubject.addObservers(onBookingsUpdate)
        return () => {
            classSubject.removeObservers(onBookingsUpdate)
        }
    }, [])
    return (
        <>
            <Grid
                container
                item
                wrap="nowrap"
                direction="column"
                xs={10}
                sx={{
                    overflowY: 'auto',
                    background: theme.palette.primary.main,
                    height: '100%',
                }}>
                {filteredBookings.map((booking, i) => {
                    return (
                        <CustomAccordion
                            key={i}
                            id={`${booking.id}`}
                            page="classReservation"
                            expanded={expanded}
                            setExpanded={setExpanded}
                            infoObj={booking}
                            subject={filteredBookings}>
                            <ClassSummary value={booking} />
                        </CustomAccordion>
                    )
                })}
            </Grid>
        </>
    )
}
const ClassSummary = ({ value }) => {
    return (
        <>
            <Grid item container direction="row">
                <Grid item container xs={2}>
                    <Typography color={theme.palette.darkGray.main} noWrap sx={{ fontWeight: 'bolder' }}>
                    {value.id}
                    </Typography>
                </Grid>
                <Grid item container xs={4}>
                    <Typography sx={{ color: theme.palette.darkGray.main }}>{value.name.toUpperCase()}</Typography>
                </Grid>
              
                <Grid item container xs={2}>
                    <TaskAlt sx={{color: value.iconColor}}/>
                </Grid>
            </Grid>
        </>
    )
}
