export class SalonTemplate {
    id;
    name = ''
    active;
  
    constructor(jsonResponse) {
      if (jsonResponse) {

        if (jsonResponse.id)
          this.id = jsonResponse.id

        this.name = jsonResponse.name
        
        if (jsonResponse.active)
          this.active = jsonResponse.active
      }
    }
  }