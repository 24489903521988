import genericAxiosClient from '../axios/GenericAxiosClient'

class SalonService {
  baseUrl = '/admin/planning/salons'

  async getSalons() {
    const response = await genericAxiosClient.get(this.baseUrl)
    return response.data
  }

  async post(salon) {
    return await genericAxiosClient.post(this.baseUrl, salon)
  }

  put(salon) {
    return genericAxiosClient.put(this.baseUrl, salon)
  }

  delete(id) {
    return genericAxiosClient.delete(`${this.baseUrl}/${id}`)
  }
}

const salonService = new SalonService()
export default salonService
