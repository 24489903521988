import styled from '@emotion/styled'
import { AccordionDetails, Button, Divider, Grid, Typography } from '@mui/material'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import ElementIcon from '../../UI/ElementIcon'
import ImageDetails from './ImageDetails'

/**
 * Local typography customization
 */
const TypographyOnDetails = styled(Typography)(() => ({
  color: 'white',
  fontSize: '12px',
}))
/**
 * Renders al the details of a video.
 * @param {Object} infoObj data of the video.
 * @returns a component.
 */
const VideoDetails = ({ infoObj }) => {
  const getDateFormat = (date) => {
    let str = `${date.getDate()}/${('0' + (parseInt(date.getMonth()) + 1)).slice(-2)}/${(
      '0' + date.getFullYear()
    ).slice(-2)}`
    return str
  }
  /**
   * Executes the onSelectItemToModify function from the onDemandSubject that switches the page to the createVideo page, sets the isCreating variable to true and sets the corresponding variables with the data to modify.
   */
  const handleModify = () => {
    onDemandSubject.onSelectItemToModify(infoObj, 'videos')
  }
  /**
   * Executes the deleteSelectedItem function from the onDemandSubject deletes a video from the sections array.
   */
  const handleDelete = () => {
    onDemandSubject.deleteSelectedItem(infoObj.id, 'videos')
  }

  return (
    <AccordionDetails sx={{ padding: '0 1%', marginBottom: '3px' }}>
      <Grid
        container
        direction="row"
        sx={{
          backgroundColor: '#666666',
          padding: '10px',
          borderRadius: '0 0 10px 10px',
        }}>
        <Grid container item direction={'column'} xs={6} sx={{ height: '100%' }}>
          <Grid container item direcion="row" xs={6} sx={{ height: '100%' }}>
            <Grid container item direction="row" xs={8} margin="10px">
              <ImageDetails image={infoObj.previewImage} link={infoObj.link} />
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={3}>
            <TypographyOnDetails sx={{ fontStyle: 'italic' }}>
              Con {infoObj.coaches.name ? infoObj.coaches.name : onDemandSubject.getCoachesName(infoObj.id)}
            </TypographyOnDetails>
            {infoObj.duration && (
              <TypographyOnDetails sx={{ fontStyle: 'italic' }}>Duración: {infoObj.duration} min</TypographyOnDetails>
            )}
          </Grid>
          {infoObj.elements.length > 0 && (
            <Grid container item direction="column" xs={3}>
              <Grid item xs={4}>
                <TypographyOnDetails sx={{ fontWeight: 'bold' }}>Elementos</TypographyOnDetails>
              </Grid>
              <Grid item container direction="row" xs={8}>
                {infoObj.elements.map((element, index) => (
                  <Grid
                    item
                    xs={1.75}
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      m: '2px',
                      background: '#333333',
                      position: 'relative',
                      borderRadius: '15px',
                    }}>
                    <ElementIcon src={element.image} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container item direction={'column'} xs={6} sx={{ height: '100%' }}>
          <Grid container item direction="column" xs={10}>
            <TypographyOnDetails sx={{ fontWeight: 'bold' }}>{infoObj.name}</TypographyOnDetails>
            <TypographyOnDetails sx={{ m: '5px 0px', fontWeight: 'bold' }}>
              {infoObj.shortDescription}
            </TypographyOnDetails>
            <TypographyOnDetails>{infoObj.longDescription}</TypographyOnDetails>
            <Grid sx={{ mt: '10px', mb: '10px' }}>
              <TypographyOnDetails sx={{ fontWeight: 'bold' }}>Objetivos</TypographyOnDetails>
              {infoObj.goals.map((element, index) => (
                <TypographyOnDetails key={index}>{element}</TypographyOnDetails>
              ))}
            </Grid>
            <Divider orientation="horizontal" sx={{ backgroundColor: 'white' }} />
            <Grid container item direction="row">
              <Grid item container direction="column" xs={6} sx={{ mt: '10px', mb: '10px' }}>
                <TypographyOnDetails sx={{ fontWeight: 'bold' }}>Fecha inicio</TypographyOnDetails>
                <TypographyOnDetails>{getDateFormat(new Date(infoObj.startAt))}</TypographyOnDetails>
              </Grid>
              <Grid item container direction="column" xs={6} sx={{ mt: '10px', mb: '10px' }}>
                <TypographyOnDetails sx={{ fontWeight: 'bold' }}>Fecha fin</TypographyOnDetails>
                <TypographyOnDetails>{getDateFormat(new Date(infoObj.endAt))}</TypographyOnDetails>
              </Grid>
            </Grid>
            <Divider orientation="horizontal" sx={{ backgroundColor: 'white' }} />
          </Grid>
          <Grid container item direction="row" xs={2} sx={{ mt: '5px' }}>
            <Grid item xs={6}>
              <Button
                onClick={handleModify}
                sx={{ backgroundColor: 'transparent', color: 'red', border: '2px solid red', width: '120px' }}>
                MODIFICAR
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleDelete}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#cecece',
                  border: '2px solid #cecece',
                  width: '120px',
                }}>
                ELIMINAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails>
  )
}
export default VideoDetails
