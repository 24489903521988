import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import appSubject from '../../../subjects/AppSubject'
import onDemandSubject from '../../../subjects/OnDemandSubject'

/**
 *
 * @param {Function} handleAddContent function that adds or removes content from an array
 * @param {String} type specifies the array to witch the content is added. The type will vary acording to the page variable.
 * @returns a component with the list of content added to the selected array.
 */
const ContentList = ({ handleAddContent, type, selectedFilter }) => {
  const [filteredContent, setFilteredContent] = useState([])
  /**
   * sets the local variable filteredContent to the correct array from Subject according to the type variable.
   */
  const setCorrectData = useCallback(() => {
    setFilteredContent([])
    if (type === 'section') {
      if (selectedFilter === 'Programas') {
        setFilteredContent(onDemandSubject.filterProgramsForSectionDetails)
      } else {
        setFilteredContent(onDemandSubject.filterVideosForSectionDetails)
      }
    }

    if (type === 'program') {
      setFilteredContent(onDemandSubject.filterVideosForProgramDetails)
    }

    if (type === 'homeSections') {
      setFilteredContent(appSubject.filteredCards)
    }
  }, [type, selectedFilter])

  useEffect(() => {
    setCorrectData()
    if (type === 'homeSections') appSubject.addObserver(setCorrectData)
    if (type === 'program' || type === 'section') onDemandSubject.addSearchObserver(setCorrectData)
    return () => {
      if (type === 'homeSections') appSubject.removeObserver(setCorrectData)
      if (type === 'program' || type === 'section') onDemandSubject.removeSearchObserver(setCorrectData)
    }
  }, [setCorrectData, type])

  return (
    <>
      {filteredContent.length > 0 &&
        filteredContent.map((element, index) => (
          <Grid key={index} item xs={3.5} sx={{ position: 'relative', margin: '5px', height: '80px' }}>
            <Box
              onClick={() => {
                handleAddContent(element)
                setCorrectData()
              }}
              component="img"
              sx={{ height: '100%', width: '100%', margin: '0 5px', objectFit: 'cover' }}
              src={
                type === 'homeSections'
                  ? process.env.REACT_APP_RESOURCES_BASEURL + (element.images && element.images[0] ? element.images[0].image : '')
                  : process.env.REACT_APP_RESOURCES_BASEURL + element.previewImage
              }
              alt="Imagen no disponible."
            />
            <Typography
              noWrap
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: '5px',
                backgroundColor: 'rgba(0,0,0,0.8)',
                color: 'white',
                fontSize: '12px',
              }}>
              {type !== 'homeSections' ? `${element.id}. ${element.name}` : `${element.id}. ${element.title}`}
            </Typography>
          </Grid>
        ))}
    </>
  )
}
export default ContentList
