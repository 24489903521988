import { useEffect, useState } from 'react'
import CustomAccordion from '../../../shared/Components/CustomAccordion/CustomAccordion'
import pushNotificationSubject from '../../../subjects/PushNotificationsSubject'
import { Divider, Grid, Typography } from '@mui/material'
import theme from '../../../css/Theme'

/**
 * Renders the array of notifications as accordions.
 * @returns
 */
export const PushViewer = () => {
  const [pushNotification, setPushNotification] = useState(pushNotificationSubject.filteredNotificationPush)
  const [expanded, setExpanded] = useState(false)

  /**
   * Updates the local push notification array to the filtered notifiactions array from the ṕush notification subject.
   */
  const onPushNotificationUpdate = () => {
    setPushNotification(pushNotificationSubject.filteredNotificationPush)
  }
  /**
   * Subscribes the component to the subject.
   */
  useEffect(() => {
    pushNotificationSubject.addObservers(onPushNotificationUpdate)
    return () => {
      pushNotificationSubject.removeObservers(onPushNotificationUpdate)
    }
  }, [])
  return (
    <>
      <Grid
        container
        item
        wrap="nowrap"
        direction="column"
        xs={10}
        sx={{
          overflowY: 'auto',
          background: theme.palette.primary.main,
          height: '100%',
        }}>
        {pushNotification.map((notifications, i) => {
          return (
            <CustomAccordion
              key={i}
              id={`${notifications.name}-${i}`}
              page="notificationsPush"
              expanded={expanded}
              setExpanded={setExpanded}
              infoObj={notifications}
              subject={pushNotification}>
              <NotificationSummary value={notifications} />
            </CustomAccordion>
          )
        })}
      </Grid>
    </>
  )
}
const NotificationSummary = ({ value }) => {
  return (
    <>
      <Grid item container direction="row">
        <Grid item container direction="row" xs={6}>
          <Typography color={theme.palette.darkGray.main} noWrap sx={{ fontWeight: 'bolder' }}>
            {value.name}
          </Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography sx={{ color: theme.palette.darkOrange.main }}>{value.status}</Typography>
        </Grid>
        <Divider orientation="vertical" color={theme.palette.primary.main} flexItem={true} />
        <Grid item container xs={3}>
          <Grid item xs={6}>
            <Typography sx={{ textAlign: 'center' }} color={theme.palette.darkGray.main}>
              {value.type.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ textAlign: 'center' }} color={theme.palette.darkGray.main}>
              {value.category.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
