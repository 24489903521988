export default class SaveHomeCardModel {
  title
  subtitle
  action
  enabled
  images
  scheduledDate
  endDate
  sectionToModify
  constructor(newCard, selectedSection) {
    let action = Object.assign({ type: newCard.action }, { ...newCard.actionConfig })

    this.title = newCard.title
    this.subtitle = newCard.subtitle
    this.action = action
    this.enabled = newCard.enabled
    this.images = newCard.images || []
    if (selectedSection) {
      this.sectionToModify = selectedSection
    }
    
    if ('scheduledDate' in newCard) {
      this.scheduledDate = newCard.scheduledDate;
    }

    if('endDate' in newCard){
      this.endDate = newCard.endDate;
    }
    
  }
}
