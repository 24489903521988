import React, { useEffect, useState } from 'react'
import { Grid, Input, Typography } from '@mui/material'
import theme from '../../../css/Theme'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { Box } from '@mui/system'
import pushNotificationSubject from '../../../subjects/PushNotificationsSubject'
import CustomDivider from '../../../shared/Components/CustomDivider/CustomDivider'

/**
 * Renders a part of a form that creates a new batch notification.
 * @returns
 */
export default function CreateBatchViewer() {
  const [file, setFile] = useState(pushNotificationSubject.file || null)

  /**
   * Updates the local file variable to the value of file in the new notification instance, from the push notification subject.
   */
  const onFileUpdate = () => {
    setFile(pushNotificationSubject.file)
  }

  useEffect(() => {
    pushNotificationSubject.addObservers(onFileUpdate)
    return () => pushNotificationSubject.removeObservers(onFileUpdate)
  }, [])

  return (
    <>
      <Grid direction="row" container item justifyContent={'center'} gap={4} sx={{ my: '10px' }}>
        <Grid item xs={4}>
          <Typography align="right" color={'red'} fontWeight={800} sx={{ fontSize: '15px' }}>
            Notificación BATCH
          </Typography>
        </Grid>
      </Grid>
      <Grid direction="row" justifyContent={'center'} alignItems={'center'} gap={4} container item sx={{ my: '10px' }}>
        <Grid item xs={4}>
          <Typography align="right" fontWeight={900} sx={{ fontSize: '15px' }} color={theme.palette.darkerGray.main}>
            Subir archivo CSV
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <label>
            <Input
              inputProps={{ accept: '.csv' }}
              id="contained-button-file"
              multiple
              type="file"
              sx={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files.length === 0) return e.preventDefault()
                if (e.target.files[0].type === 'text/csv') pushNotificationSubject.updateFile(e.target.files[0])
                else return alert('Not supported type')
              }}
            />
            <Box
              sx={{
                paddingRight: '2%',
                border: '3px solid white',
                borderRadius: '6px',
                backgroundColor: 'transparent',
                height: '40px',
                width: '60%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Typography sx={{ ml: 1 }}>{file && file.name}</Typography>
              <DriveFolderUploadIcon style={{ color: 'red' }} fontSize={'large'} />
            </Box>
          </label>
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <CustomDivider />
      </Grid>
    </>
  )
}
