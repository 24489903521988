import { Divider, Grid, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import CustomAccordion from '../../../shared/Components/CustomAccordion/CustomAccordion'
import { ThemedCheckBox } from '../../../shared/Components/CustomCheckbox/CustomCheckbox'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import Theme from '../../../css/Theme'

const VideoList = (pagination) => {
  const [videos, setVideos] = useState(onDemandSubject.filteredVideo)
  const [expanded, setExpanded] = useState(false)

  /**
   * Updates the local variable videos to the value of filteredVideos from onDemandSubject
   */
  const onVideosUpdate = () => {
    setVideos(onDemandSubject.filteredVideo)
  }

  useEffect(() => {
    onVideosUpdate()
  }, [pagination])

  useEffect(() => {
    onDemandSubject.addObserver(onVideosUpdate)
    return () => onDemandSubject.removeObserver(onVideosUpdate)
  }, [])

  useEffect(() => {
    onDemandSubject.addSearchObserver(onVideosUpdate)
    return () => onDemandSubject.removeSearchObserver(onVideosUpdate)
  }, [])

  return (
    <div>
      {videos &&
        videos.map((element, index) => (
          <CustomAccordion
            key={index}
            page={'videos'}
            id={`videos-${index}`}
            infoObj={element}
            expanded={expanded}
            setExpanded={setExpanded}
            subject={videos}>
            <VideoSummary value={element} />
          </CustomAccordion>
        ))}
    </div>
  )
}
export default VideoList

const VideoSummary = ({ value }) => {
  return (
    <>
      <ThemedCheckBox borderColor={Theme.palette.darkerGray.main} />
      <Grid item container direction="row" xs={12}>
        <Grid item container direction="row" xs={6} justifyContent="space-between">
          <Typography noWrap sx={{ fontWeight: 'bolder' }}>
            {value.id + '. ' + value.name}
          </Typography>
        </Grid>
        <Grid item container xs={0.5}>
          <Divider orientation="vertical" color="black" flexItem={true} />
        </Grid>
        <Grid item container xs={5} justifyContent={'center'}></Grid>
      </Grid>
    </>
  )
}
