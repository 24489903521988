import genericAxiosClient from '../axios/GenericAxiosClient'

class NotificationService {
  baseUrl = '/admin/notifications'

  async getAll() {
    const response = await genericAxiosClient.get(this.baseUrl)
    return response.data.map((element) => {
      if (!element.status) element.status = 'INACTIVE'
      if (!element.sendingDate) element.sendingDate = new Date()
      else element.sendingDate = new Date(element.sendingDate)
      return element
    })
  }

  async getFailedCasesFile(id) {
    const response = await genericAxiosClient.getFile(`${this.baseUrl}/csv/failed/${id}`)
    const href = URL.createObjectURL(response.data)

    const link = document.createElement('a')
    link.setAttribute('download', 'failed_cases.csv')
    link.href = href

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  async getSuccessfulCasesFile(id) {
    const response = await genericAxiosClient.getFile(`${this.baseUrl}/csv/successful/${id}`)
    const href = URL.createObjectURL(response.data)

    const link = document.createElement('a')
    link.setAttribute('download', 'successful_cases.csv')
    link.href = href

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  async getCategories() {
    const response = await genericAxiosClient.get(`${this.baseUrl}/categories`)
    return response.data
  }

  async post(notification) {
    return await genericAxiosClient.post(this.baseUrl, notification)
  }

  async sendFile(id, CSVFile) {
    const formData = new FormData()
    formData.append('file', CSVFile)
    return await genericAxiosClient.post(`${this.baseUrl}/${id}/attachment`, formData)
  }

  async sendFileScheduled(id, CSVFile, date) {
    const formData = new FormData()
    formData.append('file', CSVFile)
    formData.append('date', date)
    return await genericAxiosClient.post(`${this.baseUrl}/${id}/schedule-push`, formData)
  }

  put(id, notification) {
    return genericAxiosClient.put(`${this.baseUrl}/${id}`, notification)
  }

  async resendNotifications(id) {
    return await genericAxiosClient.post(`${this.baseUrl}/${id}/resend`);
  }

  async cancelNotifications(id) {
    return await genericAxiosClient.put(`${this.baseUrl}/${id}/cancel`);
  }
}


const notificationService = new NotificationService()
export default notificationService
