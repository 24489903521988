import { Fragment, useContext } from 'react';
import classes from './Layout.module.css'
import React from "react";
import SideMenu from "../UI/SideMenu/SideMenu";
import AppContext from "../../store/app-context";

const Layout = (props) => {
  const authCtx = useContext(AppContext);
  let appMenu = null;
  if (authCtx.isLoggedIn) {
    appMenu = (<SideMenu/>)
  }

  let mainContent = (
      <Fragment>
        {props.children}
      </Fragment>
  )

  return (
    <Fragment>
      {appMenu}
      <main className={classes.mainClass}>
        {mainContent}
      </main>
    </Fragment>
  );
};

export default Layout;