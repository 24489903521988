import axios from 'axios'

class ResourcesAxiosClient {
  defaultConfig = {
    baseURL: process.env.REACT_APP_BASEURL,
  }

  instance = axios.create(this.defaultConfig)

  async post(url, imgFile) {
    const formData = new FormData()
    formData.append('image', imgFile)

    return await this.instance.post(url, formData)
  }
}

const resourcesAxiosClient = new ResourcesAxiosClient()
export default resourcesAxiosClient
