import { translateSeverities } from '../../Utils/translateSeverity'

export class SaveProgramRequestModel {
  name
  short_description
  long_description
  severity
  preview_image
  start_at
  end_at
  seasons
  view_types
  access_levels

  constructor(newProgram) {
    this.name = newProgram.name
    this.short_description = newProgram.shortDescription
    this.long_description = newProgram.longDescription
    this.severity = translateSeverities(newProgram.severity)
    this.preview_image = newProgram.previewImage
    this.start_at = newProgram.startAt || ''
    this.end_at = newProgram.endAt || ''
    this.seasons = newProgram.seasons.map((element) => {
      if (element.videos.length > 0) {
        return {
          number: element.number,
          videos: element.videos.map((video, index) => {
            return { id: video.id, order: index + 1 }
          }),
        }
      } else {
        return [{ number: 1, videos: [] }]
      }
    })

    this.view_types = newProgram.viewTypes
      ? newProgram.viewTypes.map((element) => {
          return { view_type: element.viewType, image: element.image }
        })
      : []
    this.access_levels = newProgram.accessLevels
  }
}
