import { Grid, Divider, Typography } from '@mui/material'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import CustomTextField from '../../../shared/Components/CustomTextField/CustomTextField'
import { useEffect, useState } from 'react'
import ContentList from '../../../shared/Components/ContentList/ContentList'
import DragAndDropList from '../../../shared/Components/DragAndDrop/DragAndDropList'
import dragAndDropSubject from '../../../shared/Components/DragAndDrop/DragAndDropSubject'

/**
 * The form to crate a new program.
 * @returns a component
 */
const Program = () => {
  const [addedVideos, setAddedVideos] = useState([])

  /**
   * Updates the local variable addedVideos to the value of addedVideosToPrograms from onDemandSubject
   */
  const onAddedVideosUpdate = () => {
    setAddedVideos(onDemandSubject.addedVideosToPrograms)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onAddedVideosUpdate)
    return () => onDemandSubject.removeObserver(onAddedVideosUpdate)
  }, [])

  useEffect(() => {
    onDemandSubject.getSelectedSeason()
    return () => (onDemandSubject.filterVideosForProgramDetails = [...onDemandSubject.videos])
  }, [])

  /**
   * Executes the function on onDemandSubject that adds a video to the array of programs.
   * @param {Object} element a video from the filteredVideos array
   */
  const handleAddVideos = (element) => {
    onDemandSubject.handleAddVideosToPrograms(element)
    dragAndDropSubject.addItemsToList(element)
  }
  /**
   * Executes the function on onDemandSubject that removes a video from the array of programs.
   * @param {Object} element a video from the filteredVideos array
   */
  const handleRemoveVideos = (element) => {
    onDemandSubject.handleRemoveVideosToPrograms(element)
    dragAndDropSubject.removeItemFromList(element)
  }

  return (
    <Grid
      container
      justifyContent="space-evenly"
      direction="row"
      flexWrap="nowrap"
      sx={{ height: '100%', width: '100%' }}>
      <Grid container item direction={'column'} xs={4} sx={{ height: '380px' }}>
        <DragAndDropList
          defaultList={addedVideos}
          onChange={(newOrderList) => {
            onDemandSubject.reorderContentInPrograms(newOrderList)
          }}
          renderOption={(option) => option.id + '. ' + option.name}
          optionValue={(option, newOrder) => {
            option.order = newOrder
          }}
          showDeleteButton
          deleteButtonHandler={(i) => handleRemoveVideos(addedVideos[i])}
        />
      </Grid>
      <Grid
        container
        item
        direction="column"
        xs={6.5}
        sx={{ backgroundColor: '#666666', borderRadius: '15px', height: '380px' }}>
        <Grid container item xs={2} direction="row" sx={{ padding: '10px', marginTop: '10px' }}>
          <Grid item xs={5} padding="10px">
            <Typography>{onDemandSubject.totalElementsVideo} videos</Typography>
          </Grid>
          <Grid item container xs={7} direction="column">
            <Grid item>
              <CustomTextField
                autoFocus
                onChange={(e) => onDemandSubject.searchHandler(e, 'createProgram')}
                searchbutton={1}
                background={'#666666'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="horizontal" sx={{ backgroundColor: 'white', margin: '10px' }} />
        <Grid
          container
          item
          xs={9}
          direction="row"
          justifyContent={'flex-start'}
          sx={{ height: '100%', width: '100%', overflowY: 'auto' }}>
          <ContentList handleAddContent={handleAddVideos} type="program" />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Program
