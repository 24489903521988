import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import CalendarPage from '../pages/CalendarPage/CalendarPage'
import ClassReservationPage from '../pages/ClassReservation/ClassReservationPage'

const ClubRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<CalendarPage />} />
      <Route path="/planificacion/calendario" exact element={<CalendarPage />} />
      <Route path="/clases/reservar" exact element={<ClassReservationPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default ClubRoutes
