import styled from '@emotion/styled'
import { AccordionDetails, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import onDemandSubject from '../../../subjects/OnDemandSubject'

/**
 * Local typography customization
 */
const TypographyOnDetails = styled(Typography)(() => ({
  color: 'white',
  fontSize: '13px',
  margin: '5px 0',
}))

/**
 * Local box customization
 */
const ListBox = styled(Box)(() => ({
  height: '220px',
  width: '80%',
  marginTop: '5px',
  marginLeft: '5px',
  backgroundColor: 'white',
}))
/**
 * Renders al the details of a program.
 * @param {Object} infoObj data of the program.
 * @returns a component.
 */
const ProgramDetails = ({ infoObj }) => {
  /**
   * Executes the deleteSelectedItem function from the onDemandSubject deletes a program from the programs array.
   */
  const handleDelete = () => {
    onDemandSubject.deleteSelectedItem(infoObj.id, 'program')
  }

  return (
    <AccordionDetails sx={{ padding: '0 1%', marginBottom: '3px' }}>
      <Grid
        container
        direction="row"
        alignItems={'stretch'}
        sx={{ backgroundColor: '#666666', padding: '10px', borderRadius: '0 0 10px 10px' }}>
        <Grid container item xs={6} direction="row" sx={{ height: '100%' }}>
          <Grid
            item
            xs={9}
            container
            direction={'column'}
            alignContent={'center'}
            sx={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'auto' }}>
            <ListBox>
              {infoObj.seasons.map((element) =>
                element.videos.map((elem, index) => {
                  return (
                    <Grid
                      container
                      item
                      key={index}
                      direction="row"
                      justifyContent="space-evenly"
                      sx={{ marginY: '5px', width: '100%', border: '1px solid #ffa33e', borderRadius: '5px' }}>
                      <Grid item xs={9}>
                        <Tooltip title={elem.name} disableInteractive>
                          <Typography noWrap sx={{ width: '100%', fontWeight: '600', fontSize: '15px' }}>
                            {elem.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                })
              )}
            </ListBox>
          </Grid>
        </Grid>
        <Grid container item xs={6} direction={'column'} sx={{ height: '100%' }}>
          <Grid item xs={3}>
            <TypographyOnDetails>{infoObj.name}</TypographyOnDetails>
            <TypographyOnDetails sx={{ fontWeight: 'bold' }}>RECOMENDADOS</TypographyOnDetails>
            <TypographyOnDetails>{infoObj.seasons.length} temporada/s</TypographyOnDetails>
          </Grid>
          <Grid item xs={3}>
            <TypographyOnDetails>{infoObj.longDescription}</TypographyOnDetails>
          </Grid>
          <Grid item xs={3} marginY="10px">
            <Divider orientation="horizontal" sx={{ backgroundColor: 'white' }} />
          </Grid>
          <Grid item container xs={3} direction={'row'}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  onDemandSubject.onSelectItemToModify(infoObj, 'program')
                }}
                sx={{ backgroundColor: 'transparent', color: 'red', border: '2px solid red', width: '120px' }}>
                MODIFICAR
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleDelete}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#cecece',
                  border: '2px solid #cecece',
                  width: '120px',
                }}>
                ELIMINAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails>
  )
}
export default ProgramDetails
