import { useState, useContext } from 'react'
import { Button, Typography, Box, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import AppContext from '../../store/app-context'
import styled from '@emotion/styled'
import React from 'react'
import Theme from '../../css/Theme'
import classes from './AuthForm.module.css'
import Grid from '@mui/material/Grid/Grid'
import { updateObject, checkValidity } from '../../shared/utility'
import { useNavigate } from 'react-router'
import authService from '../../http/services/AuthService'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const ThemedButton = styled(Button)(() => ({
  background: Theme.palette.gradient.main,
  color: Theme.palette.primary.main,
  width: '100%',
  height: '60px',
  textTransform: 'none',
  fontSize: '20px',
  fontWeight: 'lighter',
}))

const ThemedOutlinedInput = styled(OutlinedInput)(() => ({
  width: '100%',
  backgroundColor: '#f2f2f2b2',
  borderColor: '#f2f2f2',
  borderRadius: '5px',
  borderStyle: 'solid',
  borderWidth: '2px',
  '.MuiOutlinedInput-notchedOutline': {
    border: 'hidden',
  },
  '.Mui-error': {
    backgroundColor: '#ffcdd2b2',
  },
}))

const AuthForm = () => {
  const authCtx = useContext(AppContext)
  let navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const loginButtonRef = React.useRef(null)

  let initialControls = {
    email: {
      type: 'email',
      label: 'Mail Address',
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      error: false,
    },
    password: {
      type: 'password',
      label: 'Password',
      validation: {
        required: true,
        minLength: 5,
      },
      error: false,
      endAdornment: true,
    },
  }

  const [controls, setControls] = useState(initialControls)
  const [shouldSavePassword] = useState(false)

  let inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(controls, {
      [controlName]: updateObject(controls[controlName], {
        value: event.target.value,
        error: !checkValidity(event.target.value, controls[controlName].validation),
      }),
    })
    setControls(updatedControls)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      loginButtonRef.current.click()
    }
  }

  let onClickHandler = async () => {
    authCtx.setLoading(true)
    try {
      let token = await authService.login(controls.email.value, controls.password.value)
      const date = new Date()
      date.setMilliseconds(3600 * 1000)
      let savePassword = shouldSavePassword ? controls.password.value : null
      authCtx.login(token, date.toISOString(), savePassword)
      const club = await authService.getClubId()
      localStorage.setItem('club', club.data.clubId)
      authCtx.setLoading(false)
      authCtx.setErrorMessage('')
      navigate('/')
    } catch (err) {
      setError(err)
      authCtx.setLoading(false)
    }
  }

  let setError = (err) => {
    if (err.status === 400) {
      authCtx.setErrorMessage('Contraseña Incorrecta')
    } else {
      authCtx.setErrorMessage(err.data.error_description)
    }
  }

  const formElementsArray = []
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key],
    })
  }
  let form = formElementsArray.map((formElement) => (
    <Grid item xs={12} key={formElement.id}>
      <ThemedOutlinedInput
        variant="outlined"
        sx={{
          '.MuiInputLabel-root': {
            backgroundColor: 'transparent !important',
            color: '#666666',
          },
          '.MuiInputLabel-shrink': {
            color: 'transparent !important',
          },
        }}
        type={formElement.config.label === 'Password' ? (showPassword ? 'text' : 'password') : undefined}
        placeholder={formElement.config.label}
        error={formElement.config.error}
        endAdornment={
          formElement.config.endAdornment ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                edge="end">
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
        onChange={(event) => inputChangedHandler(event, formElement.id)}
        onKeyPress={handleKeyPress}
      />
    </Grid>
  ))

  return (
    <div className={classes.Container}>
      <Grid container spacing={3}>
        {form}
        <Grid item xs={12}>
          <ThemedButton onClick={onClickHandler} ref={loginButtonRef}>Log In</ThemedButton>
        </Grid>
        {authCtx.errorMessage !== '' ? (
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: '#ffcdd2b2',
                borderRadius: '5px',
                padding: '4px 0px',
              }}>
              <Typography align="center" color="#ff3b4e">
                {authCtx.errorMessage}
              </Typography>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}

export default AuthForm
