import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import classes from './CustomAccordion.module.css'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import VideoDetails from '../../../components/OnDemand/AccordionDetails/VideoDetails'
import SectionDetails from '../../../components/OnDemand/AccordionDetails/SectionDetails'
import ProgramDetails from '../../../components/OnDemand/AccordionDetails/ProgramDetails'
import HomeDetails from '../../../components/App/Home/HomeViewer/HomeDetails'
import PushNotificationDetails from '../../../components/PushNotification/AccordeonDetails/PushNotificationDetails'
import PushSalonsDetails from '../../../components/SalonsComponent/AccordeonDetails/PushSalonsDetails'
import ClassReservationDetail from "../../../components/ClassReservation/ClassReservationGrid/ClassReservationDetail"

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 'large' }} />} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.darkerGray.main}`,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: 'white',
    backgroundColor: theme.palette.darkerGray.main,
    borderRadius: '50%',
    padding: '1%',
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    color: theme.palette.darkerGray.main,
    borderRaius: '50%',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

export default function CustomAccordion({ infoObj, id, page, expanded, setExpanded, subject, borderRadius, children }) {
  const showDetails = () => {
    switch (page) {
      case 'videos': {
        return <VideoDetails infoObj={infoObj} />
      }
      case 'sections': {
        return <SectionDetails infoObj={infoObj} />
      }
      case 'programs': {
        return <ProgramDetails infoObj={infoObj} />
      }
      case 'homeSections': {
        return <HomeDetails infoObj={infoObj} />
      }
      case 'notificationsPush': {
        return <PushNotificationDetails infoObj={infoObj} />
      }
      case 'salons': {
        return <PushSalonsDetails infoObj={infoObj} />
      }
      case 'classReservation': {
        return <ClassReservationDetail infoObj={infoObj} />
      }
      default:
        return <></>
    }
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    setExpanded(false)
  }, [page, subject, setExpanded])

  return (
    <MuiAccordion
      expanded={expanded === id}
      onChange={handleChange(id)}
      elevation={0}
      sx={{ marginBottom: '3px', width: '100%' }}>
      <AccordionSummary
        aria-controls="panel1d-content"
        className={classes.Summary}
        sx={{ borderRadius: borderRadius || 'none' }}>
        {children}
      </AccordionSummary>
      {expanded && showDetails()}
    </MuiAccordion>
  )
}
