import { AccordionDetails, Grid, Typography } from "@mui/material";
import React from "react";
import notificationService from "../../../http/services/NotificationService";
import { ThemedButton } from "../../../shared/Components/CustomButton/CustomButton";
import pushNotificationSubject from "../../../subjects/PushNotificationsSubject";

/**
 *
 * @param {object} infoObj data of one notification from the array of notifications.
 * @returns Accordion detail item
 */
export default function PushNotificationDetails({ infoObj }) {
  const downloadFailedCasesCSV = async (id) => {
    return await notificationService.getFailedCasesFile(id);
  };

  const downloadSuccessfulCasesCSV = async (id) => {
    return await notificationService.getSuccessfulCasesFile(id);
  };

  const resendNotifications = async (infoObj) => {
    pushNotificationSubject.notificationPush.find(n => n.id === infoObj.id).status = "En Proceso";
    pushNotificationSubject.notifyfilteredNotificationPushChanged();
    await notificationService.resendNotifications(infoObj.id);
    pushNotificationSubject.updateNotifications();
  };

  const cancelNotifications = async (infoObj) => {
    pushNotificationSubject.notificationPush.find(n => n.id === infoObj.id).status = "Cancelada";
    pushNotificationSubject.notifyfilteredNotificationPushChanged();
    await notificationService.cancelNotifications(infoObj.id);
    pushNotificationSubject.updateNotifications();
  }

  return (
    <AccordionDetails sx={{ padding: "0 1%", marginBottom: "3px" }}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={4}
        sx={{
          backgroundColor: "#666666",
          padding: "20px",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Grid container>
          <Grid container item xs={4} direction="column">
            <Typography sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}>
              Envío:
              <span style={{ fontWeight: "400", paddingLeft: "6px" }}>
                {infoObj.sendingDate.getDate()} de{" "}
                {infoObj.sendingDate.toLocaleString("es-ES", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </Typography>

            <Typography sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}>
              Hora:
              <span style={{ fontWeight: "400", paddingLeft: "6px" }}>
                {infoObj && (infoObj.sendingDate.getHours() - 3 + 24) % 24} :{" "}
                {infoObj &&
                  ("0" + infoObj.sendingDate.getMinutes().toString()).slice(
                    -2
                  )}{" "}
                hs
              </span>
            </Typography>

            {infoObj.status !== "Programada" &&
              infoObj.status !== "Cancelada" && (
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Total de notificationes enviadas:{" "}
                  {infoObj.notificationStatus?.totalNotificationsSent}
                </Typography>
              )}

            {infoObj?.status === "Finalizada" &&
              infoObj?.notificationStatus.successNotificationsCount > 0 && (
                <Grid item sx={{ width: "75%", marginTop: "51px" }}>
                  <ThemedButton
                    background="#8b0000"
                    handler={() => resendNotifications(infoObj)}
                  >
                    Reenviar notificaciones
                  </ThemedButton>
                </Grid>
              )}

            {infoObj?.status === "Programada" && (
              <Grid item sx={{ width: "75%", marginTop: "51px" }}>
                <ThemedButton
                  background="#8b0000"
                  handler={() => cancelNotifications(infoObj)}>
                  Cancelar envio
                </ThemedButton>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            xs={8}
            gap={2}
            direction={"column"}
            sx={{ height: "100%" }}
          >
            <Grid item xs={3}>
              <Typography sx={{ color: "white", fontSize: "16px" }}>
                Estado: {infoObj.status}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {infoObj.notificationStatus &&
                infoObj.status !== "Programada" &&
                infoObj.status !== "Cancelada" && (
                  <Typography sx={{ color: "white", fontSize: "16px" }}>
                    Notificaciones enviadas satisfactoriamente:{" "}
                    {infoObj.notificationStatus.successNotificationsCount}
                  </Typography>
                )}
            </Grid>
            {infoObj.notificationStatus !== undefined &&
              infoObj.status !== "Programada" &&
              infoObj.status !== "Cancelada" && (
                <Grid container direction="row" gap={2} item xs={3}>
                  <Typography sx={{ color: "white", fontSize: "16px" }}>
                    Notificaciones fallidas:{" "}
                    {infoObj.notificationStatus.failedNotificationsCount}
                  </Typography>
                </Grid>
              )}
            {infoObj.notificationStatus !== undefined &&
              infoObj.status !== "Cancelada" &&
              infoObj.notificationStatus.successNotificationsCount > 0 && (
                <Grid item sx={{ width: "75%" }}>
                  <ThemedButton
                    background="#006400"
                    handler={() => downloadSuccessfulCasesCSV(infoObj.id)}
                  >
                    Descargar CSV con casos enviados satisfactoriamente
                  </ThemedButton>
                </Grid>
              )}
            {infoObj.notificationStatus !== undefined &&
              infoObj.status !== "Cancelada" &&
              infoObj.notificationStatus.failedNotificationsCount > 0 && (
                <Grid item sx={{ width: "75%" }}>
                  <ThemedButton
                    background="#8b0000"
                    handler={() => downloadFailedCasesCSV(infoObj.id)}
                  >
                    Descargar CSV con casos fallidos
                  </ThemedButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </AccordionDetails >
  );
}
