export class SedeTemplate {
    idClub;
    nombre = ''
    telefono = ''
    email = ''
    direccion = ''
    latitud = ''
    longitud = ''
    schedule = ''
    textoDescargaGrilla = ''
    imagen
    cardImagen
    zona
    estatus
    active;
  
    constructor(jsonResponse) {
      if (jsonResponse) {

        
        this.idClub = jsonResponse.idClub
        this.nombre = jsonResponse.nombre
        this.telefono = jsonResponse.telefono
        this.email = jsonResponse.email
        this.direccion = jsonResponse.direccion
        this.latitud = jsonResponse.latitud
        this.longitud = jsonResponse.longitud
        this.schedule = jsonResponse.schedule
        this.textoDescargaGrilla = jsonResponse.textoDescargaGrilla
        this.imagen = jsonResponse.imagen
        this.cardImagen = jsonResponse.cardImagen
        this.idClubExterno = jsonResponse.idClubExterno
        this.peso = jsonResponse.peso
        this.externalName = jsonResponse.externalName
        this.status = jsonResponse.estatus
        this.zona = jsonResponse.zona

        if (jsonResponse.active)
          this.active = jsonResponse.active
      }
    }
  }