export class SavePushNotificationModel {
  name
  description
  type
  category
  status
  sendingDate
  hasAttachment

  constructor(newNotification) {
    let newCategory
    if (typeof newNotification.category === 'number') {
      newCategory = newNotification.category
    } else newCategory = newNotification.category.id

    this.name = newNotification.name
    this.description = newNotification.description
    this.type = newNotification.type
    this.category = newCategory
    this.status = newNotification.status
    this.sendingDate = newNotification.sendingDate.toISOString()
    this.hasAttachment = 1
  }
}
