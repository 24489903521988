import classSubject from '../../subjects/ClassSubject'
import { useState, useEffect } from 'react'
import { Divider, Grid, Typography, MenuItem } from '@mui/material'
import CustomTextField from '../../shared/Components/CustomTextField/CustomTextField'
import theme from '../../css/Theme'
import CustomSelect from '../../shared/Components/CustomSelect/CustomSelect'
import Spinner from '../UI/Spinner/Spinner'
import { ClassReservationGrid } from './ClassReservationGrid/classReservationGrid'
import FileDownload from '@mui/icons-material/FileDownload'
import { PDFDownloadLink } from "@react-pdf/renderer";
import BookingPDF from "./BookinPDF"

/**
 * Renders a component according to the selected page variable from the push notification subject.
 * @returns
 */
const ClassReservationViewer = () => {
    const [page, setPage] = useState(classSubject.selectedPage)
    const [isLoading, setIsLoading] = useState(false)
    const [sedes, setSedes] = useState(classSubject.sedes)
    const [activities, setActivities] = useState(classSubject.activities)
    const [classes, setClasses] = useState(classSubject.classes)

    const onPageChange = () => {
        setPage(classSubject.selectedPage)
    }

    const onSedesUpdate = () => {
        setSedes(classSubject.sedes)
    }

    const onActivitiesUpdate = () => {
        setActivities(classSubject.activities)
    }

    const onClassesUpdate = () => {
        setClasses(classSubject.classes)
    }

    useEffect(() => {
        classSubject.addObservers(onPageChange)
        classSubject.addObservers(onSedesUpdate)
        classSubject.addObservers(onActivitiesUpdate)
        classSubject.addObservers(onClassesUpdate)
        return () => {
            classSubject.removeObservers(onPageChange)
            classSubject.removeObservers(onSedesUpdate)
            classSubject.removeObservers(onActivitiesUpdate)
            classSubject.removeObservers(onClassesUpdate)
        }
    }, [])
    /**
     *  Switches the component accordingt to the page variable
     * @returns a component
     */
    const renderPage = () => {
        switch (page) {
            case 'viewer':
                return <ClassReservationGrid />
            default:
                return
        }
    }

    const handleUpdateClub = async (value) => {
        setIsLoading(true)
        await classSubject.getActivities(value)
        setIsLoading(false)
    }

    const handleUpdateActivity = async (value) => {
        setIsLoading(true)
        await classSubject.getClasses(value)
        setIsLoading(false)
    }

    const handleUpdateClass = async (value) => {
        setIsLoading(true)
        await classSubject.getBookings(value)
        setIsLoading(false)
    }

    return (
        <>
            <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
                <Grid container item xs={3} direction="column">
                    <Typography>Clases</Typography>
                    <Typography variant="h4">Reservar</Typography>
                </Grid>

                <Grid container item direction={'column'} xs={2}>
                    <CustomSelect
                        defaultValue={classSubject.selectedClub || 'Sede'}
                        onChange={(e) => {
                            handleUpdateClub(e.target.value)
                        }}>
                        <MenuItem value={'Sede'} disabled>
                            Sede
                        </MenuItem>
                        {sedes.map((elem, index) => {
                            return (
                                <MenuItem key={index} value={elem.idClub}>
                                    {elem.nombre}
                                </MenuItem>
                            )
                        })}
                    </CustomSelect>
                </Grid>
                <Grid item xs={2} sx={{ ml: 1 }}>
                    {isLoading && <Spinner />}
                    <CustomSelect
                        defaultValue={classSubject.selectedActivity || 'Disciplina'}
                        onChange={(e) => {
                            handleUpdateActivity(e.target.value)
                        }}>
                        <MenuItem value={'Disciplina'} disabled>
                            Disciplina
                        </MenuItem>
                        {activities.map((elem, index) => {
                            return (
                                <MenuItem key={index} value={elem.id}>
                                    {elem.nombre}
                                </MenuItem>
                            )
                        })}
                    </CustomSelect>
                </Grid>
                <Grid item xs={2} sx={{ ml: 1 }}>
                    <CustomSelect
                        defaultValue={classSubject.selectedClass || 'Clase'}
                        onChange={(e) => {
                            handleUpdateClass(e.target.value)
                        }}>
                        <MenuItem disabled value="Clase">
                            Clase
                        </MenuItem>
                        {classes.map((elem, index) => {
                            return (
                                <MenuItem key={index} value={elem.idClase}>
                                    {elem.title}
                                </MenuItem>
                            )
                        })}
                    </CustomSelect>
                </Grid>
                <Grid container item xs={2} direction="column" sx={{ ml: 1 }}>
                    {page === 'viewer' && (
                        <Grid item xs={6}>
                            <CustomTextField searchbutton={1} onChange={(e) => classSubject.searchHandler(e)} />
                        </Grid>
                    )}

                    {classSubject.filteredBookings.length > 0 && (
                        <Grid item container xs={2}>
                            <PDFDownloadLink
                                document={<BookingPDF
                                    discipline={classSubject.selectedActivityText}
                                    club={classSubject.selectedClubtext}
                                    selectedClass={classSubject.selectedClassText}
                                    bookings={classSubject.filteredBookings}
                                />
                                }
                                fileName='Reservas.pdf'>
                                Descargar PDF <FileDownload />
                            </PDFDownloadLink>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <br />
            <Divider orientation="horizontal" color={theme.palette.primary.main} />
            <br />
            {renderPage()}
        </>
    )
}
export default ClassReservationViewer
