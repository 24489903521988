import React, { useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { ThemedCheckBox } from '../../../shared/Components/CustomCheckbox/CustomCheckbox'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CalendarPicker from '@mui/lab/CalendarPicker'
import styled from '@emotion/styled'
import Theme from '../../../css/Theme'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import { es } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import theme from '../../../css/Theme'

/**
 * Local CalendarPicker Customization
 */
const ThemedCalendarPicker = styled(CalendarPicker)(() => ({
  '&.MuiCalendarPicker-root': {
    background: 'white',
    borderRadius: '5px',
    height: '90%',
    width: '300px',
    margin: '0%',
  },
  '.MuiPickersDay-today': {
    borderColor: 'transparent',
  },
  '.Mui-selected': {
    background: theme.palette.gradient.main,
    color: 'white',
    borderRadius: '0px',
    fontWeight: 'bold',
  },
  '.Mui-selected &:hover': {},
  div: {
    overflow: 'hidden',
  },
}))
/**
 * Local Typography Customization
 */
const ThemedTypography = styled(Typography)(() => ({
  color: Theme.palette.darkerGray.main,
}))

/**
 * The form to create  a new video
 * @returns a component
 */
export default function Video() {
  const [elemCheckboxClicked, setElemCheckboxClicked] = useState(
    (onDemandSubject.newVideo && onDemandSubject.newVideo.elements) || []
  )
  const [objCheckboxClicked, setObjCheckboxClicked] = useState(
    (onDemandSubject.newVideo && onDemandSubject.newVideo.goals) || []
  )
  const [startingDate, setStartingDate] = useState(
    (onDemandSubject.newVideo && new Date(onDemandSubject.newVideo.startAt)) || new Date()
  )
  const [endingDate, setEndingDate] = useState(
    (onDemandSubject.newVideo && new Date(onDemandSubject.newVideo.endAt)) || new Date()
  )

  /**
   * Updates the local startingDate variable and completes the startingDate of the new instance (newVideo) to the selected date.
   * @param {Date} e selected starting date.
   */
  const handleStartDate = (e) => {
    setStartingDate(e)
    onDemandSubject.newVideo.startAt = e.toISOString()
  }
  /**
   * Updates the local endingDate variable and completes the endingDate of the video instance (newVideo) to the selected date.
   * @param {Date} e selected ending date.
   */
  const handleEndDate = (e) => {
    setEndingDate(e)
    onDemandSubject.newVideo.endAt = e.toISOString()
  }
  /**
   * Checks if the elem is already in the array. Adds or removes the elem according to the situation and updates the video instance (newVideo).
   * @param {String} elem the element to add or remove from an array.
   * @param {Array} checkboxArr the array to modify.
   * @param {Function} setcheckboxArr the function to modify the array
   * @param {String} type  each element has type. Section they belong to.
   */
  const handleGoalsCheckbox = (elem, checkboxArr, setcheckboxArr) => {
    if (checkboxArr.includes(elem)) {
      let arr = [...checkboxArr]
      let index = arr.indexOf(elem)
      arr.splice(index, 1)
      setcheckboxArr(arr)
      onDemandSubject.newVideo.goals = arr
    } else {
      onDemandSubject.newVideo.goals = [...onDemandSubject.newVideo.goals, elem]
      setcheckboxArr([...checkboxArr, elem])
    }
  }

  const handleElemCheckbox = (elem, checkboxArr, setcheckboxArr) => {
    let arr = [...checkboxArr]
    let found = arr.find((element) => element.id === elem.id)
    if (found) {
      let index = arr.indexOf(found)
      arr.splice(index, 1)
      setcheckboxArr(arr)
      onDemandSubject.newVideo.elements = arr
    } else {
      onDemandSubject.newVideo.elements = [...onDemandSubject.newVideo.elements, elem]
      setcheckboxArr([...checkboxArr, elem])
    }
  }

  const elementType = onDemandSubject.elementList
  const objetivsType = onDemandSubject.goalList

  return (
    <>
      <Grid item container paddingLeft="3%" alignContent="space-around" xs={5}>
        <ThemedTypography sx={{ fontWeight: 'bold', padding: '3%' }}>Fecha de publicación</ThemedTypography>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es} utils={DateFnsUtils}>
            <ThemedCalendarPicker
              date={startingDate}
              today={false}
              reduceAnimations
              disableHighlightToday
              showDaysOutsideCurrentMonth
              onChange={(e) => handleStartDate(e)}
            />
          </LocalizationProvider>
        </Box>

        <ThemedTypography sx={{ fontWeight: 'bold', padding: '3%' }}> Fecha de finalización</ThemedTypography>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <ThemedCalendarPicker
              today={false}
              date={endingDate}
              reduceAnimations
              disableHighlightToday
              onChange={(e) => handleEndDate(e)}
            />
          </LocalizationProvider>
        </Box>
      </Grid>

      <Grid item xs={7}>
        <ThemedTypography sx={{ padding: '3%', fontWeight: 'bold' }}>Elementos</ThemedTypography>
        <Box sx={{ border: '1px solid gray', borderRadius: '15px', height: '300px', overflow: 'auto' }}>
          <Grid
            item
            container
            direction="row"
            sx={{
              padding: '3%',
            }}>
            {elementType.map((elem, i) => {
              let fill = elemCheckboxClicked.find((element) => element.id === elem.id)
              return (
                <Grid
                  key={i}
                  sx={{
                    padding: '2%',
                  }}
                  item
                  container
                  flexWrap="nowrap"
                  alignItems="center"
                  xs={6}
                  onClick={() => handleElemCheckbox(elem, elemCheckboxClicked, setElemCheckboxClicked)}>
                  <ThemedCheckBox checked={fill} isActive={true}>
                    {fill && <FiberManualRecordIcon sx={{ fontSize: 'xx-small', color: 'white' }} />}
                  </ThemedCheckBox>
                  {elem.name}
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <ThemedTypography sx={{ padding: '3%', fontWeight: 'bold' }}>Objetivos</ThemedTypography>

        <Box sx={{ border: '1px solid gray', borderRadius: '15px', height: '300px', overflow: 'auto' }}>
          <Grid
            item
            container
            direction="row"
            sx={{
              padding: '3%',
            }}>
            {objetivsType.map((elem, i) => {
              let fill = objCheckboxClicked.includes(elem)
              return (
                <Grid
                  key={i}
                  xs={6}
                  item
                  container
                  direction="row"
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{
                    padding: '2%',
                  }}
                  onClick={() => handleGoalsCheckbox(elem, objCheckboxClicked, setObjCheckboxClicked)}>
                  <ThemedCheckBox checked={fill} isActive={true}>
                    {fill && <FiberManualRecordIcon sx={{ fontSize: 'xx-small', color: 'white' }} />}
                  </ThemedCheckBox>
                  <Typography textTransform="capitalize">{elem}</Typography>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
    </>
  )
}
