import styled from '@emotion/styled'
import { Grid, Typography, Button, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import theme from '../../css/Theme'
import onDemandSubject from '../../subjects/OnDemandSubject'
import CustomTextField from '../../shared/Components/CustomTextField/CustomTextField'
import PaginationComponent from '../PaginationComponent/PaginationComponent'
import CreateNew from './OnDemandCreate/CreateNew'
import classes from './OnDemandCreate/CreateNew.module.css'
import Spinner from '../UI/Spinner/Spinner'
import VideoList from './OnDemandPageViewerContent/VideoList'
import ProgramList from './OnDemandPageViewerContent/ProgramList'
import SectionList from './OnDemandPageViewerContent/SectionList'

/**
 * Local button customization
 */
const PageControllerButton = styled(Button)(() => ({
  backgroundColor: 'inherit',
  color: '#666666',
  border: '1px solid #cccccc',
  padding: 1,
  borderBottomLeftRadius: '15px',
  borderBottomRightRadius: '15px',
  width: '100%',
  ':hover': {
    backgroundColor: '#ff3b4e',
    color: 'white',
  },
  ':focus': {
    backgroundColor: '#ff3b4e',
    borderColor: '#ff3b4e',
    color: 'white',
  },
}))

/**
 *
 * @param {String} page page identifier
 * @returns a component depending on the selceted page
 */
const SearchRender = ({ page, pageSize }) => {
  const [pagination, setPagination] = useState({ ...onDemandSubject.paging })
  const [lastPage, setLastPage] = useState(false)

  const onPaginationChange = () => {
    setPagination({ ...onDemandSubject.paging })
  }

  useEffect(() => {
    onDemandSubject.addObserver(onPaginationChange)
    return () => {
      onDemandSubject.removeObserver(onPaginationChange)
    }
  }, [])

  useEffect(() => {
    if (pagination) {
      if (pagination.total_elements / (pagination.page + 1) < pageSize) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [pagination, pageSize])

  const onFollowingClickHandler = () => {
    if (pagination.page_size < pagination.total_elements) {
      switch (page) {
        case 'videos': {
          onDemandSubject.updateVideoList(pagination.page + 1, pageSize)
          break
        }
        case 'programs': {
          onDemandSubject.updateProgramList(pagination.page + 1, pageSize)
          break
        }
        case 'sections': {
          onDemandSubject.updateSectionList(pagination.page + 1, pageSize)
          break
        }
        default:
          break
      }
    }
  }

  const onPreviousClickHandler = () => {
    if (pagination.page > 0) {
      switch (page) {
        case 'videos': {
          onDemandSubject.updateVideoList(pagination.page - 1, pageSize)
          break
        }
        case 'programs': {
          onDemandSubject.updateProgramList(pagination.page - 1, pageSize)
          break
        }
        case 'sections': {
          onDemandSubject.updateSectionList(pagination.page - 1, pageSize)
          break
        }
        default:
          break
      }
    }
  }

  let paginator = () => {
    return (
      <PaginationComponent
        currentPage={onDemandSubject.paging.page}
        onFollowingClickHandler={onFollowingClickHandler}
        onPreviousClickHandler={onPreviousClickHandler}
        last={lastPage}>
        {onDemandSubject.paging.page + 1}
      </PaginationComponent>
    )
  }

  /**
   * Renders an array of accordions for each page.
   * @returns a component, according to the selected page.
   */
  const renderViewer = () => {
    switch (page) {
      case 'videos': {
        return (
          <>
            <VideoList pagination={pagination} />
            {onDemandSubject.videos.length > 0 && paginator()}
          </>
        )
      }

      case 'programs': {
        return (
          <>
            <ProgramList pagination={pagination} />
            {onDemandSubject.programs.length > 0 && paginator()}
          </>
        )
      }
      case 'sections': {
        return (
          <>
            <SectionList />
          </>
        )
      }
      case 'createVideo': {
        return <CreateNew page={page} />
      }
      case 'createProgram': {
        return <CreateNew page={page} />
      }
      case 'createSection': {
        return <CreateNew page={page} />
      }
      default:
        return <></>
    }
  }
  return (
    <Grid
      container
      item
      wrap="nowrap"
      direction="column"
      justifyContent={'space-between'}
      xs={onDemandSubject.isCreating ? 11 : 9}
      sx={
        onDemandSubject.isCreating
          ? {
              overflowY: 'auto',
              width: '100%',
              padding: '20px',
              paddingRight: '2%',
              background: theme.palette.lightGray.main,
              borderRadius: '15px',
              height: '100%',
            }
          : {
              overflowY: 'auto',
              background: theme.palette.primary.main,
              height: '100%',
            }
      }>
      {renderViewer()}
    </Grid>
  )
}

/**
 *
 * @returns the header and other components according to the selected page
 */
const OnDemandPageViewer = () => {
  const [page, setPage] = useState(onDemandSubject.selectedPage)
  const [isCreating, setIsCreating] = useState(onDemandSubject.isCreating)
  const [isLoading, setIsLoading] = useState(false)

  const pageNumber = 0
  const pageSize = 10
  /**
   * Updates the page and the isCreating variable, according to the last update in the onDemandSubject.
   */
  const onPageChange = () => {
    setPage(onDemandSubject.selectedPage)
    setIsCreating(onDemandSubject.isCreating)
    setIsLoading(true)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onPageChange)
    return () => {
      onDemandSubject.removeObserver(onPageChange)
    }
  }, [])

  useEffect(() => {
    switch (page) {
      case 'videos': {
        onDemandSubject.updateVideoList(pageNumber, pageSize)
        setIsLoading(false)
        break
      }
      case 'programs': {
        onDemandSubject.updateProgramList(pageNumber, pageSize)
        setIsLoading(false)
        break
      }
      case 'sections': {
        onDemandSubject.updateSectionList(pageNumber, pageSize)
        setIsLoading(false)
        break
      }
      default:
        break
    }
  }, [page])

  /**
   * Sets the rendering page to the selected one.
   * @param {String} pageName
   */
  const navigateOnViewer = (pageName) => {
    onDemandSubject.updateSelectedPage(pageName)
  }

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>Play</Typography>
          <Typography variant="h4">On Demand</Typography>
        </Grid>
        <Grid container item xs={4} direction="column">
          <Grid item xs={6}>
            {!isCreating && (
              <CustomTextField
                searchbutton={1}
                onChange={(e) => {
                  onDemandSubject.searchHandler(e, page)
                }}
                className={classes.searchButton}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!isCreating ? (
        <Grid item container xs={1} sx={{ borderTop: '2px solid #ff3b4e' }} direction="row" justifyContent={'center'}>
          <Grid item xs={3}>
            <PageControllerButton onClick={() => navigateOnViewer('videos')}>Videos</PageControllerButton>
          </Grid>
          <Grid item xs={3}>
            <PageControllerButton onClick={() => navigateOnViewer('programs')}>Programas</PageControllerButton>
          </Grid>
          <Grid item xs={3}>
            <PageControllerButton onClick={() => navigateOnViewer('sections')}>Secciones</PageControllerButton>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={0.25}>
          <Divider orientation="horizontal" sx={{ background: 'black' }} />
        </Grid>
      )}
      {isLoading ? (
        <>
          <SearchRender page={page} pageSize={pageSize}></SearchRender>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}
export default OnDemandPageViewer
