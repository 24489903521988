import { createTheme } from '@mui/material/styles'

export const darKOrange = '#ff3b4e'
export const lightOrange = '#ffa33e'
export const lighterGray = '#f2f2f2'
export const lightGray = '#cccccc'
export const megaGradient = 'linear-gradient(135deg, ' + lightOrange + ' 20%, ' + darKOrange + ' 90%)'

let theme = createTheme({
  typography: {
    fontFamily: '"Montserrat"',
  },
  palette: {
    lightOrange: {
      main: lightOrange,
    },
    darkOrange: {
      main: darKOrange,
    },
    primary: {
      main: lighterGray,
    },
    lightGray: {
      main: lightGray,
    },
    darkGray: {
      main: '#666666',
    },
    darkerGray: {
      main: '#4d4d4d',
    },
    darkerTransparentGray: {
      main: '#4d4d4dcc',
    },
    gradient: {
      main: 'linear-gradient(135deg, ' + lightOrange + ' 20%, ' + darKOrange + ' 90%)',
    },
  },
})

export default theme
