import {
  FormControl,
  Grid,
  TextField,
  Paper,
  Typography,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import { ThemedButton } from "../../../shared/Components/CustomButton/CustomButton";
import CustomSelect from "../../../shared/Components/CustomSelect/CustomSelect";
import CustomTextField from "../../../shared/Components/CustomTextField/CustomTextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState, useCallback, useEffect } from "react";
import CustomTimePicker from "../../../shared/Components/CustomTimePicker/CustomTimePicker";
import theme from "../../../css/Theme";
import pushNotificationSubject from "../../../subjects/PushNotificationsSubject";
import { es } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import styled from "@emotion/styled";
import { DesktopDatePicker } from "@mui/lab";
import { ThemedCheckBox } from "../../../shared/Components/CustomCheckbox/CustomCheckbox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import classes from "../../Calendar/CalendarComponent/CalendarComponent.module.css";

/**
 * Local Paper customization
 */
const ThemedDatePaper = styled(Paper)(() => ({
  background: "none",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  height: "2vh",
  boxShadow: "none",
  padding: "0%",
  paddingBottom: "10%",
}));

const CustomLableInput = ({ children, time, value, lable }) => {
  const handleTimeChange = useCallback(
    (e) => {
      pushNotificationSubject.saveTime(time, e);
    },
    [time]
  );

  return (
    <>
      <Grid item xs={6}>
        <Typography className={classes.Lables}>{lable}</Typography>
      </Grid>
      <Grid item container xs={4.25}>
        {value && (
          <FormControl sx={{ width: "100%" }}>
            <CustomTimePicker value={value[time]} handler={handleTimeChange} />
          </FormControl>
        )}
        {children}
      </Grid>
    </>
  );
};

/**
 * Renders part of the form that creates and saves a a new notification.
 * @returns
 */
const CreateControls = () => {
  const today = new Date();
  const [loading, setloading] = useState(false);
  const [sendDate, setSendDate] = useState(
    pushNotificationSubject.dateSelected
  );
  const [timePicked, setTimePicked] = useState(
    pushNotificationSubject.selectedTime
  );
  const [isScheduled, setIsScheduled] = useState(
    pushNotificationSubject.isScheduled
  );

  const onTimeUpdate = () => {
    setTimePicked(pushNotificationSubject.selectedTime);
  };

  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  /**
   * Executes the saveNotification function, witch pushes the new notification to the array of pushNotifications and notifys the comoponen subscribed to this subject.
   */
  const handleSave = async () => {
    setloading(true);
    await pushNotificationSubject.saveNotification();
    setloading(false);
  };

  const handleSaveScheduled = async () => {
    setloading(true);
    await pushNotificationSubject.saveNotificationScheduled(
      sendDate,
      timePicked
    );
    setloading(false);
  };

  /**
   * UseEffect that adds and removes the observers from the subject.
   */
  useEffect(() => {
    pushNotificationSubject.addObservers(onTimeUpdate);
    return () => {
      pushNotificationSubject.removeObservers(onTimeUpdate);
      setSendDate(new Date());
    };
  }, []);
  return (
    <>
      <Grid
        container
        item
        xs={11}
        direction="column"
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
        wrap={"nowrap"}
        sx={{ height: "100%" }}
      >
        <Grid
          container
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={1} sx={{ width: "100%" }}>
            <ThemedButton
              background="transparent"
              border="2px solid red"
              color={"red"}
            >
              Nueva Notificación
            </ThemedButton>
          </Grid>
          <Grid item xs={1} justifyContent={"center"}>
            <KeyboardArrowDownIcon
              sx={{ color: "white", height: "100%", fontSize: "60px" }}
            />
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomTextField
            placeholder={"Nombre (máx. 50 caract.)"}
            backgroundcolor={"white"}
            background={theme.palette.lightGray.main}
            defaultValue={
              pushNotificationSubject.newNotificationPush.name
                ? pushNotificationSubject.newNotificationPush.name
                : ""
            }
            onChange={(e) => {
              pushNotificationSubject.newNotificationPush.name = e.target.value;
            }}
          />
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomSelect
            lable="Categoría"
            sx={{ background: "white" }}
            defaultValue={
              pushNotificationSubject.newNotificationPush.category.id || 4
            }
            onChange={(e) => {
              pushNotificationSubject.newNotificationPush.category =
                e.target.value;
            }}
          >
            <MenuItem
              value={0}
              sx={{ color: theme.palette.darkerGray.main }}
              disabled
            >
              Categoría
            </MenuItem>
            {pushNotificationSubject.categories.map((element) => {
              return (
                <MenuItem
                  key={element.id}
                  value={element.id}
                  sx={{ color: theme.palette.darkerGray.main }}
                >
                  {element.name}
                </MenuItem>
              );
            })}
          </CustomSelect>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomSelect
            lable="Tipo"
            sx={{ background: "white", outline: "2px solid red" }}
            defaultValue={"BATCH"}
          >
            <MenuItem
              value={"Tipo"}
              sx={{ color: theme.palette.darkerGray.main }}
              disabled
            >
              Tipo
            </MenuItem>
            <MenuItem
              value={"BATCH"}
              sx={{ color: theme.palette.darkerGray.main }}
            >
              Batch
            </MenuItem>
            <MenuItem
              value={"CUSTOM"}
              sx={{ color: theme.palette.darkerGray.main }}
              disabled
            >
              Custom
            </MenuItem>
          </CustomSelect>
        </Grid>
      </Grid>

      <Grid item margin={"10px"}>
        <FormControlLabel
          control={
            <ThemedCheckBox checked={isScheduled ? true : false}>
              {isScheduled ? (
                <FiberManualRecordIcon
                  sx={{ fontSize: "x-small" }}
                  className={classes.DotIcon}
                />
              ) : null}
            </ThemedCheckBox>
          }
          onClick={() => {
            setIsScheduled(!isScheduled);
          }}
          label={"Programar"}
          className={classes.Checkbox}
        />
      </Grid>

      {isScheduled && (
        <Grid
          item
          container
          direction="row"
          justifyContent={"space-evenly"}
          alignItems="center"
          className={classes.CustomTextField}
          xs={4}
          sx={{ height: "100%" }}
        >
          <CustomLableInput lable={"Programar para"}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={es}
              utils={DateFnsUtils}
            >
              <ThemedDatePaper>
                <DesktopDatePicker
                  size="small"
                  disablePast={true}
                  inputFormat="dd/MM/yyyy"
                  value={sendDate}
                  maxDate={maxDate}
                  fullWidth={true}
                  onChange={(e) => {
                    setSendDate(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{ fontSize: "8px" }}
                    />
                  )}
                />
              </ThemedDatePaper>
            </LocalizationProvider>
          </CustomLableInput>
          '
          <CustomLableInput
            value={timePicked}
            time={"start"}
            lable={"Hora"}
          ></CustomLableInput>
          '
        </Grid>
      )}

      <Grid item xs={1}>
        <ThemedButton
          handler={
            !loading ? (isScheduled ? handleSaveScheduled : handleSave) : null
          }
        >
          {!loading
            ? isScheduled
              ? "PROGRAMAR"
              : "ENVIAR"
            : isScheduled
            ? "PROGRAMANDO..."
            : "ENVIANDO..."}
        </ThemedButton>
      </Grid>
    </>
  );
};
export default CreateControls;
