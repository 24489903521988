import axios from './axiosCoreInstance'
class CoreAxiosClient {

  get = async (url) => {
    return await axios.get(url)
  }

  put = async (url) => {
    return await axios.put(url)
  }
}

const coreAxiosClient = new CoreAxiosClient()
export default coreAxiosClient
