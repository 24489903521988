import genericAxiosClient from '../axios/GenericAxiosClient'

class OnDemandService {
  baseUrl = '/admin/play'

  /**
   * Get program list
   * @return {Promise<Object[]>}
   */
  getProgramList(page, pageSize, fields, keyword) {
    const url = new URLSearchParams()
    page && url.append('page', page)
    pageSize && url.append('page_size', pageSize)
    fields && url.append('fields', 'id,name,preview_image')
    keyword && url.append('keyword', keyword)
    return genericAxiosClient.get(this.baseUrl + '/programs/list?' + url.toString()).then((response) => {
      return { ...response.data }
    })
  }

  /**
   * Get form data
   * @return {Promise<Object>}
   */
  getFormData() {
    return genericAxiosClient.get(this.baseUrl + '/videos/formData').then((response) => {
      return { ...response.data }
    })
  }

  /**
   * Get video list
   * @return {Promise<Object[]>}
   */
  getVideoList(page, pageSize, fields, keyword) {
    const url = new URLSearchParams()
    page && url.append('page', page)
    pageSize && url.append('page_size', pageSize)
    fields && url.append('fields', 'id,name,preview_image')
    keyword && url.append('keyword', keyword)
    return genericAxiosClient.get(this.baseUrl + '/videos/list?' + url.toString()).then((response) => {
      return { ...response.data }
    })
  }

  /**
   * Get section list
   * @return {Promise<Object[]>}
   */
  getSectionList(keyword, page, pageSize) {
    const url = new URLSearchParams()
    page && url.append('page', page)
    pageSize && url.append('page_size', pageSize)
    keyword && url.append('keyword', keyword)
    return genericAxiosClient.get(this.baseUrl + '/sections/list?' + url.toString()).then((response) => {
      return { ...response.data }
    })
  }

  /**
   * Post video
   * @param videoRequest
   * @return {Promise<Object>}
   */
  postVideo(videoRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/videos', JSON.stringify(videoRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Put video
   * @param id
   * @param videoRequest
   * @return {Promise<Object>}
   */
  putVideo(id, videoRequest) {
    return genericAxiosClient
      .put(this.baseUrl + '/videos/' + id, JSON.stringify(videoRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  deleteVideo(id) {
    return genericAxiosClient
      .delete(this.baseUrl + '/videos/' + id)
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Post section
   * @param sectionRequest
   * @return {Promise<Object>}
   */
  postSection(sectionRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/sections', JSON.stringify(sectionRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Put section
   * @param id
   * @param sectionRequest
   * @return {Promise<Object>}
   */
  putSection(id, sectionRequest) {
    return genericAxiosClient
      .put(this.baseUrl + '/sections/' + id, JSON.stringify(sectionRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  deleteSection(id) {
    return genericAxiosClient
      .delete(this.baseUrl + '/sections/' + id)
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Post program
   * @param programRequest
   * @return {Promise<Object>}
   */
  postProgram(programRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/programs', JSON.stringify(programRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Put program
   * @param id
   * @param programRequest
   * @return {Promise<Object>}
   */
  putProgram(id, programRequest) {
    return genericAxiosClient
      .put(this.baseUrl + '/programs/' + id, JSON.stringify(programRequest))
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  deleteProgram(id) {
    return genericAxiosClient
      .delete(this.baseUrl + '/programs/' + id)
      .then((response) => {
        return { ...response.data }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  reorderSection(newOrder) {
    return genericAxiosClient.put(this.baseUrl + '/sections', newOrder).catch((err) => {
      console.log(err)
      throw err
    })
  }
}

const onDemandService = new OnDemandService()
export default onDemandService
