import { Grid, IconButton } from '@mui/material/'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import classes from './PaginationComponent.module.css'

const PaginationComponent = (props) => {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={4} className={classes.PreviousButton}>
          <IconButton
            onClick={() => props.onPreviousClickHandler(props.currentPage)}
            disabled={props.currentPage === 0}
            color="darkerGray"
            aria-label="Previous Page">
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4} className={classes.GridItems}>
          {props.children}
        </Grid>
        <Grid item xs={4} className={classes.FollowingButton}>
          <IconButton
            onClick={() => props.onFollowingClickHandler(props.currentPage)}
            disabled={props.last}
            color="darkerGray"
            aria-label="Following Page">
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PaginationComponent
