import * as React from 'react'
import { styled } from '@mui/material/styles'
import classes from './SideMenu.module.css'
import Backdrop from '@mui/material/Backdrop/Backdrop'
import { lighterGray } from '../../../css/Theme'
import Theme from '../../../css/Theme'
import Grid from '@mui/material/Grid/Grid'
import { useContext } from 'react'
import AppContext from '../../../store/app-context'
import Typography from '@mui/material/Typography/Typography'
import { Fragment } from 'react'
//import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { NavLink } from 'react-router-dom'
import menuItems from './MenuItems'
import ThemedDrawer from '../ThemedDrawer/ThemedDrawer'
import ThemedMenuButtonSelected from './ThemedMenuButtons/ThemedMenuButtonSelected'
import ThemedMenuButtonUnSelected from './ThemedMenuButtons/ThemedMenuButtonUnSelected'
import authService from '../../../http/services/AuthService'
//import ThemedFixMenuButton from './ThemedMenuButtons/ThemedFixMenuButton'

const ThemedTypography = styled(Typography)(() => ({
  '&:hover': {
    color: Theme.palette.darkOrange.main,
  },
}))

const SideMenu = () => {
  const authCtx = useContext(AppContext)
  const [open, setOpen] = React.useState(false)
  const [menuItemList, setMenuItemList] = React.useState([])
  const [subMenu, setSubMenu] = React.useState([])
  const [selectedMenu, setSelectedMenu] = React.useState('Home')

  /*  const menuFixHandler = () => {
    authCtx.setMenuFixed(!authCtx.menuFixed)
    if (!authCtx.menuFixed) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  } */

  React.useEffect(() => {
    const asyncEffect = async () => {
      const club = await authService.getClubId()
      localStorage.setItem('club', club.data.clubId)
      setMenuItemList(menuItems.getItemsByScope(parseInt(club.data.clubId)))
      authCtx.club = club.data.getClubId
    }
    asyncEffect()
  }, [authCtx, setMenuItemList])

  const togleDrawer = () => {
    if (!authCtx.menuFixed) {
      setOpen(!open)
    }
  }

  const onMenuItemClick = (menuItem, action) => {
    if (!open) {
      togleDrawer()
    }

    if (menuItem.text === 'Logout' && action === 'click') {
      authCtx.logout()
    }

    if (menuItem.text !== 'Logout') {
      setSelectedMenu(menuItem.text)
      setSubMenu(menuItem.subMenu)
      if (menuItem.subMenu.length === 0) {
        if (!authCtx.menuFixed) {
          setOpen(false)
        }
      }
    }
  }

  const onSubMenuClick = () => {
    togleDrawer()
  }

  let backdrop = null
  if (open && !authCtx.menuFixed) {
    backdrop = (
      <Backdrop
        sx={{ color: '#4d4d4d', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={open}
        onClick={togleDrawer}
      />
    )
  }

  let menuIconItemsArray = menuItemList.map((formElement) => (
    <Tooltip title={formElement.text} placement="right-end" key={formElement.text}>
      <Grid item xs={12}>
        {selectedMenu === formElement.text ? (
          <ThemedMenuButtonSelected onClick={() => onMenuItemClick(formElement)}>
            {formElement.icon}
          </ThemedMenuButtonSelected>
        ) : (
          <ThemedMenuButtonUnSelected
            onClick={() => onMenuItemClick(formElement, 'click')}
            onMouseOver={() => onMenuItemClick(formElement, 'hover')}>
            {formElement.icon}
          </ThemedMenuButtonUnSelected>
        )}
      </Grid>
    </Tooltip>
  ))

  let menuIcons = (
    <Fragment>
      <Grid item xs={12}>
        <div className={classes.SearchContainer}>
          <div className={classes.SearchDiv} />
        </div>
      </Grid>
      {menuIconItemsArray}
      {/* <Grid item xs={12}>
        <div className={classes.LogoutContainer}>
          <Tooltip title={'Fijar Menu'} placement="right-end">
            <>
              <ThemedFixMenuButton onClick={menuFixHandler}>
                <FiberManualRecordIcon fontSize="10px" />
              </ThemedFixMenuButton>
            </>
          </Tooltip>
        </div>
      </Grid> */}
    </Fragment>
  )

  let subMenuComponent = null
  if (open && subMenu.length > 0) {
    let selectedMenuItem = menuItemList.find((m) => m.text === selectedMenu)
    if (selectedMenuItem.subMenu.length > 0) {
      let subMenuItems = selectedMenuItem.subMenu.map((formElement) => (
        <Grid item xs={12} key={formElement.text}>
          <NavLink to={formElement.link} className={classes.NavLinkClass}>
            <ThemedTypography color={lighterGray} onClick={() => onSubMenuClick()}>
              {formElement.text}
            </ThemedTypography>
          </NavLink>
        </Grid>
      ))
      subMenuComponent = (
        <div className={classes.SecondaryMenuContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold' }} color={lighterGray}>
                {selectedMenuItem.text}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.MenuDivider}></div>
            </Grid>
            {subMenuItems}
          </Grid>
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div className={classes.MenuIconsContainer}>
        <Grid container spacing={2}>
          {menuIcons}
        </Grid>
      </div>
      <ThemedDrawer open={open}>{subMenuComponent}</ThemedDrawer>
      {backdrop}
    </Fragment>
  )
}

export default SideMenu
