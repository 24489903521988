import axios from 'axios';

const defaultOptions = {
  baseURL: process.env.REACT_APP_CORE_BASEURL,
  headers: {
    'Access-Control-Allow-Origin': 'https://35.244.194.93',
  },
};

// Create instance
let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 403) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    window.location = '/'
  }  else {
    return Promise.reject(error);
  }
});

export default instance;