import { Button, Grid, Typography } from '@mui/material'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import theme from '../../../css/Theme'
import { useEffect, useState } from 'react'
import DragAndDropList from '../../../shared/Components/DragAndDrop/DragAndDropList'
import onDemandService from '../../../http/services/OnDemandService'

/**
 * Renders buttons that sort sections and that navigate to "Create sections". Selectes filters for rendered accordions of programs. Also renders a drag and drop to modify the order of sections.
 * @returns a component.
 */
const SectionControls = () => {
  const [totalSection, setTotalSection] = useState(onDemandSubject.sections.length)

  /**
   * Executes the function on onDemandSubject that updates the selected page.
   * @param {String} pageName
   * @param {Boolean} cleanSelectedItem if true, it creates a new instance of section template.
   */
  const navigateOnControls = (pageName, cleanSelectedItem) => {
    onDemandSubject.updateSelectedPage(pageName, cleanSelectedItem)
  }

  const onTotalSectionUpdate = () => {
    setTotalSection(onDemandSubject.sections.length)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onTotalSectionUpdate)
    return () => onDemandSubject.removeObserver(onTotalSectionUpdate)
  }, [])

  return (
    <Grid container item direction={'column'} xs={12}>
      <Grid container item xs={2} direction="column" justifyContent={'flex-start'} gap={'10px'}>
        <Grid item xs={5}>
          <ThemedButton handler={() => navigateOnControls('createSection', true)}>Crear Sección</ThemedButton>
        </Grid>
        <Grid item xs={5}>
          <Button
            onClick={() => {
              onDemandSubject.sortBySection()
            }}
            sx={{
              background: 'transparent',
              border: '1px solid white',
              borderRadius: '10px',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography sx={{ color: theme.palette.darkGray.main, textTransform: 'lowercase' }}>
              {totalSection + 1} secciones
            </Typography>
            <Typography sx={{ color: theme.palette.darkerGray.main, fontWeight: 'bold' }}>
              A-Z <ArrowDownwardIcon sx={{ fontSize: 'small' }} />
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={4}
        sx={{ height: '100%', overflowY: 'hidden' }}
        direction={'column'}
        justifyContent={'flex-start'}
        alignItems={'center'}>
        {totalSection > 0 && (
          <DragAndDropList
            title={'Orden'}
            defaultList={onDemandSubject.activeSections}
            onChange={(orderList) => {
              let newOrderList = orderList.map((element) => {
                return {
                  id: element.id,
                  weight: element.config.weight,
                }
              })
              onDemandService.reorderSection(newOrderList)
            }}
            renderOption={(option) => {
              if (option.config.weight !== 1) return option.name
            }}
            optionValue={(option, newOrder) => (option.config.weight = newOrder + 1)}
          />
        )}
      </Grid>
    </Grid>
  )
}
export default SectionControls
