import { AccordionDetails, Grid } from '@mui/material'
import React from 'react'

/**
 *
 * @param {object} infoObj data of one notification from the array of notifications.
 * @returns Accordion detail item
 */
export default function PushNotificationDetails({ infoObj }) {

  return (
    <AccordionDetails sx={{ padding: '0 1%', marginBottom: '3px' }}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={4}
        sx={{ backgroundColor: '#666666', padding: '20px', borderRadius: '0 0 10px 10px' }}>
      </Grid>
    </AccordionDetails>
  )
}
