import { useState, useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import getDay from 'date-fns/getDay'
import styled from '@emotion/styled'
import calendarSubject from '../../../subjects/CalendarSubject'
import { es } from 'date-fns/locale'
import LessonTemplate from '../../../subjects/models/LessonTemplate'
import CustomAutocomplete from '../../../shared/Components/CustomAutocomplete/CustomAutocomplete'
import './CalendarSchedule.css'
import { ReactComponent as CalendarIcon } from '../../../img/calendarIcon.svg'
import PaginationComponent from '../../PaginationComponent/PaginationComponent'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Spinner from '../../UI/Spinner/Spinner'

const locales = {
  es: es,
}

/**
 * Object declared as localizer for Scheduler
 */
const localizer = dateFnsLocalizer({
  startOfWeek(es) {},
  format,
  parse,
  getDay,
  locales,
})

/**
 * Local Grid customization
 */
const ThemedHeaderGrid = styled(Grid)(() => ({
  height: '100%',
  h3: {
    color: '#ff4759',
  },
}))

/**
 * Scheduler for Calendar Page
 * @returns CalendarSchedule Component
 */
const CalendarSchedule = () => {
  const [selectedClub, setSelectedClub] = useState(calendarSubject.selectedClub)
  const [selectedActivity, setSelectedActivity] = useState(calendarSubject.selectedActivity)
  const [calendarDayPicked, setCalendarDayPicked] = useState(calendarSubject.schedulerTime)
  const [events, setEvents] = useState()
  const [currentPage, setCurrentPage] = useState(5000)
  const [sedes, setSedes] = useState(calendarSubject.sedes)
  const [activities, setActivities] = useState(calendarSubject.activities)
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Switch between weeks to show in the scheduler
   */
  const onPreviousClickHandler = async () => {
    setCurrentPage(currentPage - 1)
    setIsLoading(true)
    await calendarSubject.updatePaginationSchedule()
    setIsLoading(false)
  }

  /**
   * Switch between weeks to show in the scheduler
   */
  const onFollowingClickHandler = async () => {
    setCurrentPage(currentPage + 1)
    setIsLoading(true)
    await calendarSubject.updatePaginationSchedule(true)
    setIsLoading(false)
  }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onClubActivityUpdate = () => {
    setSelectedActivity(calendarSubject.selectedActivity)
    setSelectedClub(calendarSubject.selectedClub)
  }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onCalendarDayUpdate = () => {
    setCalendarDayPicked(calendarSubject.schedulerTime)
  }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onEventsUpdate = () => {
    setEvents(calendarSubject.events)
  }

  const onSedesUpdate = () => {
    setSedes(calendarSubject.sedes)
  }

  const onActivitiesUpdate = () => {
    setActivities(calendarSubject.activities)
  }

  useEffect(() => {
    calendarSubject.initializeSchedulerTime()
  }, [])

  /**
   * Receives an event, creates a template and sends it to the subject to inform the observers.
   * @param {*} e Event returned from the Scheduler when you click a slot with an event.
   */
  const handleSelectEvent = (e) => {
    let find = calendarSubject.events.find((element) => element.id === e.id)
    let newEvent = new LessonTemplate(e.start)
    newEvent.id = find.id
    newEvent.end = e.end
    newEvent.activityId = find.activityId
    newEvent.clubId = find.clubId
    newEvent.days = find.days
    newEvent.coachId = find.coachId
    newEvent.quota = find.quota
    newEvent.unlimitedCapacity = find.unlimitedCapacity
    newEvent.status = find.status
    calendarSubject.showEventPicked(e)
  }

  /**
   * Receives an Event, creates a template and sends it to the subject to inform the observers.
   * @param {*} e Event returned from the Scheduler when you click an empty slot.
   */
  const handleSelectSlot = (e) => {
    const event = new LessonTemplate(e.start, selectedActivity, selectedClub)
    event.end = e.end
    event.days = [e.start.getDay()]
    calendarSubject.showDayPicked(event)
  }

  /**
   * Updates the SelectedClub value in the subject.
   * @param {*} value value returned from the Club Autocomplete when an option is selected.
   */
  const handleUpdateClub = async (value) => {
    setIsLoading(true)
    await calendarSubject.updateClub(value)
    setIsLoading(false)
  }

  /**
   * Updates the SelectedActivity value in the subject.
   * @param {*} value value returned from the Activity Autocomplete when an option is selected.
   */
  const handleUpdateActivity = async (value) => {
    setIsLoading(true)
    await calendarSubject.updateActivity(value)
    calendarSubject.cleanSelectedDay()
    setIsLoading(false)
  }

  /**
   * UseEffect that adds and removes the observers from the subject.
   */
  useEffect(() => {
    calendarSubject.addObserver(onClubActivityUpdate)
    calendarSubject.addObserver(onSedesUpdate)
    calendarSubject.addObserver(onActivitiesUpdate)
    calendarSubject.addObserver(onCalendarDayUpdate)
    calendarSubject.addObserver(onEventsUpdate)
    return () => {
      calendarSubject.removeObserver(onClubActivityUpdate)
      calendarSubject.removeObserver(onSedesUpdate)
      calendarSubject.removeObserver(onActivitiesUpdate)
      calendarSubject.removeObserver(onCalendarDayUpdate)
      calendarSubject.removeObserver(onEventsUpdate)
    }
  }, [])

  const { formats } = useMemo(
    () => ({
      formats: {
        dayFormat: (date, culture, localizer) => localizer.format(date, 'EEE, dd/MM', culture),
      },
    }),
    []
  )

  return (
    <>
      <ThemedHeaderGrid container item direction={'row'} justifyContent={'space-around'} xs={1.5}>
        <Grid container item direction={'column'} xs={3}>
          <Typography>Planificacion</Typography>
          <Typography variant="h4">Calendario</Typography>
        </Grid>
        <Grid container item direction="row" xs={7} sx={{ position: 'relative' }} spacing={1}>
          <Grid container item direction={'column'} xs={6}>
            <Typography variant="h6" sx={{ color: 'red' }}>
              Sede
            </Typography>
            <CustomAutocomplete
              list={sedes}
              disabled={sedes.length <= 1}
              handler={handleUpdateClub}
              value={selectedClub}
              renderOption={(option) => {
                let sede
                if (option && option.id) {
                  sede = calendarSubject.getSelectedClub(option.id)
                } else {
                  sede = calendarSubject.getSelectedClub(option)
                }
                if (sede && sede.name) return sede.name
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value
              }}
              placeholder="Sede">
              <FiberManualRecordIcon sx={{ fontSize: 'x-small', color: 'white' }} />
            </CustomAutocomplete>
          </Grid>
          {isLoading && <Spinner />}
          <Grid container item direction={'column'} xs={5}>
            <Typography variant="h6" sx={{ color: 'red' }}>
              Act.
            </Typography>
            <CustomAutocomplete
              list={activities}
              handler={handleUpdateActivity}
              value={selectedActivity !== null ? selectedActivity : null}
              renderOption={(option) => {
                let activity
                if (option && option.id) {
                  activity = calendarSubject.getSelectedActivity(option.id)
                } else {
                  activity = calendarSubject.getSelectedActivity(option)
                }
                if (activity && activity.name) return activity.name
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value
              }}
              placeholder="Actividad">
              <FiberManualRecordIcon sx={{ fontSize: 'x-small', color: 'white' }} />
            </CustomAutocomplete>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction={'column'}
          justifyContent={'center'}
          xs={2}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <PaginationComponent
            onPreviousClickHandler={onPreviousClickHandler}
            onFollowingClickHandler={onFollowingClickHandler}
            currentPage={currentPage}
            last={false}>
            <CalendarIcon className="icon" />
          </PaginationComponent>
        </Grid>
      </ThemedHeaderGrid>
      <Grid container item xs={10.5} sx={{ marginTop: '10px', height: '100%', width: '100%' }}>
        {events && (
          <Calendar
            dayLayoutAlgorithm={'no-overlap'}
            defaultView="week"
            culture="es"
            events={events}
            formats={formats}
            min={new Date(2022, 0, 0, 6, 0, 0)}
            max={new Date(2022, 0, 0, 23, 59, 59)}
            localizer={localizer}
            date={calendarDayPicked}
            onNavigate={(e) => e}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            selectable
            onView={() => Views.WEEK}
            view={Views.WEEK}
            toolbar={false}
            step={30}
          />
        )}
      </Grid>
    </>
  )
}

export default CalendarSchedule
