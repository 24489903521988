import { Backdrop, ClickAwayListener, Grid, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState, useRef } from 'react'

const BoxOption = ({ type, value, handler, disabled }) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef()

  const handleOpen = () => {
    if (!disabled) setOpen(!open)
  }

  const printHours = () => {
    let hours = []
    for (let i = 6; i < 24; i++) {
      hours.push(i)
    }
    return hours.map((element) => (
      <MenuItem
        sx={{ m: 0.5, pt: 0, pb: 0, pl: 1, pr: 1 }}
        value={element}
        key={element}
        onClick={() => {
          handleOpen()
          handler(element, type)
        }}>
        <Typography>{('0' + element).slice(-2)}</Typography>
      </MenuItem>
    ))
  }

  const printMinutes = () => {
    let minutes = []
    for (let i = 0; i < 60; i += 5) {
      minutes.push(i)
    }
    return minutes.map((element) => (
      <MenuItem
        sx={{ m: 0.5, pt: 0, pb: 0, pl: 1, pr: 1 }}
        value={element}
        key={element}
        onClick={() => {
          handleOpen()
          handler(element, type)
        }}>
        {('0' + element).slice(-2)}
      </MenuItem>
    ))
  }

  return (
    <Box
      sx={{
        position: 'relative',
        cursor: 'pointer',
        userSelect: 'none',
      }}>
      <Box onClick={() => handleOpen()}>
        {('0' + value).slice(-2)}
        {open && (
          <>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Box
                ref={selectRef}
                sx={{
                  backgroundColor: 'white',
                  overflowY: 'scroll',
                  height: '250px',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 1000,
                }}>
                {type === 'minutes' && printMinutes()}
                {type === 'hours' && printHours()}
              </Box>
            </ClickAwayListener>
            <Backdrop invisible sx={{ zIndex: 10 }} open={open} />
          </>
        )}
      </Box>
    </Box>
  )
}

const CustomTimePicker = ({ value, handler, disabled }) => {
  const constructTime = (newValue, type) => {
    if (type === 'hours') {
      let date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), newValue, value.getMinutes())
      handler(date)
    }
    if (type === 'minutes') {
      let date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), newValue)
      handler(date)
    }
  }

  return (
    <Grid container direction={'row'} sx={{ pt: 1 }}>
      <Grid container item xs={5} justifyContent={'center'}>
        <BoxOption type="hours" value={value.getHours()} handler={constructTime} disabled={disabled} />
      </Grid>
      <Grid container item xs={1} justifyContent={'center'}>
        <Typography>:</Typography>
      </Grid>
      <Grid container item xs={5} justifyContent={'center'}>
        <BoxOption type="minutes" value={value.getMinutes()} handler={constructTime} disabled={disabled} />
      </Grid>
    </Grid>
  )
}
export default CustomTimePicker
