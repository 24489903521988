import { useEffect, useState } from 'react'
import salonSubject from '../../subjects/SalonSubject'
import CreateControls from './PushControls/CreateControls'
import ViewerControls from './PushControls/ViewerControls'
import EditControls from './PushControls/EditControls'

/**
 * Renders the controls according to the selected page.
 * @returns a functions that returns a component.
 */
const SalonControls = () => {
  const [page, setPage] = useState(salonSubject.selectedPage)
  /**
   * Updates the local page variable to the selected page variable from the push notification subject.
   */
  const onPageChange = () => {
    setPage(salonSubject.selectedPage)
  }
  /**
   * Subscribes the component to the push notification subject.
   */
  useEffect(() => {
    salonSubject.addObservers(onPageChange)
    return () => salonSubject.removeObservers(onPageChange)
  }, [])
  /**
   * Switches the renderd component according to the selected page variable from the push notfication subject.
   * @returns a component
   */
  const renderControls = () => {
    switch (page) {
      case 'viewer':
        return <ViewerControls />
      case 'create':
        return <CreateControls />
      case 'edit':
        return <EditControls />
      default:
        return
    }
  }

  return <>{renderControls()}</>
}
export default SalonControls
