import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { styled, TextField, Typography, Backdrop } from '@mui/material'
import { Box } from '@mui/system'
import { ThemedCheckBox } from '../CustomCheckbox/CustomCheckbox'

const ThemedAutocomplete = styled(Autocomplete)(() => ({
  width: '100%',
  '.MuiOutlinedInput-root': {
    padding: 0,
  },
  '.Mui-focused': {
    border: '2px solid white',
    borderRadius: '10px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
}))

const ThemedTextField = styled(TextField)(() => ({
  div: {
    maxHeight: '40px',
    overflow: 'hidden',
  },
}))

const CustomAutocomplete = ({
  list,
  handler,
  multiple,
  value,
  children,
  placeholder,
  renderOption,
  disabled,
  isOptionEqualToValue,
  checkboxOptionStyles,
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (event, values) => {
    handler(values)
  }

  return (
    <>
      <ThemedAutocomplete
        disabled={disabled}
        options={list}
        value={value}
        autoHighlight
        noOptionsText={<Typography sx={{ color: 'black' }}>Sin opciones disponibles</Typography>}
        multiple={multiple}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => setOpen(false)}
        disableCloseOnSelect={multiple}
        limitTags={1}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option, value) => {
                return option.id === value.id
              }
        }
        getOptionLabel={(option) => {
          if (option) return renderOption(option)
          else return ''
        }}
        renderInput={(params) => {
          return (
            <ThemedTextField
              placeholder={!value ? placeholder : multiple && value.length === 0 ? placeholder : ''}
              sx={{ zIndex: 20 }}
              {...params}
              focused
            />
          )
        }}
        onChange={handleChange}
        renderOption={(props, option, state) => {
          return (
            <Box {...props} sx={{ pl: 5, pr: 5 }} key={option.id}>
              <ThemedCheckBox
                borderColor={!checkboxOptionStyles && 'black'}
                checked={state.selected}
                sx={!checkboxOptionStyles ? { color: 'black' } : checkboxOptionStyles}
                children={children}
              />
              <Typography>
                {option.name} {option.surname}
              </Typography>
            </Box>
          )
        }}
      />
      <Backdrop sx={{ zIndex: 10 }} invisible open={open}></Backdrop>
    </>
  )
}
export default CustomAutocomplete
