export class VideoTemplate {
  name = ''
  shortDescription = ''
  longDescription = ''
  startAt = new Date()
  endAt = new Date()
  link = ''
  elements = []
  goals = []
  scores = []
  duration
  severity = ''
  previewImage = ''
  coaches = []
  specialGuest = ''
  isFree = false
  viewTypes = []
  accessLevels = ['MEMBERSHIP']
}
