import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'
import DragAndDropList from '../../../../shared/Components/DragAndDrop/DragAndDropList'
import dragAndDropSubject from '../../../../shared/Components/DragAndDrop/DragAndDropSubject'
import appSubject from '../../../../subjects/AppSubject'
import CircularProgress from '@mui/material/CircularProgress'
import CreateSectionControls from './CreateSectionControls'
import ScheduleSectionControls from './ScheduleSectionControls'

const HomeControls = () => {
  const [page, setPage] = useState(appSubject.selectedPage)
  const [activeSections, setActiveSections] = useState(appSubject.enabledSections)
  const [isLoading, setIsLoading] = useState(false)

  const onPageChange = () => {
    setPage(appSubject.selectedPage)
  }

  const onEnabledSectionsUpdate = () => {
    setActiveSections(appSubject.enabledSections)
  }

  useEffect(() => {
    appSubject.addObserver(onPageChange)
    appSubject.addObserver(onEnabledSectionsUpdate)
    return () => {
      appSubject.removeObserver(onPageChange)
      appSubject.removeObserver(onEnabledSectionsUpdate)
    }
  }, [])

  const handleCreateSectionButton = () => {
    appSubject.updateSelectedPage('createSection', false)
  }

  useEffect(() => {
    dragAndDropSubject.populateList(activeSections)
  }, [activeSections])

  const renderControls = () => {
    switch (page) {
      case 'homeSections': {
        return (
          <Grid container item xs={12} direction="column" justifyContent={'flex-start'}>
            <Grid item xs={0.8}>
              <ThemedButton handler={() => handleCreateSectionButton()}>Crear Sección</ThemedButton>
            </Grid>
            <Grid
              item
              container
              xs={4}
              sx={{ height: '100%', overflowY: 'hidden', position: 'relative' }}
              direction={'column'}
              justifyContent={'flex-start'}
              alignItems={'center'}>
              {activeSections.length > 0 && (
                <DragAndDropList
                  title={'Orden'}
                  defaultList={activeSections}
                  onChange={async (orderList) => {
                    let newOrderList = orderList.map((element, index) => {
                      return {
                        id: element.id,
                        order: index + 2,
                      }
                    })
                    setIsLoading(true)
                    await appSubject.reorderSections(newOrderList)
                    setIsLoading(false)
                  }}
                  renderOption={(option) => {
                    return option.name
                  }}
                  optionValue={(option, newOrder) => (option.order = newOrder)}
                />
              )}
              {isLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '3%',
                    left: '8%',
                    width: '84%',
                    height: '94%',
                    backgroundColor: 'white',
                    opacity: 0.7,
                  }}>
                  <Box>
                    <Box sx={{ position: 'absolute', top: '40%', left: '43%' }}>
                      <CircularProgress />
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        )
      }
      case 'createSection': {
        return <CreateSectionControls />
      }
      case 'scheduleCard': {
        return <ScheduleSectionControls />
      }
      default:
        break
    }
  }
  return <>{renderControls()}</>
}

export default HomeControls
