import { Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../../../css/Theme'
import CustomAccordion from '../../../../shared/Components/CustomAccordion/CustomAccordion'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import appSubject from '../../../../subjects/AppSubject'
import Spinner from '../../../UI/Spinner/Spinner'
import CreateSectionViewer from '../HomeViewer/CreateSectionViewer'
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined'
import { ThemedCheckBox } from '../../../../shared/Components/CustomCheckbox/CustomCheckbox'
import Theme from '../../../../css/Theme'

const SearchRender = ({ page }) => {
  const [expanded, setExpanded] = useState(false)
  const [homeSections, setHomeSections] = useState()
  const [mainSection, setMainSection] = useState()

  const onHomeSectionsUpdate = () => {
    setMainSection(appSubject.mainSection)
    setHomeSections(appSubject.homeSections)
  }

  useEffect(() => {
    appSubject.addObserver(onHomeSectionsUpdate)
    return () => {
      appSubject.removeObserver(onHomeSectionsUpdate)
    }
  }, [])

  const renderViewer = () => {
    switch (page) {
      case 'homeSections': {
        return (
          <>
            {mainSection && (
              <>
                <CustomAccordion
                  key={0}
                  page={page}
                  id={`mainSection-0`}
                  infoObj={mainSection}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  subject={homeSections}
                  borderRadius={'10px'}>
                  <MainHomeSectionSummary value={mainSection} />
                </CustomAccordion>
                <Divider
                  sx={{
                    color: 'transparent',
                    borderTop: `1px dashed ${theme.palette.darkGray.main}`,
                    marginBottom: '4px',
                  }}></Divider>
              </>
            )}
            {homeSections &&
              homeSections.map((element, index) => (
                <CustomAccordion
                  key={index}
                  page={page}
                  id={`${page}-${index}`}
                  infoObj={element}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  subject={homeSections}>
                  <CommonHomeSectionSummary value={element} />
                </CustomAccordion>
              ))}
          </>
        )
      }
      case 'createSection': {
        return <CreateSectionViewer />
      }
      case 'scheduleCard': {
        return <CreateSectionViewer />
      }
      default:
        return <></>
    }
  }

  return (
    <Grid
      container
      item
      wrap="nowrap"
      direction="column"
      xs={10.5}
      sx={
        appSubject.isCreating
          ? {
              overflowY: 'auto',
              width: '100%',
              padding: '20px',
              paddingRight: '2%',
              background: theme.palette.lightGray.main,
              borderRadius: '15px',
              height: '100%',
            }
          : {
              overflowY: 'auto',
              background: theme.palette.primary.main,
              height: '100%',
            }
      }>
      {homeSections && mainSection && renderViewer()}
    </Grid>
  )
}
//Pantalla izquierda
const HomePageViewer = () => {
  const [page, setPage] = useState(appSubject.selectedPage)
  const [isCreating, setIsCreating] = useState(appSubject.isCreating)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let getAllSections = async () => {
      if (page === 'homeSections') {
        setIsLoading(true)
        try {
          await appSubject.updateHomeSectionList()
          setIsLoading(false)
        } catch (err) {
          console.log(err)
          setIsLoading(false)
        }
      }
    }
    getAllSections()
  }, [page])

  const onPageChange = () => {
    setPage(appSubject.selectedPage)
    setIsCreating(appSubject.isCreating)
  }

  useEffect(() => {
    appSubject.addObserver(onPageChange)
    return () => {
      appSubject.removeObserver(onPageChange)
    }
  }, [])

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>App</Typography>
          <Typography variant="h4" fontWeight="bold">
            Home
          </Typography>
        </Grid>
        <Grid container item xs={4} direction="column">
          <Grid item xs={6}>
            {!isCreating && (
              <CustomTextField searchbutton={1} onChange={(e) => appSubject.searchSections(e.target.value)} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={0.25}>
        <Divider orientation="horizontal" sx={{ background: 'black' }} />
      </Grid>
      <SearchRender page={page} />
      {isLoading && <Spinner />}
    </>
  )
}

export default HomePageViewer

const MainHomeSectionSummary = ({ value }) => {
  return (
    <>
      <Grid item container direction="row">
        <Grid item xs={0.5}>
          <HouseOutlinedIcon sx={{ width: '20px' }}></HouseOutlinedIcon>
        </Grid>
        <Grid item container direction="row" xs={6} justifyContent="space-between">
          <Typography noWrap sx={{ fontWeight: 'bolder' }}>
            {value.name}
          </Typography>
          <Grid item container xs={3}>
            {value.enabled ? (
              <Typography sx={{ color: theme.palette.darkerTransparentGray.main }}>Activo</Typography>
            ) : (
              <Typography sx={{ color: 'red' }}>Inactivo</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={0.5}>
          <Divider orientation="vertical" color="black" sx={{ height: '30px' }} flexItem={true} />
        </Grid>
      </Grid>
    </>
  )
}

const CommonHomeSectionSummary = ({ value }) => {
  return (
    <>
      <Grid item container direction="row">
        <Grid item xs={0.5}>
          <ThemedCheckBox sx={{ width: '20px' }} borderColor={Theme.palette.darkerGray.main} />
        </Grid>
        <Grid item container direction="row" xs={6} justifyContent="space-between">
          <Typography noWrap sx={{ fontWeight: 'bolder' }}>
            {value.name}
          </Typography>
          <Grid item container xs={3}>
            {value.enabled ? (
              <Typography sx={{ color: theme.palette.darkerTransparentGray.main }}>Activo</Typography>
            ) : (
              <Typography sx={{ color: 'red' }}>Inactivo</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={0.5}>
          <Divider orientation="vertical" color="black" flexItem={true} />
        </Grid>
        <Grid item container xs={5} justifyContent={'center'}>
          <Grid item container xs={10}>
            <Grid item container xs={5}>
              {value.type && (
                <Typography
                  sx={{
                    color: theme.palette.darkerTransparentGray.main,
                    textTransform: 'capitalize',
                  }}>
                  {value.type}
                </Typography>
              )}
            </Grid>
            <Grid item container xs={5}>
              {value.category && (
                <Typography sx={{ fontStyle: 'italic', color: theme.palette.darkerTransparentGray.main }}>
                  {value.category.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
