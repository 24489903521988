import { useEffect, useState } from 'react'
import pushNotificationSubject from '../../subjects/PushNotificationsSubject'
import CreateControls from './PushControls/CreateControls'
import ViewerControls from './PushControls/ViewerControls'

/**
 * Renders the controls according to the selected page.
 * @returns a functions that returns a component.
 */
const PushNotificationControls = () => {
  const [page, setPage] = useState(pushNotificationSubject.selectedPage)
  /**
   * Updates the local page variable to the selected page variable from the push notification subject.
   */
  const onPageChange = () => {
    setPage(pushNotificationSubject.selectedPage)
  }
  /**
   * Subscribes the component to the push notification subject.
   */
  useEffect(() => {
    pushNotificationSubject.addObservers(onPageChange)
    return () => pushNotificationSubject.removeObservers(onPageChange)
  }, [])
  /**
   * Switches the renderd component according to the selected page variable from the push notfication subject.
   * @returns a component
   */
  const renderControls = () => {
    switch (page) {
      case 'viewer':
        return <ViewerControls />
      case 'create':
        return <CreateControls />
      default:
        return
    }
  }

  return <>{renderControls()}</>
}
export default PushNotificationControls
