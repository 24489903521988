export class CardTemplate {
  title
  subtitle
  enabled
  images
  action
  activeUntil
  activeSince
  actionConfig = {}

  constructor(card) {
    this.id = card ? card.id : undefined
    this.title = card ? card.title : ''
    this.subtitle = card ? card.subtitle : ''
    this.enabled = card ? card.enabled : true
    this.images = card ? card.images : []
    this.action = card ? card.action : 'link'
    this.activeUntil = card ? card.activeUntil : undefined
    this.activeSince = card ? card.activeSince : undefined

    if (card) {
      switch (card.action) {
        case 'link': {
          this.actionConfig.link = card.actionConfig.link
          break
        }
        case 'urlLink': {
          this.actionConfig.link = card.actionConfig.link
          break
        }
        case 'page': {
          this.actionConfig.image = card.actionConfig.image
          this.actionConfig.content = card.actionConfig.content
          break
        }
        default:
          this.actionConfig = {}
          break
      }
    }
  }
}
