class DragAndDropSubject {
  list = []
  observers = []

  addObserver(element) {
    this.observers.push(element)
  }

  removeObserver(element) {
    let index = this.observers.indexOf(element)
    this.observers.splice(index, 1)
  }

  notify() {
    this.observers.forEach((element) => element())
  }

  populateList(list) {
    this.list = [...list]
    this.notify()
  }

  cleanList() {
    this.list = []
    this.notify()
  }

  addItemsToList(item) {
    let found = this.list.find((element) => element.id === item.id)
    if (!found) {
      let arr = [...this.list]
      arr.push(item)
      this.list = [...arr]
      this.notify()
    }
  }

  removeItemFromList(item) {
    let found = this.list.find((element) => element.id === item.id)
    if (found) {
      let index = this.list.indexOf(found)
      this.list.splice(index, 1)
      this.notify()
    }
  }
}

const dragAndDropSubject = new DragAndDropSubject()
export default dragAndDropSubject
