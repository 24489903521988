import { Grid } from '@mui/material'
import React from 'react'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField'
import appSubject from '../../../../subjects/AppSubject'

const CardMainControls = () => {
  const handleCreateCardButton = () => {
    appSubject.updateSelectedPage('createCard', false)
  }

  return (
    <Grid direction="column" sx={{ height: '100%' }} container wrap="nowrap" justifyContent={'space-between'}>
      <Grid container item xs={5} gap={1} direction="column" wrap="nowrap">
        <Grid container item xs={2}>
          <ThemedButton handler={() => handleCreateCardButton()}>Crear Card</ThemedButton>
        </Grid>
      </Grid>
      <Grid container item xs={1} direction="column" justifyContent="flex-end">
        <Grid container item xs={1}>
          <CustomTextField
            searchbutton={1}
            onChange={(e) => appSubject.searchCards(e.target.value)}
            placeholder="Buscar..."></CustomTextField>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardMainControls
