import { Divider, FormControlLabel, FormGroup, Grid, MenuItem, Switch } from '@mui/material'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import CustomSelect from '../../../shared/Components/CustomSelect/CustomSelect'
import CustomTextField from '../../../shared/Components/CustomTextField/CustomTextField'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme from '../../../css/Theme'

/**
 * Renders a form to create and save a new section.
 * @returns a component.
 */
const CreateSectionControls = () => {
  /**
   * Executes the saveSection function in onDemandSubject.
   */
  const onSaveNewItem = () => {
    onDemandSubject.saveSection()
  }

  return (
    <>
      <Grid
        container
        item
        xs={4}
        direction="column"
        justifyContent={'space-around'}
        alignItems={'center'}
        sx={{ height: '100%' }}>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <ThemedButton background="transparent" border="1px solid red" color={'red'}>
            {onDemandSubject.newSection.id ? 'Modificar Sección' : 'Nueva Sección'}
          </ThemedButton>
        </Grid>
        <Grid container item xs={1} justifyContent={'center'}>
          <KeyboardArrowDownIcon sx={{ fontSize: '40px', color: 'white' }} />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newSection && onDemandSubject.newSection.name}
            onChange={(e) => (onDemandSubject.newSection.name = e.target.value)}
            placeholder={'Nombre'}
          />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomSelect
            defaultValue={
              onDemandSubject.newSection.config && onDemandSubject.newSection.config.viewType
                ? onDemandSubject.newSection.config.viewType
                : 'Tipo'
            }
            onChange={(e) => (onDemandSubject.newSection.config.viewType = e.target.value)}>
            <MenuItem value="Tipo" disabled>
              Tipo
            </MenuItem>
            <MenuItem value="STANDARD" disabled={onDemandSubject.newSection.config.weight === 1}>
              Standard
            </MenuItem>
            <MenuItem value="PRINCIPAL">Principal</MenuItem>
            <MenuItem value="FEATURED" disabled={onDemandSubject.newSection.config.weight === 1}>
              Featured
            </MenuItem>
          </CustomSelect>
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <Divider variant="middle" sx={{ backgroundColor: 'white', width: '90%' }} />
      </Grid>
      <Grid container item xs={4} direction="column" justifyContent={'flex-start'} sx={{ height: '100%' }}>
        <Grid container item xs={2} direction="column" alignItems={'center'} justifyContent={'space-evenly'}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={onDemandSubject.newSection.config.weight === 1}
                  color={'warning'}
                  onChange={(e) => onDemandSubject.changeSectionPublished(e.target.checked)}
                  defaultChecked={onDemandSubject.newSection.config && onDemandSubject.newSection.config.published}
                />
              }
              label="Publicar"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item xs={4} direction="column" justifyContent={'flex-end'} sx={{ height: '100%' }}>
        <Grid item xs={3}>
          <ThemedButton handler={onSaveNewItem}>GUARDAR</ThemedButton>
        </Grid>
      </Grid>
    </>
  )
}
export default CreateSectionControls
