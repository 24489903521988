import onDemandService from '../http/services/OnDemandService'
import { SaveProgramRequestModel } from '../http/requestModels/SaveProgramRequestModel'
import { SaveSectionRequestModel } from '../http/requestModels/SaveSectionRequestModel'
import { SaveVideoRequestModel } from '../http/requestModels/SaveVideoRequestModel'
import { SectionTemplate } from './models/SectionTemplate'
import { ProgramTemplate } from './models/ProgramTemplate'
import { VideoTemplate } from './models/VideoTemplate'
import resourceService from '../http/services/ResourcesService'

class OnDemandSubject {
  observers = []
  searchObserver = []

  videos = []
  programs = []
  mainSection = {}
  sections = []
  selectedPage = 'videos'
  selectedItem = null
  isCreating = false
  newVideo = new VideoTemplate()
  newSection = new SectionTemplate()
  newProgram = new ProgramTemplate()
  filteredVideo = [...this.videos]
  filteredSection = [...this.sections]
  filteredProgram = [...this.programs]

  filterVideosForSectionDetails = []
  filterProgramsForSectionDetails = []
  filterVideosForProgramDetails = []
  addedContentToSection = []
  selectedSeason = 1
  addedVideosToPrograms = []

  elementList = []
  coachList = []
  goalList = []
  accessLevelList = []
  severityList = []
  activeSections = []

  contentBlobImages = {}

  paging = {}

  totalElementsVideo = 0
  totalElementsProgram = 0

  imagesForViewTypes = {}
  searchTimeout = null

  formDataVideos = []
  formDataPrograms = []

  /**
   * Adds an observer ti the array of observers
   * @param {Object} observer new observer to be conected to the subject
   */
  addObserver(observer) {
    this.observers.push(observer)
  }

  /**
   * Removes an observer from the array of observers
   * @param {Object} observer the observer to be removed from the subject
   */
  removeObserver(observer) {
    let index = this.observers.indexOf(observer)
    this.observers.splice(index, 1)
  }

  /**
   * Notifies all the conected observer of the updates
   */
  notify() {
    this.observers.forEach((element) => element())
  }

  addSearchObserver(observer) {
    this.searchObserver.push(observer)
  }

  removeSearchObserver(observer) {
    let index = this.searchObserver.indexOf(observer)
    this.searchObserver.splice(index, 1)
  }

  notifySearch() {
    this.searchObserver.forEach((element) => element())
  }

  /**
   * Update program list
   * @return {Promise<void>}
   */
  async updateProgramList(page, pageSize) {
    let data = await onDemandService.getProgramList(page, pageSize)
    this.programs = [...data.result]
    this.filteredProgram = [...this.programs]
    this.filterProgramsForSectionDetails = [...this.programs]
    this.paging = { ...data.paging }
    this.notify()
  }

  /**
   * Get form data
   * @return {Promise<void>}
   */
  async getFormData() {
    Promise.all([
      onDemandService.getFormData(),
      onDemandService.getVideoList(null, 100, true),
      onDemandService.getProgramList(null, null, true),
    ])
      .then((data) => {
        data[1].result.forEach((element) => (element.contentType = 'video'))
        data[2].result.forEach((element) => (element.contentType = 'program'))
        this.elementList = [...data[0].elementList]
        this.coachList = [...data[0].coachList]
        this.goalList = [...data[0].goalList]
        this.accessLevelList = [...data[0].accessLevelList]
        this.severityList = [...data[0].severityList]
        this.filterVideosForProgramDetails = [...data[1].result]
        this.formDataVideos = [...data[1].result]
        this.filterVideosForSectionDetails = [...data[1].result]
        this.filterProgramsForSectionDetails = [...data[2].result]
        this.formDataPrograms = [...data[2].result]
        this.totalElementsVideo = data[1].paging.total_elements
        this.totalElementsProgram = data[2].paging.total_elements
      })
      .finally(() => {
        this.notify()
      })
  }

  /**
   * Update video list
   * @return {Promise<void>}
   */
  async updateVideoList(page, pageSize) {
    let data = await onDemandService.getVideoList(page, pageSize)
    this.videos = [...data.result]
    this.filteredVideo = [...this.videos]
    this.filterVideosForProgramDetails = [...this.videos]
    this.filterVideosForSectionDetails = [...this.videos]
    this.paging = { ...data.paging }
    this.notify()
  }

  /**
   * Update section list
   * @return {Promise<void>}
   */
  async updateSectionList() {
    let response = await onDemandService.getSectionList()
    this.sections = []

    this.activeSections = response.result.filter((elem) => elem.config.published === true)

    response.result.forEach((element) => {
      if (element.config.weight === 1) {
        this.mainSection = { ...element }
      } else {
        this.sections = [...this.sections, { ...element }]
      }
    })
    this.filteredSection = [...this.sections]
    this.updateSectionsState()
  }

  /**
   * Sets the addedVideosToPrograms variable to the array of videos, according to the selectedSeason.
   */
  getSelectedSeason() {
    let find = this.newProgram.seasons.find((element) => element.number === this.selectedSeason)
    if (find) {
      this.addedVideosToPrograms = [...find.videos.sort((a, b) => a.order - b.order)]
    } else this.addedVideosToPrograms = []
    this.notify()
  }

  /**
   * Sets the selectedSeason variable to the selected season and executes the getSelectedSeason funcion to get all the videos correspondint to that season.
   * @param {Number} seasonId the season number
   */
  setSelectedSeason(seasonId) {
    this.selectedSeason = seasonId
    this.getSelectedSeason()
    this.notify()
  }

  /**
   * Creates a new season if the current season has at least one video.
   */
  createNewSeason() {
    const numberSeason = this.newProgram.seasons.map((e) => e.number)
    let id = Math.max.apply(null, numberSeason) + 1
    let arr = [...this.newProgram.seasons]
    if (arr[arr.length - 1].videos.length > 0) {
      arr.push({ number: id, videos: [] })
      this.newProgram.seasons = [...arr]
      this.setSelectedSeason(id)
      this.notify()
    } else alert('La última temporada debe tener al menos 1 capítulo asignado antes de crear una nueva temporada.')
  }

  /**
   * Depending of the type, switches the page to the selected creating page, sets the isCreating variable to true and sets the corresponding variables with the data to modify.
   * @param {Object} item
   * @param {String} type current page.
   * @returns
   */
  onSelectItemToModify(item, type) {
    this.addedContentToSection = []
    this.addedVideosToPrograms = []
    switch (type) {
      case 'videos': {
        this.selectedPage = 'createVideo'
        this.isCreating = true
        this.newVideo = { ...item }
        break
      }
      case 'section': {
        this.selectedPage = 'createSection'
        this.isCreating = true
        this.newSection = { ...item }
        this.addedContentToSection = [...item.contents]
        break
      }
      case 'program': {
        if (item.seasons.length < 1) {
          item.seasons = [{ number: 1, videos: [] }]
        }
        this.selectedPage = 'createProgram'
        this.isCreating = true
        this.newProgram = { ...item }
        this.addedVideosToPrograms = [...item.seasons]
        this.selectedSeason = 1
        break
      }
      default:
        return
    }
    this.notify()
  }

  /**
   * Sets the isCreating variable to true or false depending on the pageName, and creates a new instance of a template depending on the pageName.
   * @param {String} pageName page to navigate to.
   * @param {Boolean} cleanSelectedItem if true creates a new instance of a template depending on rhe pageName.
   */
  updateSelectedPage(pageName, cleanSelectedItem) {
    this.selectedPage = pageName
    this.imagesForViewTypes = {}
    if (
      this.selectedPage === 'createVideo' ||
      this.selectedPage === 'createProgram' ||
      this.selectedPage === 'createSection'
    ) {
      this.isCreating = true
    } else this.isCreating = false
    if (cleanSelectedItem === true) {
      if (pageName === 'createVideo') this.newVideo = new VideoTemplate()
      if (pageName === 'createProgram') {
        this.newProgram = new ProgramTemplate()
        this.selectedSeason = 1
      }
      if (pageName === 'createSection') this.newSection = new SectionTemplate()
    }
    this.notify()
  }

  /**
   * Updates the filtered arrays. FilteredVideo, filterVideosForSectionDetails and filterVideosForProgramDetails are the arrays renderd in the pages, witch need to be updated in case of ne items or new deletes.
   */
  setfilteredArr() {
    this.filteredVideo = [...this.videos]
    this.filteredSection = [...this.sections]
    this.filteredProgram = [...this.programs]
  }

  /**
   * Deletes an item from an array accoding to the type.
   * @param {Number} id id of the item to remove.
   * @param {String} type type of item. The type variable is used to search for the item in the correct array.
   * @returns
   */
  async deleteSelectedItem(id, type) {
    let result
    switch (type) {
      case 'videos': {
        result = window.confirm('¿Está seguro que desea borrar este video?')
        if (result === true) {
          let arr = [...this.videos]
          let found = arr.find((element) => element.id === id)
          if (found) {
            let index = arr.indexOf(found)
            arr.splice(index, 1)
          }
          this.videos = [...arr]
          this.notify()
          await onDemandService.deleteVideo(id)
          this.updateVideoList()
          this.totalElementsVideo -= 1
        }
        break
      }
      case 'section': {
        if (id === this.mainSection.id) {
          alert('No es posible eliminar esta sección')
        } else {
          result = window.confirm('¿Está seguro que desea borrar esta sección?')
          if (result === true) {
            await onDemandService.deleteSection(id)
            this.updateSectionList()
          }
        }
        break
      }
      case 'program': {
        result = window.confirm('¿Está seguro que desea borrar este programa?')
        if (result === true) {
          await onDemandService.deleteProgram(id)
          this.updateProgramList()
          this.totalElementsProgram -= 1
        }
        break
      }
      default:
        return
    }
    this.notify()
  }

  /**
   * Saves a new video in the array of videos. First it checks if all the required fields are completed and alerts the user in case there are empty fields. It checks if the id already exists (modification case) and proceeds to replace the item or add a new one (creation case). Updates the page variable, used to navigate and the filtered arrays.
   */
  async saveVideo() {
    if (this.newVideo.startAt > this.newVideo.endAt) {
      alert('La fecha de finalización no puede ser posterior a la fecha de publicación')
    } else if (
      this.newVideo.name === '' ||
      this.newVideo.coaches.length === 0 ||
      this.newVideo.goals.length === 0 ||
      this.newVideo.severity === '' ||
      this.newVideo.link === ''
    ) {
      alert('Quedan campos por completar')
    } else {
      let selected = [...this.filteredVideo]
      let find = selected.find((element) => element.id === this.newVideo.id)
      let index = selected.indexOf(find)
      if (this.newVideo.elements.length < 1) this.newVideo.elements = null
      const videoRequest = new SaveVideoRequestModel(this.newVideo)
      if (index !== -1) {
        selected.splice(index, 1)
        try {
          videoRequest.view_types = this.constructViewType(find, 'video')
          videoRequest.preview_image = this.imagesForViewTypes.preview
            ? this.constructPreviewImage(find, 'video')
            : this.newVideo.previewImage
          const videoResult = await onDemandService.putVideo(this.newVideo.id, videoRequest)
          this.uploadImages(find.id, 'videos').then(() => {
            selected.unshift(videoResult)
            this.updateVideoState(selected)
          })
        } catch (e) {
          console.log(e)
          alert('No se pudo actualizar el video')
        }
      } else {
        try {
          let videoResult = await onDemandService.postVideo(videoRequest)
          videoRequest.view_types = this.constructViewType(videoResult, 'video')
          videoRequest.preview_image = this.constructPreviewImage(videoResult, 'video')
          Promise.all([
            this.uploadImages(videoResult.id, 'videos'),
            onDemandService.putVideo(videoResult.id, videoRequest),
          ]).then(() => {
            selected.unshift(videoResult)
            this.updateVideoState(selected)
          })
        } catch (e) {
          alert('No se pudo guardar el video')
        }
      }
      this.getFormData()
    }
  }

  /**
   * Update video state
   * @param selected
   */
  updateVideoState(selected) {
    this.filteredVideo = [...selected]
    this.setfilteredArr()
    this.updateSelectedPage('videos', true)
    this.notify()
  }

  /**
   * Saves a new section in the array of sections. First it checks if all the required field are completed and alerts the user in case there are empty fields. It checks if the id already exists (modification case) and proceeds to replace the item or add a new one (creation case). Updates the page variable, used to navigate and the filtered arrays.
   */
  async saveSection() {
    let selected
    if (this.newSection.name === '' || this.newSection.config.viewType === '') {
      alert('Quedan campos por completar')
    } else if (this.addedContentToSection.length === 0) {
      alert('La sección debe tener al menos un contenido')
    } else {
      selected = [{ ...this.mainSection }, ...this.sections]
      let find = selected.find((element) => element.id === this.newSection.id)
      let index = selected.indexOf(find)
      this.newSection.contents = [...this.addedContentToSection]
      const sectionRequest = new SaveSectionRequestModel(this.newSection)
      if (index !== -1) {
        try {
          sectionRequest.view_types = this.constructViewType(find, 'section')
          selected[index] = await onDemandService.putSection(this.newSection.id, sectionRequest)
          await this.uploadImages(find.id, 'sections')
          this.updateSectionList()
        } catch (e) {
          alert('No se pudo actualizar la sección')
        }
      } else {
        try {
          let sectionResult = await onDemandService.postSection(sectionRequest)
          sectionRequest.view_types = this.constructViewType(sectionResult, 'section')
          Promise.all([
            this.uploadImages(sectionResult.id, 'sections'),
            onDemandService.putSection(sectionResult.id, sectionRequest),
          ]).then(() => {
            selected.unshift(sectionResult)
            this.updateSectionList()
          })
        } catch (e) {
          if (
            e.response.data.message ===
            '{"error":"section_creation_error","message":"A section with name nueva already exists"}'
          ) {
            alert('Ya existe una sección con el mismo nombre')
          } else {
            alert('No se pudo guardar la sección')
          }
        }
      }
      this.getFormData()
    }
  }

  /**
   * Update sections state
   * @param selected
   */
  updateSectionsState() {
    this.updateSelectedPage('sections', true)
    this.setfilteredArr()
    this.addedContentToSection = []
    this.notify()
  }

  /**
   * Saves a new program in the array of programs. First it checks if all the required field are completed and alerts the user in case there are empty fields. It checkes if the id already exists (modification case) and procedes to replace the item or add a new one (creation case). Updates the page variable, used to navigate and the filtered arrays.
   */
  async saveProgram() {
    let selected
    if (this.newProgram.name === '' || this.newProgram.severity === '') {
      alert('Quedan campos sin completar')
    } else if (this.addedVideosToPrograms.length === 0) {
      alert('La temporada debe tener al menos un video')
    } else {
      selected = [...this.programs]
      let find = selected.find((element) => element.id === this.newProgram.id)
      let index = selected.indexOf(find)
      let seasonFound = this.newProgram.seasons.find((element) => element.number === this.selectedSeason)
      seasonFound.videos = [...this.addedVideosToPrograms]
      if (this.newProgram.startAt === '') this.newProgram.startAt = new Date()
      const programRequest = new SaveProgramRequestModel(this.newProgram)
      if (index !== -1) {
        try {
          programRequest.view_types = this.constructViewType(find, 'program')
          programRequest.preview_image = this.imagesForViewTypes.preview
            ? this.constructPreviewImage(find, 'program')
            : this.newProgram.previewImage
          selected[index] = await onDemandService.putProgram(this.newProgram.id, programRequest)
          await this.uploadImages(find.id, 'programs')

          this.updateProgramState(selected)
        } catch (e) {
          alert('No se pudo actualizar el programa')
        }
      } else {
        try {
          let programResult = await onDemandService.postProgram(programRequest)
          programRequest.view_types = this.constructViewType(programResult, 'program')
          programRequest.preview_image = this.constructPreviewImage(programResult, 'program')
          Promise.all([
            this.uploadImages(
              programResult.id,
              'programs',
              onDemandService.putProgram(programResult.id, programRequest)
            ),
          ]).then(() => {
            selected.unshift(programResult)
            this.updateProgramState(selected)
          })
        } catch (e) {
          alert('No se pudo guardar el programa')
        }
      }
      this.getFormData()
    }
  }

  /**
   * Update program state
   * @param selected
   */
  updateProgramState(selected) {
    this.programs = [...selected]
    this.setfilteredArr()
    this.updateSelectedPage('programs', true)
    this.addedVideosToPrograms = []
    this.notify()
  }

  /**
   * Filters the array of videos by name and description. If the input value is empty it sets the filterd array to equal the video array
   * @param {Object} e input event
   */
  searchHandler = (e, page) => {
    let inputText = e.target.value.toLowerCase()
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(async () => {
      if (inputText.length >= 1) {
        let data
        switch (page) {
          case 'sections':
            data = [...this.sections]
            let filtered = []
            data.forEach((elem) => {
              let name = elem.name.toLowerCase()
              if (name.includes(inputText)) {
                filtered.push(elem)
              }
              this.filteredSection = [...filtered]
            })
            break
          case 'programs':
            data = await onDemandService.getProgramList(null, null, null, inputText)
            this.filteredProgram = [...data.result]
            this.paging = { ...data.paging }
            break
          case 'videos':
            data = await onDemandService.getVideoList(null, null, null, inputText)
            this.filteredVideo = [...data.result]
            this.paging = { ...data.paging }
            break
          case 'createProgram':
            data = await onDemandService.getVideoList(null, null, null, inputText)
            data.result.forEach((element) => (element.contentType = 'video'))
            this.filterVideosForProgramDetails = [...data.result]
            break
          case 'createSection-Videos':
            data = await onDemandService.getVideoList(null, null, null, inputText)
            data.result.forEach((element) => (element.contentType = 'video'))
            this.filterVideosForSectionDetails = [...data.result]
            break
          case 'createSection-Programas':
            data = await onDemandService.getProgramList(null, null, null, inputText)
            data.result.forEach((element) => (element.contentType = 'program'))
            this.filterProgramsForSectionDetails = [...data.result]
            break
          default:
            break
        }
      }
      if (e.target.value === '') {
        this.filteredVideo = [...this.videos]
        this.filteredSection = [...this.sections]
        this.filteredProgram = [...this.programs]
        this.filterProgramsForSectionDetails = [...this.formDataPrograms]
        this.filterVideosForSectionDetails = [...this.formDataVideos]
        this.filterVideosForProgramDetails = [...this.formDataVideos]
      }
      this.notifySearch()
    }, 1000)
  }

  /**
   * Sorts the array of video, in alphabetical order by name.
   */
  sortByName = () => {
    let arr = [...this.videos]

    arr.sort(function (a, b) {
      let first = a.name.toLowerCase()
      let second = b.name.toLowerCase()
      if (first < second) {
        return -1
      }
      if (first > second) {
        return 1
      }
      return 0
    })
    this.filteredVideo = [...arr]
    this.notify()
  }
  /**
   *  Sorts the array of program, in alphabetical order by name.
   */
  sortByProgram = () => {
    let arr = [...this.programs]

    arr.sort(function (a, b) {
      let first = a.name.toLowerCase()
      let second = b.name.toLowerCase()
      if (first < second) {
        return -1
      }
      if (first > second) {
        return 1
      }
      return 0
    })

    this.programs = [...arr]
    this.setfilteredArr()
    this.notify()
  }
  /**
   *  Sorts the array of section, in alphabetical order by name.
   */
  sortBySection = () => {
    let arr = [...this.sections]

    arr.sort(function (a, b) {
      let first = a.name.toLowerCase()
      let second = b.name.toLowerCase()
      if (first < second) {
        return -1
      }
      if (first > second) {
        return 1
      }
      return 0
    })
    this.sections = [...arr]
    this.setfilteredArr()
    this.notify()
  }

  /**
   * Checkes if the id already exists, if not than adds the instance to the array of sections.
   * @param {Object} element  sectionTemplate instance
   */
  handleAddContentToSection = (element) => {
    let arr = [...this.addedContentToSection]
    let find = arr.find((elem) => element.id === elem.id)
    let index = arr.indexOf(find)
    if (index === -1) {
      element.weight = arr.length + 1
      arr.push(element)
      this.filterVideosForSectionDetails = [...this.formDataVideos]
      this.filterProgramsForSectionDetails = [...this.formDataPrograms]
      this.addedContentToSection = [...arr]
      this.notify()
    }
  }
  /**
   * Removes a section instance from the array of sections
   * @param {Object} element intance of categoyTemplate
   */
  handleRemoveContentToSection = (element) => {
    let arr = [...this.addedContentToSection]
    let find = arr.find((elem) => elem.id === element.id)
    let index = arr.indexOf(find)
    arr.splice(index, 1)
    this.addedContentToSection = [...arr]
    this.filterVideosForSectionDetails = [...this.formDataVideos]
    this.filterProgramsForSectionDetails = [...this.formDataPrograms]
    this.notify()
  }
  /**
   * Checkes if the id already exists, if not than adds the instance to the array of programs.
   * @param {Object} element  programTemplate instance
   */
  handleAddVideosToPrograms = (element) => {
    let arr = [...this.addedVideosToPrograms]
    let find = arr.find((elem) => element.id === elem.id)
    let index = arr.indexOf(find)
    if (index === -1) {
      arr.push(element)
      this.addedVideosToPrograms = [...arr]
      this.filterVideosForProgramDetails = [...this.formDataVideos]
      this.notify()
    }
  }
  /**
   * Removes a program instance from the array of programs
   * @param {Object} element intance of programTemplate
   */
  handleRemoveVideosToPrograms = (element) => {
    let arr = [...this.addedVideosToPrograms]
    let find = arr.find((elem) => elem.id === element.id)
    let index = arr.indexOf(find)
    arr.splice(index, 1)
    this.addedVideosToPrograms = [...arr]
    this.filterVideosForProgramDetails = [...this.formDataVideos]
    this.notify()
  }
  /**
   * filter the section array and returns a new array with the sections that have the active flag in true
   * @returns an array of sections that have the active flag true
   */
  activeSectionsFinder = () => {
    let activeSections = this.sections.filter((elem) => elem.config.published === true)
    return activeSections
  }
  /**
   * Get coaches name
   * @param {number} id
   * @returns {String} coaches name
   */
  getCoachesName = (id) => {
    const video = this.videos.find((element) => element.id === id)
    if (video) {
      return video.coaches.map((element) => element.name).join(', ')
    }
    return 'Staff'
  }

  /**
   * Update new video coaches
   * @param values
   */
  handlerCoaches = (values) => {
    onDemandSubject.newVideo.coaches = [...values]
    this.notify()
  }

  /**
   * Assign a weight to the section and change isPublished.
   */
  changeSectionPublished = (published) => {
    this.newSection.config.published = published
    let newWeight = this.activeSections[this.activeSections.length - 1].config.weight + 1
    this.newSection.config.weight = newWeight
    this.notify()
  }

  updateSelectedContentViewType = (type, file) => {
    this.imagesForViewTypes[type] = file
  }

  constructViewType = (content, contentType) => {
    let availableViewTypes =
      content.viewTypes.map((element) => {
        return { view_type: element.viewType, image: element.image }
      }) || []

    Object.keys(this.imagesForViewTypes).forEach((element) => {
      if (element !== 'preview') {
        let extension = element !== 'featured' ? 'jpg' : 'png'
        let found = availableViewTypes.find((elem) => elem.view_type === element)
        if (found) {
          found.image = `${contentType}_${element}_${content.id}.${extension}`
        } else {
          availableViewTypes.push({
            view_type: element,
            image: `${contentType}_${element}_${content.id}.${extension}`,
          })
        }
      }
    })
    return availableViewTypes
  }

  constructPreviewImage(content, contentType) {
    return `${contentType}_preview_${content.id}.jpg`
  }

  async uploadImages(id, contentType) {
    Object.keys(this.imagesForViewTypes).forEach((element) => {
      resourceService.postImage(id, this.imagesForViewTypes[element], element, contentType)
    })
  }

  reorderSection = (newOrder) => {
    onDemandService.reorderSection(newOrder)
    this.notify()
  }

  reorderContentInPrograms = (newOrderList) => {
    this.addedVideosToPrograms = [...newOrderList]
    this.notify()
  }
}

const onDemandSubject = new OnDemandSubject()
export default onDemandSubject
