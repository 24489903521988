class LessonTemplate {
  start = null
  end = null
  time = {
    start: {
      hours: 0,
      min: 0,
      sec: 0,
    },
    end: {
      hours: 0,
      min: 0,
      sec: 0,
    },
  }
  activityId = null
  clubId = null
  salonId = null
  quota = 0
  coachId = null
  days = []
  unlimitedCapacity = false
  status = false
  constructor(start, activityId, clubId) {
    this.start = start
    this.activityId = activityId
    this.clubId = clubId
  }
}

export default LessonTemplate
