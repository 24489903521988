export class ProgramTemplate {
  name = ''
  severity = ''
  previewImage = ''
  seasons = [{ number: 1, videos: [] }]
  viewTypes = []
  shortDescription = ''
  longDescription = ''
  accessLevels = ['ALL']
  endAt = ''
  startAt = ''
}
