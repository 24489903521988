import React, { useState, useEffect, useCallback } from 'react'
let logoutTimer

const AppContext = React.createContext({
  token: '',
  password: '',
  errorMessage: '',
  isLoggedIn: false,
  club: 0,
  menuFixed: false,
  loading: false,
  login: (token) => {},
  logout: () => {},
  setLoading: () => {},
  setMenuFixed: () => {},
})

const calculateRemainingTime = (expirationDate) => {
  const currentTime = new Date().getTime()
  const adjexpirationDate = new Date(expirationDate).getTime()

  return adjexpirationDate - currentTime
}

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token')
  const storedExpirationDate = localStorage.getItem('expirationDate')
  const storedPassword = localStorage.getItem('password')

  const remainingTime = calculateRemainingTime(storedExpirationDate)

  if (remainingTime <= 1000) {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    return null
  }

  return {
    token: storedToken,
    duration: remainingTime,
    password: storedPassword ? storedPassword : '',
  }
}

export const AppContextProvider = (props) => {
  const tokenData = retrieveStoredToken()

  let initialToken, initialPassword
  if (tokenData) {
    initialToken = tokenData.token
    initialPassword = tokenData.password
  }

  const [token, setToken] = useState(initialToken)
  const [password] = useState(initialPassword)
  const [loading, setLoading] = useState(false)
  const [menuFixed, setMenuFixed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userIsLoggedIn = !!token

  const logoutHandler = useCallback(() => {
    setMenuFixed(false)
    setToken(null)
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('club')

    if (logoutTimer) {
      clearTimeout(logoutTimer)
    }
  }, [])

  const loginHandler = (token, expirationDate, password = null) => {
    setToken(token)
    localStorage.setItem('token', token)
    localStorage.setItem('expirationDate', expirationDate)

    if (password) {
      localStorage.setItem('password', password)
    }

    const remainingTime = calculateRemainingTime(expirationDate)

    logoutTimer = setTimeout(logoutHandler, remainingTime)
  }

  const setLoadingHandler = (newLoadingValue) => {
    setLoading(newLoadingValue)
  }

  const setMenuFixedHandler = (newMenuFixed) => {
    setMenuFixed(newMenuFixed)
  }

  const setErrorMessageHandler = (errorMessage) => {
    setErrorMessage(errorMessage)
  }

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration)
    }
  }, [tokenData, logoutHandler])

  const contextValue = {
    token: token,
    menuFixed: menuFixed,
    isLoggedIn: userIsLoggedIn,
    errorMessage: errorMessage,
    loading: loading,
    password: password,
    login: loginHandler,
    logout: logoutHandler,
    setLoading: setLoadingHandler,
    setMenuFixed: setMenuFixedHandler,
    setErrorMessage: setErrorMessageHandler,
  }

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>
}

export default AppContext
