import onDemandSubject from '../../subjects/OnDemandSubject'
import { useEffect, useState } from 'react'
import VideoControls from './OnDemandControls/VideoControls'
import ProgramControls from './OnDemandControls/ProgramControls'
import SectionControls from './OnDemandControls/SectionControls'
import CreateVideoControls from './OnDemandControls/CreateVideoControls'
import CreateProgramControls from './OnDemandControls/CreateProgramControls'
import CreateSectionControls from './OnDemandControls/CreateSectionControls'

/**
 * Renders a component for each page
 * @returns a component according to the selected page
 */
const OnDemandControls = () => {
  const [page, setPage] = useState(onDemandSubject.selectedPage)
  /**
   * Updates the local variable page to the value of selectedPage from onDemandSubject
   */
  const onPageChange = () => {
    setPage(onDemandSubject.selectedPage)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onPageChange)
    return () => {
      onDemandSubject.removeObserver(onPageChange)
    }
  }, [])

  /**
   * Switches the rendered component according to the page variable.
   * @returns a component according to the selected page.
   */
  const renderControls = () => {
    switch (page) {
      case 'videos':
        return <VideoControls />
      case 'programs':
        return <ProgramControls />
      case 'sections':
        return <SectionControls />
      case 'createVideo':
        return <CreateVideoControls />
      case 'createSection':
        return <CreateSectionControls />
      case 'createProgram':
        return <CreateProgramControls />
      default:
        return <></>
    }
  }
  return <>{renderControls()}</>
}
export default OnDemandControls
