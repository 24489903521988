import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import CardsPage from '../pages/App/Cards/CardsPage'
import HomePage from '../pages/App/Home/HomePage'
import SalonsPage from '../pages/SalonsPage/SalonsPage'
import SedesPage from '../pages/SedesPage/SedesPage'
import CalendarPage from '../pages/CalendarPage/CalendarPage'
import OnDemandPage from '../pages/OnDemandPage/OnDemandPage'
import PushNotificationPage from '../pages/PushNotificationPage/PushNotificationPage'
import ClassReservationPage from '../pages/ClassReservation/ClassReservationPage'

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<CalendarPage />} />
      <Route path="/planificacion/calendario" exact element={<CalendarPage />} />
      <Route path="/video/ondemand" exact element={<OnDemandPage />} />
      <Route path="/app/home" exact element={<HomePage />} />
      <Route path="/app/salons" exact element={<SalonsPage />} />
      <Route path="/app/sedes" exact element={<SedesPage />} />
      <Route path="/app/cards" exact element={<CardsPage />} />
      <Route path="/notificaciones/push" exact element={<PushNotificationPage />} />
      <Route path="/clases/reservar" exact element={<ClassReservationPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default AdminRoutes
