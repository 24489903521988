import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import CardControls from '../../../components/App/Cards/CardControls/CardControls'
import CardViewer from '../../../components/App/Cards/CardViewer/CardViewer'
import appSubject from '../../../subjects/AppSubject'

const CardsPage = () => {
  useEffect(() => {
    appSubject.updateSelectedPage('cardList', false)
  }, [])

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90%' }}>
      <Grid
        container
        item
        wrap="nowrap"
        xs={7.5}
        style={{ overflow: "auto" }}
        direction={'column'}
        justifyContent={'flex-start'}
        sx={{
          border: '1px solid #cccccc',
          borderRadius: '15px',
          padding: '10px',
          paddingBottom: 0,
          ml: '40px',
          mt: '20px',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <CardViewer />
      </Grid>
      <Grid
        container
        item
        xs={3.5}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        sx={{ backgroundColor: '#cccccc', mt: '20px', height: '100%', padding: '20px' }}>
        <CardControls />
      </Grid>
    </Grid>
  )
}

export default CardsPage
