import {styled} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer/Drawer";
import Theme from "../../../css/Theme";
import * as React from "react";

const drawerWidth = '300px';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(4)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '.MuiPaper-root': {
      height: 'calc(100vh - 90px)',
      top:'25px',
      backgroundColor: Theme.palette.darkerTransparentGray.main,
      marginLeft: '-5px',
      borderRadius: '5px',
      borderColor: Theme.palette.lightGray.main,
      borderWidth:'2px',
      borderTop:'none',
      borderBottom:'none'
    }
  }),
);

export default function ThemedDrawer(props) {

  return (
    <Drawer variant="permanent" open={props.open}>
      {props.children}
    </Drawer>
  );
}