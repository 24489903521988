import { AccordionDetails, Grid, Typography } from '@mui/material'
import React from 'react'
import classSubject from "../../../subjects/ClassSubject"
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'

/**
 *
 * @param {object} infoObj data of one classReservation from the array of notifications.
 * @returns Accordion detail item
 */
export default function ClassReservationDetail({ infoObj }) {

  return (
    <AccordionDetails sx={{ padding: '0 1%', marginBottom: '3px' }}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={4}
        sx={{ backgroundColor: '#666666', padding: '20px', borderRadius: '0 0 10px 10px' }}>
        <Grid container>
          <Grid item container xs={4}>
            <Typography sx={{ color: "white" }}>Fecha creación: {classSubject.setFormattedDate(infoObj.createdAt)}</Typography>
          </Grid>
          <Grid item container xs={4}>
            <Typography sx={{ color: "white" }}>
              Fecha asistencia: {infoObj.redemptionDate == null ? "-" : classSubject.setFormattedDate(infoObj.redemptionDate)}
            </Typography>
          </Grid>
          {!infoObj.isBooked && (
            <Grid item container xs={4}>
              <ThemedButton background="transparent" border="1px solid" color={'white'} handler={() => classSubject.putBookClass(infoObj.id)}>
                Confirmar asistencia
              </ThemedButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </AccordionDetails>
  )
}
