import { Divider, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CardList from './CardList'
import appSubject from '../../../../subjects/AppSubject'
import CreateCardViewer from './CreateCardViewer'
import Spinner from '../../../UI/Spinner/Spinner'

const CardViewer = () => {
  const [cardList, setCardList] = useState([])
  const [page, setPage] = useState(appSubject.selectedPage)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let getCardList = async () => {
      if (page === 'cardList') {
        setIsLoading(true)
        try {
          await appSubject.updateHomeCardList()
          setIsLoading(false)
        } catch (err) {
          console.log(err)
          setIsLoading(false)
        }
      }
    }
    getCardList()
  }, [page])

  const onCardChange = () => {
    setCardList([...appSubject.cards])
  }

  const onPageChange = () => {
    setPage(appSubject.selectedPage)
  }

  useEffect(() => {
    appSubject.addObserver(onCardChange)
    appSubject.addObserver(onPageChange)
    return () => {
      appSubject.removeObserver(onCardChange)
      appSubject.removeObserver(onPageChange)
    }
  }, [])

  const renderViewer = () => {
    switch (page) {
      case 'cardList': {
        return <>{cardList && <CardList cardList={cardList} />}</>
      }
      case 'createCard': {
        return (
          <>
            <CreateCardViewer />
          </>
        )
      }
      default:
        return
    }
  }

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>App</Typography>
          <Typography variant="h4">Cards</Typography>
        </Grid>
      </Grid>
      <Divider orientation="horizontal"></Divider>
      {renderViewer()}
      {isLoading && <Spinner />}
    </>
  )
}

export default CardViewer
