import { differenceInMinutes } from 'date-fns'
import { toIsoString } from '../../Utils/Utils'

export default class UpdateLessonModel {
  coachId
  salonId
  quota
  unlimitedCapacity
  status
  startDate
  duration
  days
  endDate

  constructor(lessonTemplate) {
    let daysOfWeek = [
      { name: 'Monday', n: 1 },
      { name: 'Tuesday', n: 2 },
      { name: 'Wednesday', n: 3 },
      { name: 'Thursday', n: 4 },
      { name: 'Friday', n: 5 },
      { name: 'Saturday', n: 6 },
      { name: 'Sunday', n: 0 },
    ]

    const constructDays = () => {
      let days = lessonTemplate.days.map((element) => {
        let day = daysOfWeek.find((elem) => elem.n === element)
        return day.name
      })
      return days.toString()
    }

    this.coachId = lessonTemplate.coachId
    this.salonId = lessonTemplate.salonId
    this.quota = lessonTemplate.quota
    this.unlimitedCapacity = lessonTemplate.unlimitedCapacity
    this.status = lessonTemplate.status
    this.startDate = toIsoString(lessonTemplate.start)
    this.days = lessonTemplate.days ? constructDays() : null
    this.duration = calculateDuration(lessonTemplate)
    this.endDate = toIsoString(lessonTemplate.end)
  }
}

const calculateDuration = (lessonTemplate) => {
  let start = lessonTemplate.start
  let end = new Date(start)
  end.setHours(lessonTemplate.end.getHours())
  end.setMinutes(lessonTemplate.end.getMinutes())

  return differenceInMinutes(end, start)
}
