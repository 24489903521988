import { Grid } from "@mui/material";
import { ThemedButton } from "../../../shared/Components/CustomButton/CustomButton";
import CustomTextField from "../../../shared/Components/CustomTextField/CustomTextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import theme from "../../../css/Theme";
import salonSubject from "../../../subjects/SalonSubject";

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page) => {
  salonSubject.setSelectedPage(page);
};

/**
 * Renders part of the form that creates and saves a a new notification.
 * @returns
 */
const CreateControls = () => {
  const [loading, setloading] = useState(false);

  const handleSave = async () => {
    setloading(true);
    await salonSubject.saveSalon();
    setloading(false);
  };
  return (
    <>
      <Grid
        container
        item
        xs={11}
        direction="column"
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
        wrap={"nowrap"}
        sx={{ height: "100%" }}
      >
        <Grid
          container
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={1} sx={{ width: "100%" }}>
            <ThemedButton
              background="transparent"
              border="2px solid red"
              color={"red"}
            >
              Nuevo Salón
            </ThemedButton>
          </Grid>
          <Grid item xs={1} justifyContent={"center"}>
            <KeyboardArrowDownIcon
              sx={{ color: "white", height: "100%", fontSize: "60px" }}
            />
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }}>
          <CustomTextField
            placeholder={"Nombre (máx. 50 caract.)"}
            backgroundcolor={"white"}
            background={theme.palette.lightGray.main}
            defaultValue={
              salonSubject.newSalon.name ? salonSubject.newSalon.name : ""
            }
            onChange={(e) => {
              salonSubject.newSalon.name = e.target.value;
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <ThemedButton handler={!loading ? handleSave : null}>
          {!loading ? "GUARDAR" : "GUARDANDO..."}
        </ThemedButton>
      </Grid>

      <Grid item xs={1} sx={{ mt: 1 }}>
        <ThemedButton
          handler={() => navigateOnControls("viewer")}
          background="transparent"
          border="2px solid red"
          color={"red"}
        >
          CANCELAR
        </ThemedButton>
      </Grid>
    </>
  );
};
export default CreateControls;
