export class linkActionConfig {
  content

  constructor(action) {
    this.content = action.content || ''
  }
}

export class urlLinkActionConfig {
  content

  constructor(action) {
    this.content = action.content || ''
  }
}

export class contentPageActionConfig {
  content
  image
  buttonText
  buttonLink

  constructor(action) {
    this.image = action.image || ''
    this.content = action.content || ''
    this.buttonLink = action.buttonLink || ''
    this.buttonText = action.buttonText || ''
  }
}
