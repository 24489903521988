const goals = {
  Fuerza: 'STRENGTH',
  Resistencia: 'ENDURANCE',
  Flexibilidad: 'FLEXIBILITY',
  Movilidad: 'MOBILITY',
  Estabilidad: 'STABILITY',
  Equilibrio: 'BALANCE',
  'Alivio del estrés': 'STRESS_RELIEF',
  Acondicionamiento: 'CONDITIONING',
  Agilidad: 'AGILITY',
  Potencia: 'POWER',
  Relajación: 'RELAXATION',
  Activación: 'ACTIVATION',
  'Bajar de peso': 'LOSE_WEIGHT',
  'Quemar calorías': 'BURN_CALORIES',
  'Resistencia aeróbica': 'AEROBIC_RESISTANCE',
  'Condición física general': 'GENERAL_PHYSICAL_CONDITION',
}

export const translateGoal = (goal) => {
  return goals[goal]
}
