import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import appSubject from '../../../../subjects/AppSubject';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PillSelect from '../../../../shared/Components/PillSelect/PillSelect';
import CustomTextField from '../../../../shared/Components/CustomTextField/CustomTextField';
import UploadImg from '../../../../shared/Components/UploadImgComponent/UploadImg';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const CreateCardViewer = () => {
  const [selectedAction, setSelectedAction] = useState(appSubject.selectedCardAction);
  const [selectedCardActionConfig, setSelectedCardActionConfig] = useState(appSubject.selectedCardActionConfig);
  const [selectedCard, setSelectedCard] = useState({ ...appSubject.newCard });

  const navigateOnViewer = () => {
    appSubject.updateSelectedPage('cardList', true);
  };

  const onSelectedCard = () => {
    setSelectedCard(appSubject.newCard);
  };

  const onCardActionChange = () => {
    setSelectedAction(appSubject.selectedCardAction);
    setSelectedCardActionConfig(appSubject.selectedCardActionConfig);
  };

  const getImgName = (keyword) => {
    let found = selectedCard.images.find((element) => element.viewType.toUpperCase() === keyword.toUpperCase());
    if (found) return found.image;
    else return '';
  };

  const toURL = (imageFile) => {
    if (typeof imageFile !== 'string') {
      return URL.createObjectURL(imageFile);
    } else return imageFile;
  };

  useEffect(() => {
    appSubject.addObserver(onSelectedCard);
    appSubject.addObserver(onCardActionChange);
    return () => {
      appSubject.removeObserver(onSelectedCard);
      appSubject.removeObserver(onCardActionChange);
    };
  }, []);

  const actions = [
    { value: 'link', label: 'Link', props: { disabled: true } },
    { value: 'page', label: 'Pantalla de Contenido' },
  ];

  const renderActions = () => {
    switch (selectedAction) {
      case 'urlLink':
      case 'link': {
        return (
          <Grid container item direction="row" justifyContent="flex-start" sx={{ my: 2 }}>
            <Grid container item xs={2}></Grid>
            <Grid
              container
              item
              xs={7}
              direction="row"
              justifyContent="center"
              alignContent="flex-start"
              sx={{ outline: '2px solid white', borderRadius: '15px', height: '150px' }}
            >
              <Grid container item direction="row" justifyContent="flex-start" sx={{ height: 'fit-content', mt: 2 }}>
                <Grid container item xs={5}>
                  <Typography fontWeight={900} align="center" sx={{ pl: 2.5, width: '100%' }}>
                    Acción:
                  </Typography>
                </Grid>
                <Grid container item xs={5.5} justifyContent="center">
                  <PillSelect
                    defaultValue={selectedAction}
                    options={actions}
                    onOptionSelected={(e) => appSubject.onCardActionChange(e)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={10} direction="row" justifyContent="center" sx={{ mt: 3 }}>
                <CustomTextField
                  background={'#cccccc'}
                  backgroundcolor="white"
                  defaultValue={selectedCardActionConfig.content}
                  onChange={(e) => (selectedCardActionConfig.content = e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }
      case 'page': {
        return (
          <Grid container item direction="row" justifyContent="flex-start" sx={{ my: 2, pr: 1 }}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="flex-end"
              alignContent="flex-start"
              sx={{ outline: '2px solid white', borderRadius: '15px' }}
            >
              <Grid container item direction="row" justifyContent="center" sx={{ height: 'fit-content', mt: 2 }}>
                <Grid container item xs={3}>
                  <Typography fontWeight={900} align="center" sx={{ pl: 2.5, width: '100%' }}>
                    Acción:
                  </Typography>
                </Grid>
                <Grid container item xs={3.25} justifyContent="center">
                  <PillSelect
                    defaultValue={selectedAction}
                    options={actions}
                    onOptionSelected={(e) => appSubject.onCardActionChange(e)}
                  />
                </Grid>
                <Grid container item xs={1.75}></Grid>
              </Grid>
              <Grid container item xs={8} direction="column" rowSpacing={2} sx={{ my: 2 }}>
                <Grid container item direction="row"></Grid>
                <Grid container item direction="row">
                  <Grid container item xs={3} style={{ justifyContent: "end" }}>
                    <Typography>Imagen</Typography>
                  </Grid>
                  <Grid container item xs={8.5} justifyContent="center">
                    <UploadImg
                      onChange={(file) => (appSubject.selectedImageForCard.content = file)}
                      value={toURL(selectedCardActionConfig.image)}
                      infoImg={{ format: 'JPG/PNG', resolution: '428 X 642' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={2}></Grid>
            </Grid>
            <Typography style={{ textAlign: "center", display: "block", margin: "0 auto", marginTop: "30px", marginBottom: "30px", width: "100%" }}>
              Content (HTML)
            </Typography>
            <div style={{ width: "700px", display: "block", margin: "0 auto" }}>
              <CKEditor
                editor={Editor}
                style={{ width: "500px", display: "block", margin: "0 auto" }}
                data={selectedCardActionConfig.content ? selectedCardActionConfig.content : ''}
                onChange={(event, editor) => {
                  selectedCardActionConfig.content = editor.getData();
                }}
              />
            </div>
            <Grid container style={{ display: "block", margin: "0 auto", marginBottom: "10px", marginTop: "10px" }} item xs={8} direction="column" rowSpacing={2} sx={{ my: 2 }}>
              <Grid container item direction="row"></Grid>
              <Grid container item direction="row" style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container item xs={3} style={{ justifyContent: "end" }}>
                  <Typography>Texto del botón</Typography>
                </Grid>
                <Grid container item xs={8.5} justifyContent="center">
                  <CustomTextField
                    placeholder={'Texto del botón'}
                    backgroundcolor={'white'}
                    onChange={(e) => selectedCardActionConfig.buttonText = e.target.value }
                    defaultValue={selectedCardActionConfig.buttonText ? selectedCardActionConfig.buttonText : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ display: "block", margin: "0 auto", marginBottom: "20px" }} item xs={8} direction="column" rowSpacing={2} sx={{ my: 2 }}>
              <Grid container item direction="row"></Grid>
              <Grid container item direction="row" style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container item xs={3} style={{ justifyContent: "end" }}>
                  <Typography>Link del botón</Typography>
                </Grid>
                <Grid container item xs={8.5} justifyContent="center">
                  <CustomTextField
                    placeholder={'URL'}
                    backgroundcolor={'white'}
                    onChange={(e) => selectedCardActionConfig.buttonLink = e.target.value }
                    defaultValue={selectedCardActionConfig.buttonLink ? selectedCardActionConfig.buttonLink : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
      default:
        break;
    }
  };

  return (
    <Grid container item sx={{ pr: 1 }}>
      <Grid
        container
        item
        direction="row"
        wrap="nowrap"
        sx={{ backgroundColor: '#cccccc', mt: 1, px: 1, mx: 1, borderRadius: '15px' }}
      >
        <Grid container item xs={1} justifyContent="center" alignContent="flex-start" sx={{ mt: 2 }}>
          <ArrowForwardIosIcon
            onClick={navigateOnViewer}
            sx={{
              fontSize: 'xx-large',
              transform: 'rotate(180deg)',
              color: 'white',
              cursor: 'pointer',
            }}
          />
        </Grid>
        <Grid container item xs={11} direction="column" wrap="nowrap" sx={{ mt: 2 }}>
          <Grid container item direction="row" xs={4} justifyContent="center">
            <Grid container item direction="column" xs={4} sx={{ my: 1 }} justifyContent="center" alignItems="center">
              <UploadImg
                onChange={(file) => (appSubject.selectedImageForCard.small = file)}
                value={toURL(getImgName('small'))}
                infoImg={{ format: 'JPG/PNG', resolution: '171 x 155' }}
              />
              <Typography>Small</Typography>
            </Grid>
            <Grid container item direction="column" xs={4} sx={{ my: 1 }} justifyContent="center" alignItems="center">
              <UploadImg
                onChange={(file) => (appSubject.selectedImageForCard.medium = file)}
                value={toURL(getImgName('medium'))}
                infoImg={{ format: 'JPG/PNG', resolution: '167 X 160' }}
              />
              <Typography>Medium</Typography>
            </Grid>
          </Grid>
          <Divider />
          {renderActions()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateCardViewer;
