import { Grid } from '@mui/material'
/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
/**
 * Renders buttons that sort notifications and that navigate to "Create categories". Also renders filters for the array of notifications.
 */
const ViewerControls = () => {
  return (
    <Grid container item xs={6} direction="column" justifyContent={'flex-start'} gap={3}>
      <Grid item xs={2}>
      </Grid>
    </Grid>
  )
}
export default ViewerControls
