import { useEffect, useState } from "react";
import sedeSubject from "../../../subjects/SedeSubject";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import theme from "../../../css/Theme";
import EditIcon from "@mui/icons-material/Edit";

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page, selectedRoom) => {
  sedeSubject.setSelectedPage(page)
  sedeSubject.setSelectedName(selectedRoom)
}

/**
 * Renders the array of notifications as accordions.
 * @returns
 */
export const SedesList = () => {
  const [sedes, setSede] = useState(sedeSubject.filteredSedes);
  /**
   * Updates the local push notification array to the filtered notifiactions array from the ṕush notification subject.
   */
  const onPushNotificationUpdate = () => {
    setSede(sedeSubject.filteredSedes);
  };
  /**
   * Subscribes the component to the subject.
   */
  useEffect(() => {
    sedeSubject.addObservers(onPushNotificationUpdate);
    return () => {
      sedeSubject.removeObservers(onPushNotificationUpdate);
    };
  }, []);
  return (
    <>
      <Grid
        container
        item
        wrap="nowrap"
        direction="column"
        xs={10}
        sx={{
          overflowY: "auto",
          background: theme.palette.primary.main,
          height: "100%",
        }}
      >
        {sedes.map((sede, i) => {
          return <SedeSummary key={i} id={`${sede.nombre}-${i}`} value={sede} />;
        })}
      </Grid>
    </>
  );
};
const SedeSummary = ({ value }) => {
  return (
    <>
      <Grid item container direction="row">
        <Grid
          justifyContent={'flex-start'}
          sx={{ mt: 1 }}
          item
          container
          direction="row"
          xs={6}
        >
          <Typography
            color={theme.palette.darkGray.main}
            noWrap
            sx={{ fontWeight: "bolder" }}
          >
            {value.nombre}
          </Typography>
        </Grid>
        <Divider
          sx={{ mt: 1 }}
          orientation="vertical"
          color={theme.palette.primary.main}
          flexItem={true}
        />
        <Grid
          justifyContent={'flex-end'}
          item
          container
          direction="row"
          xs={5}
        >
          <IconButton onClick={() => navigateOnControls('edit', value)}>
            <EditIcon color="info" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
