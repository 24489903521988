import { Grid } from '@mui/material'
import React from 'react'
import CardItem from './CardItem'

const CardList = ({ cardList }) => {
  return (
    <Grid
      container
      item
      sx={{ pl: 5, pt: 5, height: '100%', overflowY: 'auto' }}
      xs={11}
      direction="row"
      alignContent="flex-start">
      {cardList && cardList.map((element, index) => <CardItem key={index} item={element}></CardItem>)}
    </Grid>
  )
}

export default CardList
