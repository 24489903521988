import React, { useState, useEffect, useRef } from 'react'
import { Input, Button, Box, Typography } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import Theme from '../../../css/Theme'
import styled from '@emotion/styled'

const ThemedTypography = styled(Typography)(() => ({
  color: Theme.palette.darkerGray.main,
}))
/**
 * Renders a box woth an image preview or with an input to upload an image.
 * @param {String} value image url
 * @returns a component with the image preview or with an input to upload an image.
 */
export default function UploadImg({ value, infoImg, onChange }) {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState(value)

  let objectUrl = useRef()

  useEffect(() => {
    if (selectedFile) {
      objectUrl.current = URL.createObjectURL(selectedFile)
      setPreview(objectUrl.current)
    }
    return () => URL.revokeObjectURL(objectUrl.current)
  }, [selectedFile])

  /**
   * If there is no input files it sets the selectFile variable to undefined. if the input file has a valid format, the selectFile variable is setted to the input target. If the format is not valid the user is alerted
   * @param {Object} e input event
   * @returns
   */
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
      onChange(e.target.files[0])
      setSelectedFile(e.target.files[0])
    } else alert('not a valid format')
  }

  return (
    <label>
      <Input
        inputProps={{ accept: 'image/jpg image/png' }}
        id="contained-button-file"
        multiple
        type="file"
        sx={{ display: 'none' }}
        onChange={(e) => {
          onSelectFile(e)
        }}
      />
      <Button
        variant="contained"
        component="div"
        sx={{
          background: 'white',
          padding: '0%',
          border: '1px solid gray',
          borderRadius: '10px',
          ':hover': {
            background: 'white',
          },
        }}>
        {preview ? (
          <Box
            component="img"
            sx={{
              height: 150,
              width: 200,
              maxHeight: { xs: 150 },
              maxWidth: { xs: 200 },
              borderRadius: '10px',
              objectFit: 'contain',
            }}
            alt="Seleccionar imagen"
            src={preview.includes('blob') ? preview : process.env.REACT_APP_RESOURCES_BASEURL + preview}
          />
        ) : (
          <>
            <Box
              sx={{
                margin: '5%',
                textAlign: 'center',
              }}>
              <ImageIcon />
              <ThemedTypography sx={{ fontSize: 'small' }}>Formato compatible</ThemedTypography>
              <ThemedTypography sx={{ fontWeight: 'bolder', fontSize: 'small' }}>{infoImg.format}</ThemedTypography>
              <ThemedTypography sx={{ fontSize: 'small' }}>Resolución recomendada</ThemedTypography>
              <ThemedTypography sx={{ fontWeight: 'bold', fontSize: 'small' }}>{infoImg.resolution}</ThemedTypography>
            </Box>
          </>
        )}
      </Button>
    </label>
  )
}
