import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import appSubject from '../../../../subjects/AppSubject'
import { ThemedButton } from '../../../../shared/Components/CustomButton/CustomButton'

const CardItem = ({ item }) => {
  const [image, setImage] = useState('')

  useEffect(() => {
    if (item.images.length > 0) {
      fetch(process.env.REACT_APP_RESOURCES_BASEURL + item.images[0].image).then((response) => {
        response.blob().then((res) => {
          setImage(URL.createObjectURL(res))
        })
      })
    }
    return () => {
      setImage()
    }
  }, [item.images])

  const clickHandler = () => {
    appSubject.onSelectToModifyCard(item.id)
  }

  const onDeleteHandler = () => {
    appSubject.deleteCard(item.id)
  }

  return (
    <Grid
      container
      item
      direction="column"
      xs={3.5}
      sx={{
        m: 2,
        border: '1px solid #aaaaaa',
        borderRadius: '15px',
        position: 'relative',
        height: '160px',
        width: '100%',
      }}>
      <Box
        onClick={() => clickHandler()}
        sx={{ height: '100%', width: 'inherit', borderRadius: 'inherit', objectFit: 'contain' }}
        component="img"
        src={image}
        alt={item.title}
      />
      <Grid
        container
        item
        sx={{
          width: 'fit-content',
          backgroundColor: 'rgba(255,255,255,0.6)',
          position: 'absolute',
          top: 0,
          mt: 2,
          ml: 1,
          px: 2,
          borderRadius: '9999px',
        }}>
        <Typography>{item.enabled ? 'Activo' : 'Inactivo'}</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        wrap="nowrap"
        sx={{
          backgroundColor: 'rgba(255,255,255,0.4)',
          width: '100%',
          height: '35%',
          position: 'absolute',
          bottom: 0,
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        }}>
        <Grid container item xs={9}>
          <Typography sx={{ pl: 2, width: '95%' }} fontSize="18px" noWrap textOverflow="ellipsis" fontWeight={900}>
            {item.title}
          </Typography>
        </Grid>
        <Grid container item xs={3}>
          <ThemedButton
            handler={onDeleteHandler}
            margin="5px"
            size="small"
            background="#ffa33e"
            width="80px"
            height="25px"
            borderRadius="5px"
            fontWeight="bold"
            fontSize="12px">
            Eliminar
          </ThemedButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardItem
