import Axios from 'axios'
import genericAxiosClient from '../axios/GenericAxiosClient'

class AuthService {
  axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_LOGIN_BASEURL,
    timeout: 9000,
    headers: {
      Authorization: 'Basic ' + btoa('megatlon:user').trim(),
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    },
  })

  querystring = require('querystring')

  login(email, password) {
    const authData = {
      username: email,
      password: password,
      grant_type: 'password',
      is_admin: true,
    }

    return this.axiosInstance
      .post('oauth/token', this.querystring.stringify(authData))
      .then((response) => {
        return response.data.access_token
      })
      .catch((err) => {
        throw err.response
      })
  }

  async getClubId() {
    return await genericAxiosClient.get('/admin/user')
  }
}

const authService = new AuthService()
export default authService
