import { Grid, Typography } from "@mui/material";
import { ThemedButton } from "../../../shared/Components/CustomButton/CustomButton";
import CustomTextField from "../../../shared/Components/CustomTextField/CustomTextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useCallback, useEffect, useState } from "react";
import theme from "../../../css/Theme";
import UploadImg from '../../../shared/Components/UploadImgComponent/UploadImg'
import sedeSubject from "../../../subjects/SedeSubject";

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page) => {
  sedeSubject.setSelectedPage(page);
};

/**
 * Renders part of the form that creates and saves a a new notification.
 * @returns
 */
const EditControls = () => {
  const [loading, setLoading] = useState(false);
  const [newSalon, setNewSalon] = useState(sedeSubject.newSalon);
  const [ , setTitle] = useState(sedeSubject.newSalon.nombre)
  const [direccionError, setDireccionError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [nombreError, setNombreError] = useState(false);
  const [latitudError, setLatitudError] = useState(false);
  const [longitudError, setLongitudError] = useState(false);
  const [horarioError, setHorarioError] = useState(false);
  const [emailError, setEmailError] = useState(false)

  useEffect(() => {
    setNewSalon(sedeSubject.newSalon);
  }, []);

   const toURL = (imageFile) => {
    if (typeof imageFile !== 'string' && imageFile) {
      return URL.createObjectURL(imageFile)
    } else return imageFile
  }

  const resetState = useCallback(() => {
    setTitle(sedeSubject.newSalon.nombre);
  }, []);

  useEffect(() => {
    sedeSubject.addObserver(resetState);
    resetState();
    return () => {
      sedeSubject.removeObserver(resetState);
    };
  }, [resetState]);

  function validation() {
    const fields = [
      { name: "telefono", errorSetter: setTelefonoError },
      { name: "nombre", errorSetter: setNombreError },
      { name: "direccion", errorSetter: setDireccionError },
      { name: "latitud", errorSetter: setLatitudError },
      { name: "longitud", errorSetter: setLongitudError },
      { name: "schedule", errorSetter: setHorarioError },
      { name: "email", errorSetter: setEmailError }
    ];
  
    let errors = 0;
  
    fields.forEach(field => {
      const value = sedeSubject.newSalon[field.name].trim();
      if (value === "") {
        errors++;
        field.errorSetter(true);
      } else {
        field.errorSetter(false);
      }
    });
  
    return errors === 0;
  }

  const handlePut = async () => {
    setLoading(true);
    var passValidation = validation();
    if(!passValidation){
      setLoading(false);
      alert("Completar los campos faltantes")
    }
    else{
      await sedeSubject.putSede(newSalon);
      setLoading(false);
    }
    
  };


  return (
    <>
      <Grid
        container
        item
        xs={11}
        direction="column"
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
        wrap={"nowrap"}
        sx={{ height: "100%" }}
        style={{ overflow: "auto" }}
      >
        <Grid
          container
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={1} sx={{ width: "100%" }}>
            <ThemedButton
              background="transparent"
              border="2px solid red"
              color={"red"}
            >
              Editar Sede
            </ThemedButton>
          </Grid>
          <Grid item xs={1} justifyContent={"center"}>
            <KeyboardArrowDownIcon
              sx={{ color: "white", height: "100%", fontSize: "60px" }}
            />
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }} style={{  marginBottom: "20px" }}>
          <div className="container" style={{ display: "flex", flexDirection:"row", gap:"20px", justifyContent:"space-around" }}>
            {sedeSubject.newSalon.cardImagen &&
              <div className="input-wrapper" style={{ position:"relative" }}>
                <Typography style={{ position:"absolute", top: "-20px" }}>Imagen card</Typography><br></br>
                <img style={{ height:"100px", marginTop: "10px" }} alt="cardimage" src={process.env.REACT_APP_RESOURCES_BASEURL + toURL(sedeSubject.newSalon.cardImagen)}></img>
              </div>
            }
            {sedeSubject.newSalon.imagen &&
              <div className="input-wrapper" style={{ position:"relative" }}>
                <Typography style={{ position:"absolute", top: "-20px" }}>Imagen sede</Typography><br></br>
                <img  style={{ height:"100px", marginTop: "10px" }} alt="detailImage" src={process.env.REACT_APP_RESOURCES_BASEURL + toURL(sedeSubject.newSalon.imagen)}></img>
              </div>
            }
          </div>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%" }} style={{ marginTop:"1px" , marginBottom: "20px" }}>
          <div className="container" style={{ display: "flex", flexDirection:"row", gap:"20px" }}>
            <div class="input-wrapper" style={{ position:"relative" }}>
              <Typography style={{ position:"absolute", top: "-20px" }}>Imagen card</Typography><br></br>
              <UploadImg
               onChange={(file) => {
                sedeSubject.selectedImageForClub = file;
              }}
                // value={sedeSubject.newSalon.cardImagen && toURL(sedeSubject.newSalon.cardImagen)}
                infoImg={{ format: 'JPG/PNG', resolution: '255 X 280' }}
                style={{ height: "1px", width: "100%" }}
              />
              <p style={{ fontSize: "8px", textAlign:"center" }}>Tamaño recomandado: Hasta 500 KB</p>
            </div>
            <div class="input-wrapper" style={{ position:"relative" }}>
              <Typography style={{ position:"absolute", top: "-20px" }}>Imagen sede</Typography><br></br>
              <UploadImg
                onChange={(file) => {
                  sedeSubject.seletedDetailImageForClub = file;
                }}
                // value={sedeSubject.newSalon.imagen && toURL(sedeSubject.newSalon.imagen)}
                infoImg={{ format: 'JPG/PNG', resolution: '430 X 250' }}
                style={{ height: "1px", width: "100%" }}
              />
              <p style={{ fontSize: "8px", textAlign:"center" }}>Tamaño recomandado: Hasta 500 KB</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"-10px" }}>
          <Grid container item xs={10} style={{ marginBottom: "5px" }}>
            <Typography>Nombre:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Nombre (máx. 50 caract.)"}
            backgroundcolor={nombreError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue={sedeSubject.newSalon.nombre}
            onChange={(e) => {
              sedeSubject.newSalon.nombre = e.target.value;
            }}
          />
        </Grid>
        {nombreError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"20px", marginBottom:"-40px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"10px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Dirección:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Dirección"}
            backgroundcolor={direccionError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.direccion
            }
            border={"red"}
            onChange={(e) => {
              sedeSubject.newSalon.direccion = e.target.value;
            }}
          />
        </Grid>
        {direccionError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}  
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Latitud:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Latitud"}
            backgroundcolor={latitudError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.latitud
            }
            onChange={(e) => {
              sedeSubject.newSalon.latitud = e.target.value;
            }}
          />
        </Grid>
        {latitudError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )} 
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Longitud:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Longitud"}
            backgroundcolor={longitudError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.longitud
            }
            onChange={(e) => {
              sedeSubject.newSalon.longitud = e.target.value;
            }}
          />
        </Grid>
        {longitudError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"25px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Horarios:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Horarios"}
            backgroundcolor={horarioError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.schedule
            }
            onChange={(e) => {
              sedeSubject.newSalon.schedule = e.target.value;
            }}
          />
        </Grid>
        {horarioError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"25px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Correo:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Correo"}
            backgroundcolor={emailError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.email
            }
            onChange={(e) => {
              sedeSubject.newSalon.email = e.target.value;
            }}
          />
        </Grid>
        {emailError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Teléfono:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Télefono"}
            backgroundcolor={telefonoError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.telefono
            }
            onChange={(e) => {
              sedeSubject.newSalon.telefono = e.target.value;
            }}
          />
        </Grid>
        {telefonoError && (
          <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"40px", marginBottom:"-55px" }}>
            <span style={{ fontSize: "9px", color: "red" }}>El campo no puede estar vacio</span>
          </Grid>
        )}  
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
            <Typography>Texto descarga de grilla:</Typography>
          </Grid>
          <CustomTextField
            placeholder={"Texto descarga de grilla"}
            backgroundcolor={"white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.textoDescargaGrilla
            }
            onChange={(e) => {
              sedeSubject.newSalon.textoDescargaGrilla = e.target.value;
            }}
          />
        </Grid>
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"35px" }}>
          <Grid container item xs={10} style={{ marginTop: "20px", marginBottom: "5px" }}>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <ThemedButton handler={!loading ? handlePut : null}>
          {!loading ? "GUARDAR" : "GUARDANDO..."}
        </ThemedButton>
      </Grid>

      <Grid item xs={1} sx={{ mt: 1 }}>
        <ThemedButton
          handler={() => navigateOnControls("viewer")}
          background="transparent"
          border="2px solid red"
          color={"red"}
        >
          CANCELAR
        </ThemedButton>
      </Grid>
    </>
  );
};
export default EditControls;