import {Button, styled} from "@mui/material";
import Theme from "../../../../css/Theme";
import * as React from "react";

const ThemedButtonUnselected = styled(Button)(() => ({
  background: Theme.palette.lightGray.main,
  width: '40px',
  height: '40px',
  marginLeft: '15px',
  minWidth: '40px',
  borderBottom: Theme.palette.primary.main,
  borderStyle: 'solid',
  borderWidth: '1.5px',
  borderRadius: '7px',
  '&:hover': {
    background: Theme.palette.gradient.main,
    width: '50px',
    height: '50px',
    marginLeft: '15px',
    minWidth: '50px',
    borderRadius: '7px',
    borderStyle: 'none'
  }
}));

const ThemedMenuButtonSelected = (props) => {
  return (
    <ThemedButtonUnselected onClick={props.onClick} onMouseOver={props.onMouseOver}>
      {props.children}
    </ThemedButtonUnselected>
  );
};

export default ThemedMenuButtonSelected