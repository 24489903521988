import React from "react";
import {Document, Page, Text, View, StyleSheet} from "@react-pdf/renderer";


const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
  },
  tableRowHeader: {
    margin: "auto",
    flexDirection: "row",
    marginBottom: 5
  },
  tableRow: {
    margin: "auto",
    marginBottom: 5,
    marginTop: 5,
    flexDirection: "row",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderColor: "#d3d3d3"
  },
  tableCol: {
    width: "25%",
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
  tableCellHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12
  },
  infoContainer: {
    marginBottom: 15,
    marginLeft: 20,
    fontSize:15
  },
  titleContainer: {
    margin: "auto",
    marginTop: 0,
    marginBottom: 10,
    fontSize:20
  },
  bottomMargin: {
    height: 25
  },
  topMargin: {
    top:0,
    height: 25,
  }
});

const BookingPDF = ({club, discipline, selectedClass, bookings}) => {
  let table = (
    bookings.map(
      booking =>{
        return (
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{booking.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{booking.dni}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> </Text>
            </View>
          </View>
        );
      }
    )
  );

  return (
    <Document>
      <Page style={styles.body}>
        <View fixed style={styles.topMargin}/>
        <View style={styles.titleContainer}>
          <Text>Lista de asistencia</Text>
        </View>
        <View fixed style={styles.infoContainer}>
          <Text>{"Sede: "+ club}</Text>
          <Text>{"Diciplina: " + discipline}</Text>
          <Text>{selectedClass}</Text>
            </View>
        <View style={styles.table}>
          <View fixed style={styles.tableRowHeader}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Nombre</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>DNI</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Asistencia</Text>
            </View>
          </View>
          {table}
        </View>
        <View fixed style={styles.bottomMargin}/>
      </Page>
    </Document>
  );
};

export default BookingPDF;