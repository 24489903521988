import { differenceInMinutes } from 'date-fns'
import { toIsoString } from '../../Utils/Utils'

class SaveLessonModel {
  startDate = null
  endDate = null
  activityId = null
  clubId = null
  salonId = null
  quota = 0
  coachId = null
  days = ''
  unlimitedCapacity = false
  status = false
  duration = 0

  constructor(lessonTemplate, serieFlag) {
    let daysOfWeek = [
      { name: 'Monday', n: 1 },
      { name: 'Tuesday', n: 2 },
      { name: 'Wednesday', n: 3 },
      { name: 'Thursday', n: 4 },
      { name: 'Friday', n: 5 },
      { name: 'Saturday', n: 6 },
      { name: 'Sunday', n: 0 },
    ]

    let days = lessonTemplate.days.map((element) => {
      let day = daysOfWeek.find((elem) => elem.n === element)
      return day.name
    })

    this.startDate = toIsoString(lessonTemplate.start)
    this.endDate = serieFlag ? toIsoString(lessonTemplate.end) : null
    this.activityId = lessonTemplate.activityId
    this.clubId = lessonTemplate.clubId
    this.quota = lessonTemplate.quota
    this.coachId = lessonTemplate.coachId
    this.salonId = lessonTemplate.salonId
    this.days = serieFlag ? days.toString() : null
    this.unlimitedCapacity = lessonTemplate.unlimitedCapacity
    this.status = lessonTemplate.status
    this.duration = calculateDuration(lessonTemplate)
  }
}

const calculateDuration = (lessonTemplate) => {
  let start = lessonTemplate.start
  let end = new Date(start)
  end.setHours(lessonTemplate.end.getHours())
  end.setMinutes(lessonTemplate.end.getMinutes())

  return differenceInMinutes(end, start)
}

export default SaveLessonModel
