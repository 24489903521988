import moment from "moment";
import classService from "../http/services/ClassService";

class ClassSubject {
  selectedPage = "viewer";
  observers = [];
  clubs = [];
  classes = [];
  sedes = [];
  activities = [];
  coaches = [];
  bookings = [];
  filteredBookings = [];

  selectedClub = null;
  selectedActivity = null;
  selectedClass = null;
  selectedClubText = "";
  selectedActivityText = "";
  selectedClassText = "";

  async getClubs() {
    this.activities = [];
    this.classes = [];
    this.bookings = [];
    this.filteredBookings = [];

    this.selectedClass = null;
    this.selectedActivity = null;

    await classService.getClubs().then((response) => {
      this.sedes = [...response.data.resultado].filter(
        (elem) => elem.estatus === 1
      );
      this.activities = [];
      let userClubId = localStorage.getItem("club");
      this.selectedClub = userClubId !== "0" ? parseInt(userClubId) : "Sede";
      if (this.selectedClub !== "Sede") {
        classService.getActivities(this.selectedClub).then((response) => {
          this.activities = [...response.data.resultado];
          this.notify();
        });
      }
      this.notify();
    });
  }

  async getCoaches() {
    await classService.getCoaches().then((response) => {
      this.coaches = [...response.data.resultado];
      this.notify();
    });
  }

  async getActivities(idClub) {
    this.selectedClub = idClub;
    this.activities = [];
    this.classes = [];
    this.bookings = [];
    this.filteredBookings = [];

    this.selectedClass = null;

    let club = this.sedes.find((e) => e.idClub === idClub);
    this.selectedClubtext = club.nombre;

    await classService.getActivities(idClub).then((response) => {
      if (response.data.codigo !== 0)
        alert("Solo puede reservar clases dentro de su sede");
      else this.activities = [...response.data.resultado];
      this.notify();
    });
  }

  async getClasses(idActivity) {
    this.selectedActivity = idActivity;
    this.classes = [];
    this.filteredBookings = [];
    this.bookings = [];

    let activity = this.activities.find((e) => e.id === idActivity);
    this.selectedActivityText = activity.nombre;

    await classService
      .getClasses(this.selectedClub, idActivity)
      .then((response) => {
        this.classes = response.data.resultado.map((c) => {
          let coachName = this.coaches.find(
            (elem) => elem.coachId === c.idUsuarioCoach
          );

          if (coachName) c.coach = coachName.nombre;
          else c.coach = "Staff Megatlon";
          console.log(c.fhClase);
          c.title =
            "Fecha: " +
            this.setFormattedDate(c.fhClase) +
            ", Profe: " +
            c.coach;

          return c;
        });
        this.notify();
      });
  }

  async getBookings(idClass) {
    this.selectedClass = idClass;

    let clase = this.classes.find((e) => e.idClase === idClass);
    this.selectedClassText = clase.title;

    await classService.getBooking(idClass).then((response) => {
      this.bookings = response.data.resultado.map((c) => {
        c.isBooked = true;
        c.iconColor = "grey";
        if (c.redemptionDate == null) {
          c.isBooked = false;
          c.iconColor = "green";
        }
        return c;
      });
      this.filteredBookings = this.bookings;
      this.notify();
    });
  }

  async putBookClass(idBook) {
    await classService.putBooking(idBook).then((response) => {
      if (response.data.resultado) {
        this.getBookings(this.selectedClass);
      }
      this.notify();
    });
  }

  async getSelectedActivityText() {
    let activity = this.activities.filter(
      (e) => e.id === this.selectedActivity
    );
    return activity[0].nombre;
  }

  /**
   *  Adds an observer function to the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  addObservers(o) {
    this.observers.push(o);
  }
  /**
   * Removes an observer function from the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  removeObservers(o) {
    let index = this.observers.indexOf(o);
    this.observers.splice(index, 1);
  }
  /**
   *  Runs all the observer functions in the array of observers.
   */
  notify() {
    this.observers.forEach((element) => element());
  }
  /**
   * Filters the array of notificationsPush by name. If the input value is empty it sets the filterd array to equal the notificationsPush array
   * @param {Input event} e
   */
  searchHandler(e) {
    let arr = [...this.bookings];
    let inputText = e.target.value.toUpperCase();

    let filtered = arr.filter((elem) => {
      let name = elem.name.toUpperCase();
      return name.includes(inputText);
    });

    this.filteredBookings = [...filtered];

    if (e.target.value === "") {
      this.filteredBookings = [...this.bookings];
    }

    this.notify();
  }

  setFormattedDate = (dateString) => {
    dateString = dateString.replace("T", " ");
    dateString = dateString.replace("Z", "");
    return (
      moment(new Date(dateString)).format("DD.MM.YYYY - HH:mm").toString() +
      " hs"
    );
  };
}
const classSubject = new ClassSubject();
export default classSubject;
