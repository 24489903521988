import { Divider, Grid, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import theme from '../../../css/Theme'
import CustomAccordion from '../../../shared/Components/CustomAccordion/CustomAccordion'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined'
import { ThemedCheckBox } from '../../../shared/Components/CustomCheckbox/CustomCheckbox'
import Theme from '../../../css/Theme'

const SectionList = () => {
  const [sections, setSections] = useState(onDemandSubject.filteredSection)
  const [expanded, setExpanded] = useState(false)

  const onSectionsUpdate = () => {
    setSections(onDemandSubject.filteredSection)
  }

  useEffect(() => {
    onDemandSubject.addSearchObserver(onSectionsUpdate)
    return () => onDemandSubject.removeSearchObserver(onSectionsUpdate)
  }, [])

  return (
    <Grid>
      <CustomAccordion
        key={0}
        page={'sections'}
        id={`mainSection-0`}
        infoObj={onDemandSubject.mainSection}
        expanded={expanded}
        setExpanded={setExpanded}
        subject={sections}
        borderRadius={'10px'}>
        <MainSectionSummary value={onDemandSubject.mainSection} />
      </CustomAccordion>
      <Divider
        sx={{
          color: 'transparent',
          borderTop: `1px dashed ${theme.palette.darkGray.main}`,
          marginBottom: '4px',
        }}></Divider>
      {sections &&
        sections.map((element, index) => (
          <CustomAccordion
            key={index}
            page={'sections'}
            id={`sections-${index}`}
            infoObj={element}
            expanded={expanded}
            setExpanded={setExpanded}
            subject={sections}>
            <CommonSectionSummary value={element} />
          </CustomAccordion>
        ))}
    </Grid>
  )
}
export default SectionList

const MainSectionSummary = ({ value }) => {
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={0.5}>
          <HouseOutlinedIcon sx={{ p: '0 2px' }}></HouseOutlinedIcon>
        </Grid>
        <Grid item xs={5}>
          <Typography noWrap sx={{ fontWeight: 'bolder' }}>
            {value.name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" color="black" sx={{ width: '1px', height: '30px' }} />
        </Grid>
        <Grid item container xs={3}>
          {value.config && value.config.published && (
            <Typography sx={{ color: theme.palette.darkerTransparentGray.main }}>Activo</Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}

const CommonSectionSummary = ({ value }) => {
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={0.5}>
          <ThemedCheckBox sx={{ width: '20px' }} borderColor={Theme.palette.darkerGray.main} />
        </Grid>
        <Grid item xs={5}>
          <Typography noWrap sx={{ fontWeight: 'bolder' }}>
            {value.name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" color="black" sx={{ width: '1px', height: '30px' }} />
        </Grid>
        <Grid item container xs={3}>
          {value.config && value.config.published && (
            <Typography sx={{ color: theme.palette.darkerTransparentGray.main }}>Activo</Typography>
          )}
        </Grid>
      </Grid>
    </>
  )
}
