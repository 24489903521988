import pushNotificationSubject from '../../subjects/PushNotificationsSubject'
import PushCreateViewer from './PushViewer/PushCreateViewer'
import { PushViewer } from './PushViewer/PushViewer'
import { useState, useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import CustomTextField from '../../shared/Components/CustomTextField/CustomTextField'
import theme from '../../css/Theme'

/**
 * Renders a component according to the selected page variable from the push notification subject.
 * @returns
 */
const PushNotificationViewer = () => {
  const [page, setPage] = useState(pushNotificationSubject.selectedPage)
  /**
   * Updates the local page variable to the page variable from the push notification subject.
   */
  const onPageChange = () => {
    setPage(pushNotificationSubject.selectedPage)
  }
  /**
   * Subscribes the component to the push notification subject.
   */
  useEffect(() => {
    pushNotificationSubject.addObservers(onPageChange)
    return () => pushNotificationSubject.removeObservers(onPageChange)
  })
  /**
   *  Switches the component accordingt to the page variable
   * @returns a component
   */
  const renderPage = () => {
    switch (page) {
      case 'viewer':
        return <PushViewer />
      case 'create':
        return <PushCreateViewer />
      default:
        return
    }
  }

  return (
    <>
      <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
        <Grid container item xs={7} direction="column">
          <Typography>Notificaciones</Typography>
          <Typography variant="h4">Push</Typography>
        </Grid>
        <Grid container item xs={4} direction="column">
          {page === 'viewer' && (
            <Grid item xs={6}>
              <CustomTextField searchbutton={1} onChange={(e) => pushNotificationSubject.searchHandler(e)} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={0.25}>
        <Divider orientation="horizontal" color={theme.palette.primary.main} />
      </Grid>
      {renderPage()}
    </>
  )
}
export default PushNotificationViewer
