export class HomeSectionTemplate {
  name = ''
  description = ''
  viewType = ''
  image = ''
  enabled = false
  order = 0
  action = ''
  cards = []
}
