import React, { useCallback, useEffect, useState } from 'react'
import classes from './CalendarComponent.module.css'
import calendarSubject from '../../../subjects/CalendarSubject'
import Theme from '../../../css/Theme'
import styled from '@emotion/styled'
import { add, isPast } from 'date-fns'
import { ThemedCheckBox } from '../../../shared/Components/CustomCheckbox/CustomCheckbox'
import LessonTemplate from '../../../subjects/models/LessonTemplate'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import { Divider, FormControlLabel, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Box } from '@mui/system'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CalendarPicker from '@mui/lab/CalendarPicker'
import CustomAutocomplete from '../../../shared/Components/CustomAutocomplete/CustomAutocomplete'
import { DesktopDatePicker } from '@mui/lab'
import CustomTimePicker from '../../../shared/Components/CustomTimePicker/CustomTimePicker'
import theme from '../../../css/Theme'
import Spinner from '../../UI/Spinner/Spinner'
import { es } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'

/**
 * Local grid customization
 */
const ThemedBox = styled(Box)(() => ({
  color: Theme.palette.darkOrange.main,
  borderRadius: '6px',
  border: '1px solid red',
  padding: '2% 0%',
  width: '100%',
  textAlign: 'center',

  '& span': { textTransform: 'capitalize', fontWeight: 'bold' },
}))

/**
 * Local Paper customization
 */
const ThemedDatePaper = styled(Paper)(() => ({
  background: 'none',
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  height: '2vh',
  boxShadow: 'none',
  padding: '0%',
  paddingBottom: '10%',
}))

/**
 * Local TextField customization
 */
const ThemedTextField = styled(TextField)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiOutlinedInput-input': {
    textAlign: 'right',
  },
  boxShadow: 'none',
  padding: '0%',
  paddingBottom: '10%',
}))

/**
 * Local CalendarPicker Customization
 */
const ThemedCalendarPicker = styled(CalendarPicker)(() => ({
  '&.MuiCalendarPicker-root': {
    background: 'white',
    borderRadius: '5px',
    height: '90%',
    width: '100%',
    margin: '0%',
  },
  '.MuiPickersDay-today': {
    borderColor: 'transparent',
  },
  '.Mui-selected': {
    background: theme.palette.gradient.main,
    color: 'white',
    borderRadius: '0px',
    fontWeight: 'bold',
  },
  '.Mui-selected &:hover': {},
  div: {
    overflow: 'hidden',
  },
}))

/**
 *
 * @param {Component} children react component
 * @param {Date} time set time by default
 * @param {Function} setTime set the state of time
 * @returns Grid item
 */
const CustomLableInput = ({ children, time, value, lable }) => {
  const handleTimeChange = useCallback(
    (e) => {
      calendarSubject.saveTime(time, e)
    },
    [time]
  )

  return (
    <>
      <Grid item xs={6}>
        <Typography className={classes.Lables}>{lable}</Typography>
      </Grid>
      <Grid item container xs={4.25}>
        {value && (
          <FormControl sx={{ width: '100%' }}>
            <CustomTimePicker value={value[time]} handler={handleTimeChange} />
          </FormControl>
        )}
        {children}
      </Grid>
    </>
  )
}

/**
 * Component used as form for create, modify and delete events from the scheduler
 * @returns CalendarComponent component
 */
export default function CalendarComponent() {
  const [selectedDay, setSelectedDay] = useState(calendarSubject.getSelectedDay())
  const [checkboxClicked, setCheckboxClicked] = useState(calendarSubject.selectedDays)
  const [timePicked, setTimePicked] = useState(calendarSubject.selectedTime)
  const [peopleLimit, setPeopleLimit] = useState(calendarSubject.selectedMaxQuota)
  const [coachName, setCoachName] = useState(calendarSubject.selectedCoach)
  const [salonId, setSalonId] = useState(calendarSubject.selectedSalon)
  const [allYear, setAllYear] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [entireSeries, setEntireSeries] = useState(false)
  const [coaches, setCoaches] = useState(calendarSubject.coaches)
  const [salons, setSalons] = useState(calendarSubject.salons)
  const [status, setStatus] = useState(calendarSubject.selectedStatus)
  const [capacity, setCapacity] = useState(calendarSubject.unlimitedCapacity)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setAllYear(false)
    setEntireSeries(false)
    setEndDate(selectedDay ? selectedDay.end : null)
  }, [selectedDay])

  /**
   * Function to calculate if the selecting date is within 48 hours.
   * @returns true if the selected day is within the next 48 hours from now. Otherwise, returns false
   */
  // const isWithin48 = () => {
  //   if (compareAsc(selectedDay.start, add(new Date(), { days: 2 })) < 0) return true
  //   return false
  // }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onSelectedDaysChange = () => {
    setCheckboxClicked(calendarSubject.selectedDays)
  }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onDayUpdate = () => {
    setSelectedDay(calendarSubject.getSelectedDay())
    setCoachName(calendarSubject.selectedCoach)
    setSalonId(calendarSubject.selectedSalon)
    setPeopleLimit(calendarSubject.selectedMaxQuota)
  }

  /**
   * Update function for Subject. An observer created for the states of this component
   */
  const onTimeUpdate = () => {
    setTimePicked(calendarSubject.selectedTime)
  }

  const onCoachListUpdate = () => {
    setCoaches(calendarSubject.coaches)
  }

  const onSalonListUpdate = () => {
    setSalons(calendarSubject.salons)
  }

  const onStatusUpdate = () => {
    setStatus(calendarSubject.selectedStatus)
  }

  const onCapacityUpdate = () => {
    setCapacity(calendarSubject.unlimitedCapacity)
  }

  /**
   * UseEffect that adds and removes the observers from the subject.
   */
  useEffect(() => {
    calendarSubject.addObserver(onDayUpdate)
    calendarSubject.addObserver(onSelectedDaysChange)
    calendarSubject.addObserver(onTimeUpdate)
    calendarSubject.addObserver(onCoachListUpdate)
    calendarSubject.addObserver(onSalonListUpdate)
    calendarSubject.addObserver(onStatusUpdate)
    calendarSubject.addObserver(onCapacityUpdate)
    return () => {
      calendarSubject.removeObserver(onDayUpdate)
      calendarSubject.removeObserver(onTimeUpdate)
      calendarSubject.removeObserver(onSelectedDaysChange)
      calendarSubject.removeObserver(onCoachListUpdate)
      calendarSubject.removeObserver(onSalonListUpdate)
      calendarSubject.removeObserver(onStatusUpdate)
      calendarSubject.removeObserver(onCapacityUpdate)
    }
  }, [])

  let weekday
  let month
  if (selectedDay) {
    weekday = selectedDay.start.toLocaleString('es-ar', { weekday: 'long' }) || null
    month = selectedDay.start.toLocaleString('es-ar', { month: 'short' }) || null
  }

  const daysOfTheWeek = [
    { name: 'L', n: 1 },
    { name: 'M', n: 2 },
    { name: 'M', n: 3 },
    { name: 'J', n: 4 },
    { name: 'V', n: 5 },
    { name: 'S', n: 6 },
    { name: 'D', n: 0 },
  ]

  /**
   * Function that validates the form and, if it is completed correctly, sends all data to the subject to save the object.
   */
  const handleEvent = async () => {
    if (isPast(selectedDay.start)) {
      alert('No puede crear un evento en el pasado')
    } else if (endDate < selectedDay.start) {
      alert('La fecha de fin no puede ser menor a la fecha de inicio')
    } else if (timePicked.start > timePicked.end) {
      alert('Hora de fin de actividad no valida')
    } else if (peopleLimit < 0) {
      alert('El cupo no puede ser negativo')
    } else if (coachName === null) {
      alert('Debe seleccionar al menos un coach')
    } else if (salonId === null) {
      alert('Debe seleccionar un salon')
    } else if (calendarSubject.selectedClub === null || calendarSubject.selectedClub.length === 0) {
      alert('Debe seleccionar una sede')
    } else if (calendarSubject.selectedActivity === null || calendarSubject.selectedActivity.length === 0) {
      alert('Debe seleccionar una actividad')
    } else if (peopleLimit === 0 && capacity === false) {
      alert('Debe indicar el cupo')
    } else {
      let startDay = new Date(selectedDay.start)
      startDay.setHours(timePicked.start.getHours())
      startDay.setMinutes(timePicked.start.getMinutes())
      startDay.setSeconds(0)
      let startDate = startDay
      let endDay = new Date(selectedDay.end)
      endDay.setHours(timePicked.end.getHours())
      endDay.setMinutes(timePicked.end.getMinutes())

      let endTime = endDay

      const calculateRestOfWeek = () => {
        if (!allYear) return add(endTime, { days: 7 })
        else return add(endDate, { days: 1 })
      }
      setIsLoading(true)
      await calendarSubject.handleSaveEvent(startDate, calculateRestOfWeek(), selectedDay.id, allYear)
      setIsLoading(false)
      calendarSubject.cleanSelectedDay()
      setAllYear(false)
      setEndDate()
      setEntireSeries(false)
    }
  }
  /**
   * Function to erase all fields from the form
   */
  const handleCancel = () => {
    calendarSubject.cleanSelectedDay()
    setAllYear(false)
    setEndDate()
    setEntireSeries(false)
  }

  /**
   * Function to handle modifications in an event
   */
  const handleModifyEvent = async () => {
    setIsLoading(true)
    await calendarSubject.modifyEvent(selectedDay.id, endDate, entireSeries)
    setIsLoading(false)
    setAllYear(false)
    setEndDate()
    setEntireSeries(false)
  }

  /**
   * Function to handle a deletion of an event
   * @param {*} id an event id
   */
  const handleRemoveEvent = async (id) => {
    setIsLoading(true)
    await calendarSubject.removeEvent(id, entireSeries)
    setIsLoading(false)
    calendarSubject.cleanSelectedDay()
    setAllYear(false)
    setEndDate()
    setEntireSeries(false)
  }

  /**
   * Updates the SelectedCoaches from the subject
   * @param {*} value returned from the autocomplete when options are selected.
   */
  const handleStaff = (value) => {
    calendarSubject.updateCoaches(value)
  }

  const handleSalon = (value) => {
    calendarSubject.updateSalons(value)
  }

  const handleQuota = (value) => {
    calendarSubject.updateCapacity(value)
  }

  const handleStatus = (value) => {
    calendarSubject.updateStatus(value)
  }

  /**
   *Sets the state of the calander to the selected date.
   * @param {Date} e selected date in calander
   */
  const handleCalendar = async (e) => {
    const event = new LessonTemplate(e)
    event.end = e
    event.days = [e.getDay()]

    if (calendarSubject.dayPicked && calendarSubject.dayPicked.id === null) {
      calendarSubject.showEventPicked(selectedDay.id)
    } else {
      calendarSubject.showDayPicked(event)
    }
    setIsLoading(true)
    await calendarSubject.updateClassesList(e)
    setIsLoading(false)
  }

  return (
    <>
      {isLoading && <Spinner />}
      <Grid
        container
        item
        xs={4}
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{ height: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es} utils={DateFnsUtils}>
          <Box sx={{ marginTop: '1%' }}>
            <ThemedCalendarPicker onChange={(e) => handleCalendar(e)} />
          </Box>
        </LocalizationProvider>
        <Divider
          className={classes.Divider}
          sx={{ marginBottom: '10px', width: '90%', height: '1px', background: ' white' }}
        />
      </Grid>
      <Grid
        container
        item
        xs={8}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{ height: '100%' }}>
        {selectedDay && (
          <>
            <Grid
              container
              item
              direction="column"
              wrap="nowrap"
              justifyContent="space-around"
              alignItems={'center'}
              xs={selectedDay.id ? 5 : 4}
              sx={{ height: '100%', width: '100%' }}>
              <Grid
                item
                container
                direction="column"
                alignItems={'center'}
                xs={selectedDay.id ? 3 : 1}
                sx={{ height: '100%' }}>
                {selectedDay.activityId && (
                  <Typography className={classes.Course} sx={{ fontWeight: '900' }}>
                    {selectedDay.activityId &&
                      calendarSubject.getSelectedActivity(selectedDay.activityId) &&
                      calendarSubject.getSelectedActivity(selectedDay.activityId).name}
                  </Typography>
                )}
                {selectedDay.clubId && (
                  <>
                    <Typography className={classes.Sede}>
                      Sede{' '}
                      <span>
                        {selectedDay.clubId &&
                          calendarSubject.getSelectedClub(selectedDay.clubId) &&
                          calendarSubject.getSelectedClub(selectedDay.clubId).name}
                      </span>
                    </Typography>
                    {selectedDay.id ? <Typography>Modificar Clase</Typography> : <Typography>Crear Clase</Typography>}
                  </>
                )}
                <ThemedBox>
                  <span>{weekday}</span> desde {selectedDay.start.getDate()} de <span>{month}.</span>
                </ThemedBox>
              </Grid>

              <Grid
                item
                container
                direction="column"
                alignItems={'center'}
                xs={2}
                sx={{
                  height: '100%',
                }}>
                {selectedDay.id && selectedDay.serieId && (
                  <FormControlLabel
                    control={
                      <ThemedCheckBox checked={entireSeries ? true : false}>
                        {entireSeries ? (
                          <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                        ) : null}
                      </ThemedCheckBox>
                    }
                    onClick={() => {
                      !entireSeries ? setEndDate() : setEndDate(selectedDay.end)
                      setEntireSeries(!entireSeries)
                      setAllYear(!allYear)
                    }}
                    label="¿Modificar toda la serie?"
                    className={classes.Checkbox}
                  />
                )}

                {!selectedDay.id && (
                  <FormControlLabel
                    sx={{ width: '100%', margin: '2.5% 0' }}
                    control={
                      <ThemedCheckBox checked={allYear ? true : false}>
                        {allYear ? (
                          <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                        ) : null}
                      </ThemedCheckBox>
                    }
                    onClick={() => {
                      setAllYear(!allYear)
                      if (endDate !== null) setEndDate()
                    }}
                    label="Fecha hasta"
                    className={classes.Checkbox}
                  />
                )}
              </Grid>

              {allYear && (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent={'space-evenly'}
                  alignItems="center"
                  className={classes.Input}
                  xs={4}
                  sx={{ height: '100%' }}>
                  <CustomLableInput lable={'Extender hasta'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={es} utils={DateFnsUtils}>
                      <ThemedDatePaper>
                        <DesktopDatePicker
                          size="small"
                          disablePast={true}
                          inputFormat="dd/MM/yyyy"
                          value={endDate}
                          fullWidth={true}
                          onChange={(e) => {
                            setEndDate(e)
                          }}
                          renderInput={(params) => <TextField size="small" {...params} sx={{ fontSize: '8px' }} />}
                        />
                      </ThemedDatePaper>
                    </LocalizationProvider>
                  </CustomLableInput>
                </Grid>
              )}
              {selectedDay.days && (
                <Grid
                  container
                  item
                  justifyContent={'space-evenly'}
                  alignItems="center"
                  xs={3}
                  sx={{ height: '100%', margin: '0% 5%' }}>
                  {daysOfTheWeek.map((element, index) => {
                    let fill = checkboxClicked.includes(element.n)

                    return (
                      <Grid
                        item
                        xs={1}
                        marginTop="15px"
                        onClick={() => {
                          if (!selectedDay.serieId) {
                            calendarSubject.addOrRemoveSelectedDays(element.n)
                          }
                        }}
                        key={index}>
                        <ThemedCheckBox checked={fill ? true : false} id={element.n} size="medium">
                          {element.name}
                        </ThemedCheckBox>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
            </Grid>
            <Grid item container direction="column" xs={selectedDay.id ? 10 : 6} justifyContent="flex-start">
              <Grid item container justifyContent={'space-evenly'} className={classes.Input}>
                <CustomLableInput value={timePicked} time={'start'} lable={'Inicio'}></CustomLableInput>
                <CustomLableInput value={timePicked} time={'end'} lable={'Fin'}></CustomLableInput>

                <Grid container item margin={'10px'} xs={11} direction="row" justifyContent="space-between">
                  <Grid item xs={4}>
                    <Typography className={classes.Lables}>Cupo</Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <ThemedTextField
                      disabled={capacity}
                      id="filled-number"
                      size="small"
                      type="number"
                      value={peopleLimit}
                      onChange={(e) => {
                        calendarSubject.updateMaxQuota(e.target.valueAsNumber)
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid container item xs={5} alignItems="flex-start" justifyContent="flex-end">
                    <FormControlLabel
                      control={
                        <ThemedCheckBox checked={capacity ? true : false}>
                          {capacity ? (
                            <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                          ) : null}
                        </ThemedCheckBox>
                      }
                      onClick={() => {
                        setCapacity(!capacity)
                        calendarSubject.updateMaxQuota(0)
                        handleQuota(!capacity)
                      }}
                      label={'Acceso Libre'}
                      className={classes.Checkbox}
                    />
                  </Grid>
                </Grid>
                
                {/* Selector staff */}
                <Grid item margin={'10px'} xs={11} sx={{ width: '100%' }}>
                  {coaches && (
                    <CustomAutocomplete
                      list={coaches}
                      handler={handleStaff}
                      value={coachName}
                      renderOption={(option) => {
                        let coach
                        if (option && option.id) {
                          coach = calendarSubject.getSelectedCoach(option.id)
                        } else {
                          coach = calendarSubject.getSelectedCoach(option)
                        }
                        if (coach) return coach.name + ' ' + coach.surname
                        else return 'Staff Megatlon'
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value
                      }}
                      placeholder="Staff">
                      <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                    </CustomAutocomplete>
                  )}
                </Grid>
                
                {/* Selector salon */}
                <Grid item margin={'10px'} xs={11} sx={{ width: '100%' }}>
                  {salons && (
                    <CustomAutocomplete
                      list={salons}
                      handler={handleSalon}
                      value={salonId}
                      renderOption={(option) => {
                        let salon = {}
                        if (option && option.id) {
                          salon = calendarSubject.getSelectedSalon(option.id)
                        } else {
                          salon = calendarSubject.getSelectedSalon(option)
                        }
                        if (salon){
                          return salon.name 
                        }
                        else {
                          return ""
                        }
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id
                      }}
                      placeholder="Salones">
                      <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                    </CustomAutocomplete>
                  )}
                </Grid>

                <Grid item margin={'10px'}>
                  <FormControlLabel
                    control={
                      <ThemedCheckBox checked={status ? true : false}>
                        {status ? (
                          <FiberManualRecordIcon sx={{ fontSize: 'x-small' }} className={classes.DotIcon} />
                        ) : null}
                      </ThemedCheckBox>
                    }
                    onClick={() => {
                      setStatus(!status)
                      handleStatus(!status)
                    }}
                    label={'Activo'}
                    className={classes.Checkbox}
                  />
                </Grid>

              </Grid>
            </Grid>
            <Grid item container xs={1} justifyContent={'space-evenly'} alignItems="center" sx={{ marginBottom: '3%' }}>
              <Grid item xs={4} sx={{ height: '100%' }}>
                {!selectedDay.id ? (
                  <ThemedButton handler={handleEvent}>Confirmar</ThemedButton>
                ) : (
                  <ThemedButton handler={handleModifyEvent} open={entireSeries}>
                    {entireSeries ? <Typography>Modificar Serie</Typography> : <Typography>Modificar Clase</Typography>}
                  </ThemedButton>
                )}
              </Grid>
              <Grid item xs={4} sx={{ height: '100%' }}>
                {!selectedDay.id ? (
                  <ThemedButton handler={handleCancel} background={Theme.palette.darkerGray.main}>
                    Cancelar
                  </ThemedButton>
                ) : (
                  <ThemedButton
                    handler={() => handleRemoveEvent(selectedDay.id)}
                    background={Theme.palette.darkerGray.main}
                    open={entireSeries}>
                    {entireSeries ? <Typography>Eliminar Serie</Typography> : <Typography>Eliminar Clase</Typography>}
                  </ThemedButton>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
