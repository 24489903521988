import { add, endOfWeek, isPast, startOfWeek, sub } from 'date-fns'
import SaveLessonModel from '../http/requestModels/SaveLessonModel'
import UpdateLessonModel from '../http/requestModels/UpdateLessonModel'
import calendarService from '../http/services/CalendarService'
import LessonTemplate from './models/LessonTemplate'

class CalendarSubject {
  events = []
  schedulerTime = new Date()
  dayPicked = null
  eventPicked = null
  observers = []
  selectedDays = []
  selectedActivity = null
  selectedClub = null
  selectedTime = { start: null, end: null }
  selectedSalon = null
  selectedCoach = null
  selectedMaxQuota = 0

  sedes = []
  activities = []
  coaches = []
  salons = []

  unlimitedCapacity = false
  selectedStatus = false

  /**
   * Adds an observer function to the array of observers
   * @param {*} observer Observer function created in the calendar components
   */
  addObserver(observer) {
    this.observers.push(observer)
  }

  /**
   * Adds an observer functin to the array of observers
   * @param {*} observer Observer function created in the calendar components
   */
  removeObserver(observer) {
    let index = this.observers.indexOf(observer)
    this.observers.splice(index, 1)
  }

  /**
   * Runs all the observer functions in the array of observers.
   */
  notify() {
    this.observers.forEach((element) => element())
  }

  async getClasses(start, end) {
    return await calendarService.getClasses(
      this.selectedClub,
      this.selectedActivity,
      this.coaches,
      startOfWeek(start, { weekStartsOn: 0 }).toISOString(),
      endOfWeek(end, { weekStartsOn: 0 }).toISOString()
    )
  }

  async updateClassesList(date) {
    this.events = await this.getClasses(date, date)
    this.notify()
  }

  async getClubs() {
    await calendarService.getClubList().then((response) => {
      this.sedes = [...response]
      
      Promise.all([
        calendarService.getActivitiesList(this.sedes[0].id), 
        calendarService.getCoachList(),
        calendarService.getSalonsList()
      ])
        .then(async (data) => {
          this.activities = [...data[0]]
          this.coaches = [...data[1]]
          this.salons = [...data[2]].filter((elem) => elem.active)

          this.selectedClub = this.sedes[0].id
          this.selectedActivity = this.activities[0].id

          this.events = await this.getClasses(new Date(), new Date())
        })
        .finally(() => {
          this.notify()
        })
    })
  }

  async getActivities(clubId) {
    this.selectedActivity = null
    let data = await calendarService.getActivitiesList(clubId)
    this.activities = [...data]
    if (this.activities.length > 0) this.selectedActivity = this.activities[0].id
    else this.selectedActivity = null
    this.notify()
  }

  /**
   * Function to get the correct data between an event or a day without one.
   * @returns data about an empty slot in the scheduler or data about an event, if exists.
   */
  getSelectedDay() {
    if (this.dayPicked) {
      return this.dayPicked
    }
    if (this.eventPicked) {
      return this.eventPicked
    }
    return null
  }

  initializeSchedulerTime() {
    this.schedulerTime = new Date()
    this.notify()
  }

  /**
   * Function to clean all fields in the forms.
   */
  cleanSelectedDay() {
    this.dayPicked = null
    this.eventPicked = null
    this.selectedDays = []
    this.selectedCoach = null
    this.selectedSalon = null
    this.selectedMaxQuota = 0
    this.unlimitedCapacity = false
    this.selectedStatus = false
    this.notify()
  }

  /**
   * Fills every variable in the subject that is used to update the needed states in the calendar component
   * or scheduler component to show values of an empty slot.
   * @param {LessonTemplate} LessonTemplate received from the scheduler component or the calendar component when an empty slot was clicked
   */
  showDayPicked(LessonTemplate) {
    this.schedulerTime = LessonTemplate.start
    this.selectedDays = [LessonTemplate.start.getDay()]
    this.initializeTime(LessonTemplate)
    this.selectedCoach = LessonTemplate.coachId
    this.selectedMaxQuota = LessonTemplate.quota
    this.selectedSalon = LessonTemplate.salonId
    this.unlimitedCapacity = LessonTemplate.unlimitedCapacity
    this.selectedStatus = LessonTemplate.status
    this.dayPicked = LessonTemplate
    this.eventPicked = null
    this.notify()
  }

  /**
   * Fills every variable in the subject that is used to update the needed states in the calendar component
   * or scheduler component to show values of a saved event.
   * @param {LessonTemplate} LessonTemplate received from the scheduler component or the calendar component when an event slot was clicked
   */
  showEventPicked(LessonTemplate) {
    this.schedulerTime = LessonTemplate.start
    this.selectedDays = LessonTemplate.days
    this.initializeTime(LessonTemplate)
    this.selectedCoach = LessonTemplate.coachId
    this.selectedMaxQuota = LessonTemplate.quota
    this.selectedActivity = LessonTemplate.activityId
    this.selectedClub = LessonTemplate.clubId
    this.selectedSalon = LessonTemplate.salonId
    this.unlimitedCapacity = LessonTemplate.unlimitedCapacity
    this.selectedStatus = LessonTemplate.status
    this.eventPicked = LessonTemplate
    this.dayPicked = null
    this.notify()
  }

  /**
   * Add the selected days of the week to the selectedDays array.
   * @param {Number} dayNumber
   */
  addDaysToSelectingDaysArray(dayNumber) {
    let arr = [...this.selectedDays]
    arr.push(dayNumber)
    this.selectedDays = [...arr]
  }

  /**
   * Removes the selected days of the week from the selectedDays array
   * @param {Number} dayNumber
   */
  removeDaysToSelectingDaysArray(dayNumber) {
    let arr = [...this.selectedDays]
    let index = arr.indexOf(dayNumber)
    arr.splice(index, 1)
    this.selectedDays = [...arr]
  }

  /**
   * Adds a new event to the events array
   * @param {LessonTemplate} event a LessonTemplate object to add to the Events array.
   */
  addEvent(event) {
    let arr = [...this.events]
    arr.push(event)
    this.events = [...arr]
    this.notify()
  }

  /**
   * Removes an event from the events array. If a lesson was created in the past, it adds an end date to that lesson. If an entire series was selected, it removes all the events from the present.
   * @param {Number} id lesson id
   * @param {Date} endDate date of end
   * @param {boolean} entireSeries removes the rest of the event from the selected day.
   */
  async removeEvent(id, entireSeries) {
    let confirm = window.confirm('¿Está seguro que desea borrar esta clase?')
    if (confirm) {
      let evArr = [...this.events]
      let findOriginalEvent = evArr.find((elem) => elem.id === id)
      if (findOriginalEvent) {
        if (isPast(findOriginalEvent.start)) {
          return alert('No puede eliminar un evento del pasado')
        } else {
          try {
            await calendarService.delete(id, entireSeries)
            await this.updateClassesList(this.schedulerTime)
          } catch (err) {
            console.log(err)
            alert('No se pudo eliminar la clase')
          }
        }
      }
      this.notify()
    }
  }

  /**
   * Modify an event from the events array. If a lesson was created in the past, it modifies all instances of that event from the present . If an entire series was selected, it modifies all the lessons from the present.
   * @param {Number} id lesson id
   * @param {Date} endDate date of end
   * @param {boolean} entireSeries modifies the rest of the event from the selected day.
   */
  async modifyEvent(id, endDate, entireSeries) {
    let arr = [...this.events]
    let selected = arr.find((element) => element.id === id)
    console.log(this.getSelectedDay())
    if (endDate && endDate < this.getSelectedDay().start) {
      return alert('La fecha de fin debe ser mayor a la fecha seleccionada')
    } else if (this.selectedMaxQuota === 0 && this.unlimitedCapacity === false) {
      return alert('Indicar el cupo de la clase')
    } else if (isPast(this.getSelectedDay().start)) {
      return alert('No se puede modificar un evento del pasado')
    } else {
      let event = new LessonTemplate()
      event = { ...selected }
      event.start = new Date(
        selected.start.getFullYear(),
        selected.start.getMonth(),
        selected.start.getDate(),
        this.selectedTime.start.getHours(),
        this.selectedTime.start.getMinutes()
      )

      if (isPast(event.start)) {
        return alert('La nueva fecha de modificación no puede estar ubicada en el pasado.')
      }

      event.coachId = this.selectedCoach
      event.clubId = this.selectedClub
      event.activityId = this.selectedActivity
      event.unlimitedCapacity = this.unlimitedCapacity
      event.quota = this.selectedMaxQuota
      event.status = this.selectedStatus
      event.salonId = this.selectedSalon

      if (entireSeries) {
        let evArr = [...this.events]
        let find = evArr.find((elem) => elem.id === selected.id)
        let endDateFlag = endDate ? add(endDate, { days: 1 }) : find.end
        event.days = find.days
        event.end = new Date(
          endDateFlag.getFullYear(),
          endDateFlag.getMonth(),
          endDateFlag.getDate(),
          this.selectedTime.end.getHours(),
          this.selectedTime.end.getMinutes()
        )
        find.end = event.start
      } else {
        event.end = new Date(
          selected.start.getFullYear(),
          selected.start.getMonth(),
          selected.start.getDate(),
          this.selectedTime.end.getHours(),
          this.selectedTime.end.getMinutes()
        )
        event.days = null
      }
      let request = new UpdateLessonModel(event)
      await calendarService
        .putClass(id, request)
        .then(() => {
          this.cleanSelectedDay()
          this.updateClassesList(this.schedulerTime)
        })
        .catch((err) => {
          console.log(err)
          alert('No se pudo modificar la clase')
        })

      this.notify()
    }
  }

  /**
   * Toggles the checkbox. If there is just one id it sets the state of the calander to the date that is actualy checked. If the array is empty it sets the calendar to todays date.
   * @param {Number} e Number that corresponds with the day of the week
   */
  addOrRemoveSelectedDays(e) {
    let day = this.getSelectedDay()
    let index = this.selectedDays.indexOf(e)
    if (index === -1) {
      this.addDaysToSelectingDaysArray(e)
    } else {
      this.removeDaysToSelectingDaysArray(e)
    }

    if (this.selectedDays.length < 1 && !day.id) {
      day.start = this.schedulerTime
    }

    if (this.selectedDays.length === 1 && !day.id) {
      let temp = new Date(day.start)
      let newDay = temp.getDate() + (this.selectedDays[0] - temp.getDay())
      day.start = new Date(day.start.getUTCFullYear(), day.start.getUTCMonth(), newDay)
    }
    this.notify()
  }

  /**
   * Saves a new event or modifies an existing one.
   * @param {Date} startDate Selected day as the start of the lesson
   * @param {Date} endOfWeek Date corresponding to the selected day plus 7 days
   * @param {Number} id Optional. The lesson's id, if exists.
   */
  async handleSaveEvent(startDate, endOfWeek, id, serieFlag) {
    let newEvent = new LessonTemplate()
    newEvent.start = startDate
    newEvent.end = endOfWeek
    newEvent.end.setHours(this.selectedTime.end.getHours())
    newEvent.end.setMinutes(this.selectedTime.end.getMinutes())
    newEvent.activityId = this.selectedActivity
    newEvent.clubId = this.selectedClub
    newEvent.coachId = this.selectedCoach
    newEvent.salonId = this.selectedSalon
    newEvent.unlimitedCapacity = this.unlimitedCapacity
    newEvent.status = this.selectedStatus
    newEvent.days = this.selectedDays
    newEvent.quota = this.selectedMaxQuota
    let request = new SaveLessonModel(newEvent, serieFlag)

    await calendarService
      .postClass(request)
      .then(() => {
        this.updateClassesList(this.schedulerTime)
      })
      .catch((err) => {
        console.log(err)
        alert('No se pudo guardar la clase')
      })
  }

  /**
   * Saves the received time to the selectedTime value
   * @param {*} time
   * @param {*} value
   */
  saveTime(time, value) {
    let obj = { ...this.selectedTime }
    obj[time] = value
    this.selectedTime = { ...obj }
    //if (time === 'start') this.getSelectedDay().start = value
    this.notify()
  }

  initializeTime(time) {
    let obj = { ...this.selectedTime }
    obj.start = time.start
    obj.end = time.end
    this.selectedTime = { ...obj }
    this.notify()
  }

  /**
   * selectedClub setter
   * @param {Object} club
   */
  async updateClub(club) {
    if (club && club.id) {
      await this.getActivities(club.id)
      this.selectedClub = club.id
      this.notify()
    }
  }

  /**
   * selectedActivity setter
   * @param {Object} activity
   */
  async updateActivity(activity) {
    if (activity && activity.id) {
      this.selectedActivity = activity.id
    }
    this.events = await this.getClasses(new Date(), new Date())
    this.notify()
  }

  /**
   * selectedCoach setter
   * @param {Object} arrayCoaches
   */
  updateCoaches(coach) {
    if (coach && coach.id) {
      this.selectedCoach = coach.id
    }
    this.notify()
  }

  updateSalons(salon) {
    if(salon && salon.id) {
      this.selectedSalon = salon.id
    }
    this.notify()
  }

  updateCapacity(value) {
    this.unlimitedCapacity = value
    this.notify()
  }

  updateStatus(value) {
    this.selectedStatus = value
    this.notify()
  }
  /**
   * selectedMaxQuota setter
   * @param {Number} number
   */
  updateMaxQuota(number) {
    this.selectedMaxQuota = number
    this.notify()
  }

  /**
   * Manage the week that is shown in the scheduler
   * @param {boolean} sum If true, it adds 7 days. Else, it subtracts.
   */
  async updatePaginationSchedule(sum) {
    if (sum) this.schedulerTime = add(this.schedulerTime, { days: 7 })
    else this.schedulerTime = sub(this.schedulerTime, { days: 7 })

    this.events = await this.getClasses(this.schedulerTime, this.schedulerTime)
    this.notify()
  }

  getSelectedCoach = (value) => {
    const found = this.coaches.find((element) => element.id === value)
    if (found) return found
  }

  getSelectedSalon = (value) => {
    const salon = this.salons.find((e) => e.id === value)
    if (salon) {
      return salon
    }
  }

  getSelectedActivity = (value) => {
    const found = this.activities.find((element) => element.id === value)
    if (found) return found
  }

  getSelectedClub = (value) => {
    const found = this.sedes.find((element) => element.id === value)
    if (found) return found
  }

  getUTCDate = (date) => {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  }
}

const calendarSubject = new CalendarSubject()
export default calendarSubject
