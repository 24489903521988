import SaveHomeCardModel from "../http/requestModels/SaveHomeCardModel";
import SaveHomeSectionModel from "../http/requestModels/SaveHomeSectionModel";
import appService from "../http/services/AppService";
import {
  contentPageActionConfig,
  linkActionConfig,
  urlLinkActionConfig,
} from "./models/ActionModels";
import { CardTemplate } from "./models/CardTemplate";
import { HomeSectionTemplate } from "./models/HomeSectionTemplate";

class AppSubject {
  timeout;

  searchObserver = [];

  homeSections = [];
  mainSection = {};
  cards = [];
  newSection = new HomeSectionTemplate();
  observers = [];
  selectedSection = -1
  selectedPage = "homeSections";
  isCreating = false;
  newCard = new CardTemplate();
  selectedBannerForSection = "";
  selectedImageForCard = {
    small: "",
    medium: "",
    content: "",
  };

  selectedCardAction = "page";
  selectedCardActionConfig = new contentPageActionConfig({});

  selectedSectionType = "SMALL";
  selectedSectionAction = "page";
  selectedSectionActionConfig = new contentPageActionConfig({});
  selectedImageForSectionContent = "";

  addedCardsToSection = [];
  enabledSections = [];

  filteredCards = [];

  isScheduled = false;
  dateSelected = new Date();
  selectedTime = { start: new Date() };
  endDateSelected = new Date();
  endDate = { end: this.returnHour() }

  addObserver(observer) {
    this.observers.push(observer);
  }

  removeObserver(observer) {
    let index = this.observers.indexOf(observer);
    this.observers.splice(index, 1);
  }

  returnHour(){
    const currentDate = new Date();
    return currentDate;
  }

  notify() {
    this.observers.forEach((element) => element());
  }

  async updateHomeSectionList() {
    this.cleanSectionResults();
    await Promise.all([
      appService.getHomeSectionList(),
      appService.getHomeSectionList({ enabled: "true" }),
    ]).then(([completeList, enabledSections]) => {
      this.enabledSections = enabledSections.filter(
        (element) => element.id !== 1
      );
      completeList.forEach((element) => {
        if (element.order === 1) {
          this.mainSection = { ...element };
        } else {
          this.homeSections = [...this.homeSections, { ...element }];
        }
      });
    });
    this.updateSelectedPage("homeSections", true);
    this.addedCardsToSection = [];
    this.notify();
  }

  async updateActiveSections() {
    let response = await appService.getHomeSectionList({ enabled: true });
    this.enabledSections = response.filter((element) => element.id !== 1);
  }

  async updateHomeCardList() {
    this.cleanSearchResults();
    let response = await appService.getHomeCardList();
    this.cards = [...response];
    this.filteredCards = await appService.getHomeCardList({ enabled: true });
    this.notify();
  }

  updateSelectedPage(pageName, cleanSelectedItem, element) {
    this.selectedPage = pageName;
    if (this.selectedPage === "createSection" || this.selectedPage === "createCard" || this.selectedPage === "scheduleCard") {
      this.isCreating = true;
    } else {
      this.isCreating = false;
    }
    console.log(appSubject.addedCardsToSection);
    if (cleanSelectedItem === true) {
      this.newSection = new HomeSectionTemplate();
      this.newCard = new CardTemplate();
      this.selectedBannerForSection = "";
      this.selectedImageForSectionContent = "";
      this.selectedImageForCard = { small: "", medium: "" };
      this.addedCardsToSection = [...appSubject.addedCardsToSection];
      this.selectedCardAction = "page";
      this.selectedCardActionConfig = new contentPageActionConfig({});
      this.selectedSectionAction = "page";
      this.selectedSectionActionConfig = new contentPageActionConfig({});
      this.selectedSectionType = "SMALL";
    }
    if(element) {
      this.newCard = element;
    }
    this.notify();
    console.log("Page updated:", this.selectedPage, this.newCard);
  }

  /**
   * Saves the received time to the selectedTime value
   * @param {*} time
   * @param {*} value
   */
  saveTime(time, value) {
    let obj = { ...this.selectedTime };
    obj[time] = value;
    this.selectedTime = { ...obj };
    // //if (time === 'start') this.getSelectedDay().start = value
    this.notify();
  }

  testTime(time, value) {
    let obj = { ...this.endDate };
    obj[time] = value;
    this.endDate = { ...obj };
    this.notify();
  }

  onSelectToModifyCard(id) {
    let arr = [...this.cards];
    let selected = arr.find((element) => element.id === id);
    this.newCard = new CardTemplate(selected);
    this.selectedCardAction = this.newCard.action.type;
    this.selectedCardActionConfig = this.newCard.action;
    this.selectedPage = "createCard";
    this.notify();
  }

  onChangeSelectedType(type) {
    this.selectedSectionType = type;
    this.notify();
  }

  onSectionActionChange(newAction) {
    this.selectedSectionAction = newAction;
    switch (newAction) {
      case "link": {
        this.selectedSectionActionConfig = new linkActionConfig(
          this.newSection.action
        );
        break;
      }
      case "urlLink": {
        this.selectedSectionActionConfig = new urlLinkActionConfig(
          this.newSection.action
        );
        break;
      }
      case "page": {
        this.selectedSectionActionConfig = new contentPageActionConfig(
          this.newSection.action
        );
        break;
      }
      default:
        break;
    }
    this.notify();
  }

  onCardActionChange(newAction) {
    this.selectedCardAction = newAction;
    switch (newAction) {
      case "link": {
        this.selectedCardActionConfig = new linkActionConfig(
          this.newCard.actionConfig
        );
        break;
      }
      case "urlLink": {
        this.selectedCardActionConfig = new urlLinkActionConfig(
          this.newCard.actionConfig
        );
        break;
      }
      case "page": {
        this.selectedCardActionConfig = new contentPageActionConfig(
          this.newCard.actionConfig
        );
        break;
      }
      default:
        break;
    }
    this.notify();
  }

  onCardImageChange = (file) => {
    this.selectedImageForCard = file;
    this.notify();
  };

  async saveCard(id) {
    if (
      this.newCard.title === "" ||
      ((this.selectedCardAction === "link" ||
        this.selectedCardAction === "urlLink") &&
        !this.selectedCardActionConfig.content) ||
      (this.selectedCardAction === "page" &&
        !this.selectedCardActionConfig.content)
    ) {
      alert("Quedan campos sin completar");
    } else {
      let arr = [...this.cards];
      let found = arr.find((element) => element.id === id);
      this.newCard.action = this.selectedCardAction;
      this.newCard.actionConfig = this.selectedCardActionConfig;
      let request = new SaveHomeCardModel(this.newCard);
      request.title = request.title.trim()
      request.subtitle = request.subtitle.trim()
      if (found) {
        let index = arr.indexOf(found);
        try {
          request.images = await this.updateCardImages(found.id);
          this.checkIfHasImage(this.selectedImageForCard.content, found.action.image)
          if (!request.images[0] || request.images[0].viewType !== "SMALL") {
            throw new Error("La card debe tener una imagen de tipo Small");
          }
          if (this.selectedImageForCard.content) {
            request.action.image = this.createImagesRoutes(this.selectedImageForCard, found.id)
          }
          this.deleteButtonCode(request)
          if((request.action.buttonLink || request.action.buttonText)){
              this.validationForMakeButton(request.action.buttonLink, request.action.buttonText)
              var button = this.prepareToMakeButton(request)
              request.action.content = request.action.content + button
          }
          request.action.content = this.checkIfHasEmbedLinks(request.action.content)
          request.action = JSON.stringify(request.action);
          let response = await appService.putHomeCard(found.id, request);
          response.action = JSON.parse(response.action);
          arr[index] = response;
          this.cards = [...arr];
          this.updateSelectedPage("cardList", true);
          this.notify();
        } catch (error) {
          console.log(error);
          alert(error);
        }
      } else {
        try {
          request.action = JSON.stringify(request.action);
          this.checkIfHasImage(this.selectedImageForCard["content"])
          if (!this.selectedImageForCard.small) {
            throw new Error("La card debe tener una imagen de tipo Small");
          }
          const cardResponse = await appService.postHomeCard(request);
          cardResponse.images = await this.saveCardImages(cardResponse.id);
          cardResponse.action = JSON.parse(cardResponse.action);
          if (
            this.selectedCardAction === "page" &&
            this.selectedImageForCard["content"] !== ""
          ) {
            cardResponse.action.image =
              "homecard_content_" +
              cardResponse.id +
              "." +
              this.selectedImageForCard.content.name.split(".").pop();
          }
          cardResponse.action = JSON.stringify(cardResponse.action);
          const responseWithImagesSaved = await appService.putHomeCard(
            cardResponse.id,
            cardResponse
          );
          responseWithImagesSaved.action = JSON.parse(
            responseWithImagesSaved.action
          );
          arr.push(responseWithImagesSaved);
          this.cards = [...arr];
          this.updateSelectedPage("cardList", true);
          this.notify();
        } catch (error) {
          console.log(error);
          if(error){
            alert(error)
          } else {
            alert("No se pudo guardar la card");
          }
        }
      }
    }
  }

  checkIfHasImage(image, savedImage){
    if(!image && !savedImage){
      throw new Error("La card debe tener una imagen")
    }
  }

  validationForMakeButton(buttonLink, buttonText){
    if(buttonLink !== "" && buttonText === ""){
      throw new Error('El botón debe llevar un texto');
    }
    if(buttonLink === "" && buttonText !== ""){
      throw new Error('El botón debe llevar un link');
    }
  }

  prepareToMakeButton(request){
    this.deleteButtonCode(request)
    return this.makebutton(request.action.buttonLink, request.action.buttonText)
  }

  deleteButtonCode(request){
    if(request.action.content.includes("<button")){
      const posicion = request.action.content.indexOf("<button");
      if (posicion !== -1) {
        request.action.content = request.action.content.slice(0, posicion).trim();
      }
    }
  }

  checkIfHasEmbedLinks(content){
    if(content.includes('<figure class="media">')){
      return this.obtainLinks(content)
    } else{
      return content
    }
  }

  obtainLinks(content){
    const urlRegex = /<oembed[^>]+url="([^"]+)"/g;
    const urls = [];
    let match;
    while ((match = urlRegex.exec(content)) !== null) {
      urls.push(match[1]);
    }
    return this.prepareLinks(content, urls)
  }

  prepareLinks(content, urls){
    const links = [];
    for (let index = 0; index < urls.length; index++) {
      const element = `
        <p>
          <a href="${urls[index]}">${urls[index]}</a>
        </p>
      `;
      links.push(element)      
    }
    return this.replaceEmbedForLinks(content, links)
  }

  replaceEmbedForLinks(content,links){
    const figureRegex = /<figure class="media">.*?<\/figure>/gs;
    content = content.replace(figureRegex, (_, index) => {
      return links.shift() || _;
    });
    return content
  }

  makebutton(buttonLink, buttonText){

    if(buttonLink.includes("@") && buttonLink.includes(".com")){
      return `
        <button text="${buttonText}" uri=mailto:"${buttonLink}"></button>
          </p>
        <p>&nbsp;</p>
      `
    } else {
      return `
      <button text="${buttonText}" uri="${buttonLink}"></button>
        </p>\n
        <p>&nbsp;</p>
      `
    }    
  }

  createImagesRoutes(selectedImageForCard, id){
    return  "homecard_content_" + id + "." + selectedImageForCard.content.name.split(".").pop();
  }
  
  dateFormatter(date, timePicked, origin){
    let startDay = new Date(date);
    let year = startDay.getFullYear().toString();
    let month = ("0" + (startDay.getMonth() + 1)).slice(-2);
    let day = ("0" + startDay.getDate()).slice(-2);
    let hours, minutes;
    if (origin === "start") {
      hours = ("0" + timePicked.start.getHours()).slice(-2);
      minutes = ("0" + timePicked.start.getMinutes()).slice(-2);
    } else {
      hours = ("0" + timePicked.getHours()).slice(-2);
      minutes = ("0" + timePicked.getMinutes()).slice(-2);
    }
    let formattedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    return formattedDate;
  }

  async saveCardScheduled(id, dateSelected, timePicked, endDateSelected, timePicked2, action) {
    if (
      this.newCard.title === "" ||
      ((this.selectedCardAction === "link" ||
        this.selectedCardAction === "urlLink"))
    ) {
      alert("Quedan campos sin completar");
    } else {
      let formattedDate = this.dateFormatter(dateSelected, timePicked, "start")
      let formattedDate2 = this.dateFormatter(endDateSelected, timePicked2, "end")
      
      let arr = [...this.addedCardsToSection];
      let found = arr.find((element) => element.id === id);
      let actionCard = JSON.parse(action)
      this.newCard.action = actionCard.type;
      this.newCard.actionConfig = {image: actionCard.image, content: actionCard.content}
      this.newCard.scheduledDate = formattedDate;
      this.newCard.endDate = formattedDate2;
      
      let request = new SaveHomeCardModel(this.newCard, this.selectedSection);
      if (found) {
        let index = arr.indexOf(found);
        try {
          let images = await this.updateCardImages(found.id);
          request.images = images;
          if (this.selectedImageForCard.content) {
            request.action.image =
              "homecard_content_" +
              found.id +
              "." +
              this.selectedImageForCard.content.name.split(".").pop();
          }
          request.action = JSON.stringify(request.action);
          let response = await appService.putHomeCard(found.id, request);
          response.action = JSON.parse(response.action);
          arr[index] = response;
          this.cards = [...arr];
          this.updateSelectedPage("homeSections", true);
          this.notify();
        } catch (error) {
          alert("No se pudo actualizar la card");
        }
      }
    }
  }

  async deleteCard(id) {
    let result = window.confirm("¿Está seguro que desea borrar esta card?");
    if (result === true) {
      let arr = [...this.cards];
      let found = arr.find((element) => element.id === id);
      if (found) {
        try {
          await appService.deleteHomeCard(id);
          let index = arr.indexOf(found);
          arr.splice(index, 1);
        } catch (error) {
          console.log(error);
          alert("No se pudo borrar la card");
        }
      }
      this.cards = [...arr];
      this.notify();
    }
  }

  onSelectItemToModify(item) {
    this.selectedPage = "createSection";
    this.isCreating = true;
    this.newSection = { ...item };
    this.selectedSection = item.id;
    if (item.action) {
      this.selectedSectionActionConfig = item.action;
      this.selectedSectionAction = item.action.type;
    }
    this.selectedSectionType = item.viewType;
    this.selectedBannerForSection = item.image ? item.image : "";
    this.addedCardsToSection = [...item.cards];
    this.notify();
  }

  handleAddCardsToSection = (element) => {
    let arr = [...this.addedCardsToSection].sort((a, b) => a.weight - b.weight);
    let find = arr.find((elem) => element.id === elem.id);
    let index = arr.indexOf(find);
    if (index === -1) {
      element.weight = arr.length + 1;
      arr.push(element);
      this.addedCardsToSection = [...arr];
      this.searchCardsforSections("");
      this.notify();
    }
  };

  handleRemoveCardsToSection = (element) => {
    let arr = [...this.addedCardsToSection];
    let find = arr.find((elem) => elem.id === element.id);
    let index = arr.indexOf(find);
    if (index !== -1) {
      arr.splice(index, 1);
      this.addedCardsToSection = [...arr];
      this.notify();
    }
  };

  saveOrderSection = (newOrderList) => {
    this.addedCardsToSection = [...newOrderList];
    this.notify();
  };

  saveNewSection = async () => {
    if (this.newSection.name === "" || this.newSection.description === "") {
      alert("Quedan campos por completar");
    } else if (
      this.selectedSectionType !== "BANNER" &&
      this.addedCardsToSection.length === 0
    ) {
      alert("La sección debe tener al menos una card");
    } else {
      let selected = [{ ...this.mainSection }, ...this.homeSections];
      let find = selected.find((element) => element.id === this.newSection.id);
      let index = selected.indexOf(find);
      this.newSection.viewType = this.selectedSectionType;
      if (this.newSection.enabled) {
        if (this.newSection.order === 0)
          this.newSection.order = this.enabledSections.length + 2;
      }
      if (this.selectedSectionType === "BANNER") {
        this.newSection.action = Object.assign(
          { type: this.selectedSectionAction },
          this.selectedSectionActionConfig
        );
        this.newSection.cards = [];
      } else {
        this.newSection.cards = [...this.addedCardsToSection];
        this.newSection.action = "";
      }
      let request = new SaveHomeSectionModel(this.newSection);
      if (index !== -1) {
        try {
          request.action = JSON.stringify(request.action);
          let response = await appService.putHomeSection(find.id, request);
          if (this.selectedSectionType === "BANNER") {
            if (this.selectedBannerForSection !== request.image) {
              await appService.saveSectionImage(
                response.id,
                "banner",
                this.selectedBannerForSection
              );
              response.image =
                "homesection_banner_" +
                response.id +
                "." +
                this.selectedBannerForSection.name.split(".").pop();
            }
            if (this.selectedImageForSectionContent !== "") {
              response.action = JSON.parse(response.action);
              await appService.saveSectionImage(
                response.id,
                "content",
                this.selectedImageForSectionContent
              );
              response.action.image =
                "homesection_content_" +
                response.id +
                "." +
                this.selectedImageForSectionContent.name.split(".").pop();
              response.action = JSON.stringify(response.action);
            }
            response = await appService.putHomeSection(response.id, response);
          }
          response.action = JSON.parse(response.action);
          selected[index] = response;
          this.mainSection = selected.splice(0, 1)[0];
          this.homeSections = [...selected];
          await this.updateActiveSections();
          this.updateSelectedPage("homeSections", true);
          this.notify();
        } catch (error) {
          console.log(error);
          alert("No se pudo guardar la seccion.");
        }
      } else {
        try {
          request.action = JSON.stringify(request.action);
          let response = await appService.postHomeSection(request);
          if (this.selectedSectionType === "BANNER") {
            if (this.selectedBannerForSection !== "") {
              await appService.saveSectionImage(
                response.id,
                "banner",
                this.selectedBannerForSection
              );
              response.image =
                "homesection_banner_" +
                response.id +
                "." +
                this.selectedBannerForSection.name.split(".").pop();
            }
            if (this.selectedImageForSectionContent !== "") {
              response.action = JSON.parse(response.action);
              await appService.saveSectionImage(
                response.id,
                "content",
                this.selectedImageForSectionContent
              );
              response.action.image =
                "homesection_content_" +
                response.id +
                "." +
                this.selectedImageForSectionContent.name.split(".").pop();
              response.action = JSON.stringify(response.action);
            }
            response = await appService.putHomeSection(response.id, response);
          }
          response.action = JSON.parse(response.action);
          selected.push(response);
          this.mainSection = selected.splice(0, 1)[0];
          this.homeSections = [...selected];
          this.updateSelectedPage("homeSections", true);
          await this.updateActiveSections();
          this.notify();
        } catch (error) {
          console.log(error);
          alert("No se pudo guardar la seccion.");
        }
      }
    }
  };

  async deleteSection(id) {
    let result = window.confirm("¿Está seguro que desea borrar esta sección?");
    if (result === true) {
      let arr = [...this.homeSections];
      let found = arr.find((element) => element.id === id);
      if (found) {
        try {
          await appService.deleteHomeSection(id);
          let index = arr.indexOf(found);
          arr.splice(index, 1);
          await this.updateActiveSections();
        } catch (error) {
          console.log(error);
          alert("No se pudo borrar la sección");
        }
      }
      this.homeSections = [...arr];
      this.notify();
    }
  }

  reorderSections = async (newOrder) => {
    try {
      await appService.reorderHomeSection(newOrder);
      await this.updateActiveSections();
      this.notify();
    } catch (error) {
      console.log(error);
      alert("Error al ordenar");
    }
  };

  getImage(item, viewType) {
    const find = item.images.find(
      (element) => element.viewType.toUpperCase() === viewType.toUpperCase()
    );
    if (find) return find.image;
    else return "";
  }

  async saveCardImages(id) {
    let types = Object.keys(this.selectedImageForCard);
    let images = [];
    for (let i = 0; i < types.length; i++) {
      if (this.selectedImageForCard[types[i]] !== "") {
        let extension = this.selectedImageForCard[types[i]].name
          .split(".")
          .pop();
        try {
          await appService.saveCardImage(
            id,
            types[i],
            this.selectedImageForCard[types[i]]
          );
          images.push({
            viewType: types[i].toUpperCase(),
            image: `homecard_${types[i].toLowerCase()}_${id}.${extension}`,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    return images.filter(
      (element) => element.viewType.toUpperCase() !== "CONTENT"
    );
  }

  async updateCardImages(id) {
    let uploadedImages = await this.saveCardImages(id);

    let allImages = this.newCard.images.filter((oldImage) => {
      let find = uploadedImages.find(
        (newImage) => oldImage.viewType === newImage.viewType
      );
      return !find;
    });

    allImages = allImages.filter((element) => typeof element !== "undefined");

    return [allImages, ...uploadedImages].flat();
  }

  searchSections = async (keyword) => {
    if (this.timeout) clearTimeout(this.timeout);
    if (keyword === "") {
      let response = await appService.getHomeSectionList();
      this.homeSections = response.filter((element) => element.id !== 1);
      this.notify();
    } else if (keyword.length >= 3) {
      this.timeout = setTimeout(async () => {
        let response = await appService.getHomeSectionList({ name: keyword });
        this.homeSections = response.filter((element) => element.id !== 1);
        this.notify();
      }, 1000);
    }
  };

  cleanSectionResults() {
    this.homeSections = [];
    this.mainSection = null;
    this.notify();
  }

  cleanSearchResults() {
    this.cards = [];
    this.notify();
  }

  searchCards = async (keyword) => {
    if (this.timeout) clearTimeout(this.timeout);
    if (keyword === "") {
      if (this.selectedPage === "cardList") this.cleanSearchResults();
      this.cards = await appService.getHomeCardList();
      this.notify();
    } else if (keyword.length >= 3) {
      this.timeout = setTimeout(async () => {
        if (this.selectedPage === "cardList") this.cleanSearchResults();
        let response = await appService.getHomeCardList({ title: keyword });
        this.cards = [...response];
        this.notify();
      }, 1000);
    }
  };

  searchCardsforSections = async (keyword) => {
    if (keyword === "") {
      this.filteredCards = await appService.getHomeCardList({ enabled: true });
      this.notify();
    } else if (keyword.length >= 2) {
      let response = await appService.getHomeCardList({
        title: keyword,
        enabled: true,
      });
      this.filteredCards = [...response];
      this.notify();
    }
  };
}

const appSubject = new AppSubject();
export default appSubject;
