import resourcesAxiosClient from '../axios/ResourcesAxiosClient'

class ResourcesService {
  baseUrl = '/admin/play'

  postImage(id, imgFile, imageType, contentType) {
    return resourcesAxiosClient.post(`${this.baseUrl}/${contentType}/${id}/image?imageType=${imageType}`, imgFile)
  }
}

const resourceService = new ResourcesService()
export default resourceService
