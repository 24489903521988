import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

const ImageDetails = (props) => {
  const [previewImage, setPreviewImage] = useState()

  useEffect(() => {
    fetch(process.env.REACT_APP_RESOURCES_BASEURL + props.image).then((response) => {
      response.blob().then((res) => {
        setPreviewImage(URL.createObjectURL(res))
      })
    })
    return () => {
      setPreviewImage()
    }
  }, [props.image])

  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      {previewImage && (
        <Box
          component="img"
          sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
          src={previewImage}
          alt="Imagen no disponible."
        />
      )}
    </a>
  )
}

export default ImageDetails
