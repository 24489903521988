import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import LoginPage from '../pages/LoginPage/LoginPage'

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/auth" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  )
}

export default LoginRoutes
