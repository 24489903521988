import { Box } from '@mui/material'
import Theme from '../../../css/Theme'

export const ThemedCheckBox = (props) => {
  let bgColor
  let textColor
  let weight
  let checked = props.checked
  if (checked) {
    bgColor = ' linear-gradient(135deg, #ffa33e 20%, #ff3b4e 90%)'
    textColor = 'white'
    weight = 'bold'
  } else {
    bgColor = 'transparent'
    textColor = Theme.palette.darkGray.main
  }

  const obj = {
    background: bgColor,
    color: textColor,
    minWidth: props.width || '20px',
    height: props.height || '20px',
    lineHeight: props.lineHeight || '20px',
    fontSize: props.fontSize || 'small',
    fontWeight: weight,
    borderRadius: props.borderRadius || '7px',
    borderStyle: props.borderStyle || 'solid',
    borderWidth: props.borderWidth || '1px',
    borderColor: props.borderColor || '#fff',
    marginRight: props.marginRight || '10px',
    cursor: props.cursor || 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <Box disabled sx={{ ...obj, ...props.sx }}>
      {props.children}{' '}
    </Box>
  )
}
