import { useContext } from 'react'
import React from 'react'
import Layout from './components/Layout/Layout'
import AppContext from './store/app-context'
import Spinner from './components/UI/Spinner/Spinner'
import './App.css'
import ClubRoutes from './routes/ClubRoutes'
import AdminRoutes from './routes/AdminRoutes'
import LoginRoutes from './routes/LoginRoutes'

function App() {
  const authCtx = useContext(AppContext)

  const PermissionRouter = () => {
    const club = parseInt(localStorage.getItem('club'))

    const handleRoutes = () => {
      if (authCtx.isLoggedIn) {
        if (club !== 0) {
          return <ClubRoutes />
        } else {
          return <AdminRoutes />
        }
      } else {
        return <LoginRoutes />
      }
    }

    return <>{handleRoutes()}</>
  }

  return (
    <>
      <Layout>{authCtx.loading ? <Spinner /> : <PermissionRouter />}</Layout>
    </>
  )
}

export default App
