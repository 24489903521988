import { Grid } from '@mui/material'
import PushNotificationControls from '../../components/SedesComponent/sedesControls'
import SedesViewer from '../../components/SedesComponent/sedesViewer'
import { useEffect } from 'react'
import sedeSubject from '../../subjects/SedeSubject'

const SedesPage = () => {

  useEffect(() => {
    sedeSubject.updateSedes()
  }, [])

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90%' }}>
      <Grid
        container
        item
        wrap="nowrap"
        xs={7.5}
        direction={'column'}
        justifyContent={'flex-start'}
        sx={{
          border: '1px solid #cccccc',
          borderRadius: '15px',
          padding: '10px',
          paddingBottom: 0,
          ml: '40px',
          mt: '20px',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <SedesViewer />
      </Grid>
      <Grid
        container
        item
        xs={3.5}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        sx={{ backgroundColor: '#cccccc', mt: '20px', height: '100%', padding: '20px' }}>
        <PushNotificationControls />
      </Grid>
    </Grid>
  )
}
export default SedesPage