import { translateGoal } from '../../Utils/translateGoals'
import { translateSeverities } from '../../Utils/translateSeverity'

export class SaveVideoRequestModel {
  name
  short_description
  long_description
  severity
  preview_image
  start_at
  end_at
  link
  duration
  special_guest
  coaches
  elements
  goals
  scores = []
  view_types
  access_levels = ['MEMBERSHIP']

  constructor(newVideo) {
    this.name = newVideo.name.trim()
    this.short_description = newVideo.shortDescription ? newVideo.shortDescription : ''
    this.long_description = newVideo.longDescription ? newVideo.longDescription : ''
    this.severity = newVideo.severity ? translateSeverities(newVideo.severity) : ''
    this.preview_image = newVideo.previewImage ? newVideo.previewImage : ''
    this.start_at = newVideo.startAt ? this.transformDate(newVideo.startAt) : ''
    this.end_at = newVideo.endAt ? this.transformDate(newVideo.endAt) : ''
    this.link = newVideo.link ? newVideo.link : ''
    this.duration = newVideo.duration ? newVideo.duration : null
    this.special_guest = newVideo.specialGuest ? newVideo.specialGuest : ''
    this.coaches = newVideo.coaches ? newVideo.coaches.map((element) => element.id) : []
    this.elements = newVideo.elements ? [...newVideo.elements.map((element) => element.id)] : null
    this.goals = newVideo.goals ? newVideo.goals.map((element) => translateGoal(element)) : []
    this.scores = newVideo.scores ? newVideo.scores : []
    this.view_types = newVideo.viewTypes
      ? newVideo.viewTypes.map((element) => {
          return { view_type: element.viewType, image: element.image }
        })
      : []
    this.access_levels = newVideo.accessLevels ? newVideo.accessLevels : ['MEMBERSHIP']
  }

  transformDate(date) {
    if (typeof date !== 'string') {
      return date.toISOString()
    }
    return date
  }
}
