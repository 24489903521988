import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CalendarComponent from '../../components/Calendar/CalendarComponent/CalendarComponent'
import CalendarSchedule from '../../components/Calendar/CalendarScheduler/CalendarSchedule'
import Spinner from '../../components/UI/Spinner/Spinner'
import calendarSubject from '../../subjects/CalendarSubject'

export default function CalendarPage() {
  const [isLoading, setIsLoading] = useState(true)

  const getData = async () => {
    await calendarSubject.getClubs()
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90vh', width: '100%', mt: '20px' }}>
      {isLoading && <Spinner />}
      <Grid
        item
        container
        xs={7.5}
        direction="column"
        wrap="nowrap"
        justifyContent={'flex-start'}
        sx={{
          ml: '60px',
          padding: '10px',
          border: '1px solid #cccccc',
          borderRadius: '15px',
          width: '100%',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <CalendarSchedule />
      </Grid>
      <Grid
        container
        item
        xs={3.5}
        wrap="nowrap"
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#cccccc',
          height: '100%',
          paddingX: '20px',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}>
        <CalendarComponent />
      </Grid>
    </Grid>
  )
}
