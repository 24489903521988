import { useEffect, useState } from "react";
import salonSubject from "../../../subjects/SalonSubject";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import theme from "../../../css/Theme";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

/**
 * Executes the setSelecedPage function from the push notification subject, witch updates the page variable defined in the mentioned subject.
 * @param {String} page
 */
const navigateOnControls = (page, selectedRoom) => {
  salonSubject.setSelectedPage(page)
  salonSubject.setSelectedName(selectedRoom)
}

const handleDelete = async (room) => {
  await salonSubject.deleteRoom(room);
};

/**
 * Renders the array of notifications as accordions.
 * @returns
 */
export const SalonViewer = () => {
  const [rooms, setSalon] = useState(salonSubject.filteredSalons);

  /**
   * Updates the local push notification array to the filtered notifiactions array from the ṕush notification subject.
   */
  const onPushNotificationUpdate = () => {
    setSalon(salonSubject.filteredSalons);
  };
  /**
   * Subscribes the component to the subject.
   */
  useEffect(() => {
    salonSubject.addObservers(onPushNotificationUpdate);
    return () => {
      salonSubject.removeObservers(onPushNotificationUpdate);
    };
  }, []);
  return (
    <>
      <Grid
        container
        item
        wrap="nowrap"
        direction="column"
        xs={10}
        sx={{
          overflowY: "auto",
          background: theme.palette.primary.main,
          height: "100%",
        }}
      >
        {rooms.map((room, i) => {
          return <SalonSummary key={i} id={`${room.name}-${i}`} value={room} />;
        })}
      </Grid>
    </>
  );
};
const SalonSummary = ({ value }) => {
  return (
    <>
      <Grid item container direction="row">
        <Grid
          justifyContent={'flex-start'}
          sx={{ mt: 1 }}
          item
          container
          direction="row"
          xs={6}
        >
          <Typography
            color={theme.palette.darkGray.main}
            noWrap
            sx={{ fontWeight: "bolder" }}
          >
            {value.name}
          </Typography>
        </Grid>
        <Divider
          sx={{ mt: 1 }}
          orientation="vertical"
          color={theme.palette.primary.main}
          flexItem={true}
        />
        <Grid
          justifyContent={'flex-end'}
          item
          container
          direction="row"
          xs={5}
        >
          <IconButton onClick={() => navigateOnControls('edit', value)}>
            <EditIcon color="info" />
          </IconButton>
          <IconButton onClick={() => handleDelete(value) }>
            <DeleteIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
