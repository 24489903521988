import { Divider, FormControlLabel, FormGroup, Grid, MenuItem, Switch, Typography } from '@mui/material'
import { ThemedButton } from '../../../shared/Components/CustomButton/CustomButton'
import CustomSelect from '../../../shared/Components/CustomSelect/CustomSelect'
import CustomTextField from '../../../shared/Components/CustomTextField/CustomTextField'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme from '../../../css/Theme'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import CustomAutocomplete from '../../../shared/Components/CustomAutocomplete/CustomAutocomplete'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useState } from 'react'
import { useEffect } from 'react'

/**
 * Renders a form to create a save a new video.
 * @returns a component.
 */
const CreateVideoControls = () => {
  const [coaches, setCoaches] = useState(onDemandSubject.newVideo.coaches)
  /**
   * Executes the saveVideo function in onDemandSubject.
   */
  const onSaveNewItem = () => {
    onDemandSubject.saveVideo()
  }

  const updateAccessLevel = (e) => {
    if (e.target.checked === true) {
      onDemandSubject.newVideo.accessLevels = ['ALL']
    } else {
      onDemandSubject.newVideo.accessLevels = ['MEMBERSHIP']
    }
  }

  const onCoachesUpdate = () => {
    setCoaches(onDemandSubject.newVideo.coaches)
  }

  useEffect(() => {
    onDemandSubject.addObserver(onCoachesUpdate)
    return () => onDemandSubject.removeObserver(onCoachesUpdate)
  }, [])

  return (
    <>
      <Grid
        container
        item
        xs={4}
        direction="column"
        justifyContent={'space-around'}
        alignItems={'center'}
        wrap={'nowrap'}
        sx={{ height: '100%' }}>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <ThemedButton background="transparent" border="1px solid red" color={'red'}>
            {onDemandSubject.newVideo.id ? 'Modificar Video' : 'Nuevo Video'}
          </ThemedButton>
        </Grid>
        <Grid container item xs={1} justifyContent={'center'}>
          <KeyboardArrowDownIcon sx={{ color: 'white', height: '100%', fontSize: '40px' }} />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            placeholder={'Nombre del Video'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newVideo && onDemandSubject.newVideo.name}
            onChange={(e) => {
              onDemandSubject.newVideo.name = e.target.value
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            placeholder={'Link Vimeo...'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newVideo && onDemandSubject.newVideo.link}
            onChange={(e) => {
              onDemandSubject.newVideo.link = e.target.value
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ width: '100%' }}>
          <CustomTextField
            placeholder={'Duración del Video (Min)'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newVideo && onDemandSubject.newVideo.duration}
            type={'number'}
            onChange={(e) => {
              onDemandSubject.newVideo.duration = parseInt(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
        <Divider variant="middle" sx={{ backgroundColor: 'white', width: '90%' }} />
      </Grid>
      <Grid
        container
        item
        xs={4}
        direction="column"
        wrap="nowrap"
        justifyContent={'space-around'}
        sx={{ height: '100%' }}>
        <Grid item xs={1}>
          <Typography align="center" sx={{ fontWeight: 'bold' }}>
            CONFIGURACIÓN
          </Typography>
        </Grid>
        <Grid item container justifyContent={'center'} xs={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color={'warning'}
                  onChange={(e) => updateAccessLevel(e)}
                  defaultChecked={onDemandSubject.newVideo && onDemandSubject.newVideo.accessLevels.includes('ALL')}
                />
              }
              label="¿Es Gratis?"
              labelPlacement="start"
            />
          </FormGroup>
        </Grid>
        <Grid xs={2} item>
          <CustomSelect
            onChange={(e) => {
              onDemandSubject.newVideo.severity = e.target.value
            }}
            defaultValue={onDemandSubject.newVideo.severity ? onDemandSubject.newVideo.severity : 'Dificultad/Nivel'}>
            <MenuItem value="Dificultad/Nivel" disabled>
              Dificultad/Nivel
            </MenuItem>
            {onDemandSubject.severityList.map((elem, index) => {
              return (
                <MenuItem key={index} value={elem}>
                  {elem}
                </MenuItem>
              )
            })}
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <CustomAutocomplete
            list={onDemandSubject.coachList}
            multiple
            placeholder={'Staff'}
            handler={onDemandSubject.handlerCoaches}
            value={coaches}
            renderOption={(option) => {
              return option.name + ' ' + option.surname
            }}
            checkboxOptionStyles={{
              borderColor: 'white',
            }}>
            <FiberManualRecordIcon sx={{ fontSize: 'x-small', color: 'white' }} />
          </CustomAutocomplete>
        </Grid>
      </Grid>
      <Grid container item xs={4} direction="column" justifyContent={'space-evenly'} sx={{ height: '100%' }}>
        <Grid item xs={1}>
          <Divider variant="middle" sx={{ backgroundColor: 'white', width: '90%' }} />
        </Grid>
        <Grid item xs={1}>
          <Typography align="center" sx={{ fontWeight: 'bold' }}>
            Invitados Especiales
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            placeholder={'Nombre del Invitado'}
            backgroundcolor={'white'}
            background={theme.palette.lightGray.main}
            defaultValue={onDemandSubject.newVideo && onDemandSubject.newVideo.specialGuest}
            onChange={(e) => {
              onDemandSubject.newVideo.specialGuest = e.target.value
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <ThemedButton handler={onSaveNewItem}>GUARDAR</ThemedButton>
        </Grid>
      </Grid>
    </>
  )
}
export default CreateVideoControls
