export default class SaveHomeSectionModel {
  name
  description
  order
  action
  image
  viewType
  enabled
  cards = []

  constructor(newSection) {
    let cardRequest = newSection.cards.map((element, index) => {
      return {
        cardId: element.id,
        order: index + 1,
      }
    })

    this.name = newSection.name
    this.description = newSection.description
    this.order = newSection.order
    this.action = newSection.action
    this.image = newSection.image
    this.viewType = newSection.viewType
    this.enabled = newSection.enabled
    this.cards = cardRequest
  }
}
