import {Button, styled} from "@mui/material";
import Theme from "../../../../css/Theme";
import * as React from "react";

const ThemedButtonSelected = styled(Button)(() => ({
  background: Theme.palette.gradient.main,
  width: '50px',
  height: '50px',
  marginLeft: '15px',
  minWidth: '50px',
  borderRadius: '7px'
}));

const ThemedMenuButtonSelected = (props) => {
  return (
    <ThemedButtonSelected onClick={props.onClick}>
      {props.children}
    </ThemedButtonSelected>
  );
};

export default ThemedMenuButtonSelected